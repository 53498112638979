import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { Chevron, HomeIcn, Plus } from '../../../../../components/icons'
import { Divider, Icon } from '../../../../../components/icons/styles'
import { showInventoryModal, tableState } from '../../../../../globals'
import { IconBtn } from '../../buttons/styles'
import SearchForm from '../../forms'
import Actions from '../main/menu'

import { HeaderCont, HeaderListItems, HeaderMainCont, Item } from './styles'
import { FilterCont } from '../main/styles'


export const FILTER_OPTION = [
    'ALL',
    'EXPIRING',
    'EXPIRED',
    'RUNNING OUT',
    'OUT OF STOCK',
]


export const TableHeader = () => {
    const showEditForm = () => {
        //show modal to edit item details.
        showInventoryModal(true)
        // tableState({item: null, state: 'editing'})
    }
    return (
        <HeaderCont>
            <HeaderListItems>
                <Item>
                    <h6>STOCK</h6>
                </Item>
                <Item>
                    <h6>CATEGORY</h6>
                </Item>
                <Item>
                    <h6>INSTOCK</h6>
                </Item>
                <Item>
                    <h6>
                        SELLING PRICE
                        <span>
                            <IconBtn onClick={() => showEditForm()}>
                                <Icon>
                                    <Plus />
                                </Icon>
                            </IconBtn>
                        </span>
                    </h6>
                </Item>
                <Divider />
            </HeaderListItems>
        </HeaderCont>
    )
}


const CatHeader = (props: any) => {
    const [active, setActive] = useState('')
    const showEditForm = () => {
        tableState({
            item: null,
            state: 'editing'
        })
        showInventoryModal(true)
    }

    return (
        <HeaderMainCont>
            <HeaderCont>
                <HeaderListItems>
                    <h6>Inventory</h6>
                    <SearchForm />
                    <FilterCont>
                        <Item>
                            <h6 onClick={() => setActive('group')}>GROUP</h6>
                            <Icon id='cheveron'>
                                <Chevron />
                            </Icon>
                            {active === 'group' && <Actions closeCallback={() => setActive('')} name='group'  />}
                        </Item>
                        <Item>
                            <h6 onClick={() => setActive('filter')}>FILTER</h6>
                            <Icon id='cheveron'>
                                <Chevron />
                            </Icon>
                            {active === 'filter' && <Actions closeCallback={() => setActive('')} name='filter' options={FILTER_OPTION} />}
                         </Item>
                    </FilterCont>
                    <IconBtn onClick={() => showEditForm()}>
                        <Icon>
                            <p>+</p>
                        </Icon>
                    </IconBtn>
                </HeaderListItems>
            </HeaderCont> 
            {/* <Divider /> */}
        </HeaderMainCont>
    )
}

export default CatHeader