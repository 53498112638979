import React from 'react'
import { TracksCont, TrackItem, Item, DataItem, Body, Cont, Header, LoadingCont } from './styles'
import { Card } from 'react-bootstrap'

import { useLazyQuery, useQuery, useReactiveVar } from '@apollo/client';
import { GET_CHART_FOOTER } from '../../../../graphql/queries';
import { chartDuration } from '../../../../globals';
import { SpinLoader } from '../../../loaders';
import { roundAmount, formatMoney } from '../../../../utils';
import { Divider } from '../../../icons/styles';

export const Status = () => {

    const { loading, data, error } = useQuery(GET_CHART_FOOTER, {
        fetchPolicy: "network-only"
    })

    if (error) console.log({ error })

    return (
        <Cont>
            <Card>
                <Header>
                    <h6>Products status</h6>
                </Header>
                <Body>
                    {
                        data ?
                            <>
                                <TracksCont>
                                    <TrackItem>
                                        <Item>
                                            <h6 className="stats">Low Stocks</h6>
                                            <p>About to finish products</p>
                                        </Item>
                                        <DataItem>
                                            <h6>{data?.chartFooter.lowStocksCount}</h6>
                                        </DataItem>
                                        <Divider />
                                    </TrackItem>
                                    <TrackItem>
                                        <Item>
                                            <h6>Out of stocks</h6>
                                            <p>Exhausted products</p>
                                        </Item>
                                        <DataItem>
                                            <h6>{data.chartFooter.outOfStocks}</h6>
                                        </DataItem>
                                        <Divider />
                                    </TrackItem>
                                    <TrackItem>
                                        <Item>
                                        <h6>Expiring</h6>
                                        <p>About to expired products</p>
                                        </Item>
                                        <DataItem>
                                            <h6> {data.chartFooter.expiring}</h6>
                                        </DataItem>
                                        <Divider />
                                    </TrackItem>
                                    
                                    <TrackItem>
                                        <Item>
                                            <h6>Expired</h6>
                                            <p>Current expired products</p>
                                        </Item>
                                        <DataItem>
                                            <h6>{data.chartFooter.expired}</h6>
                                        </DataItem>
                                        <Divider />
                                    </TrackItem>
                                   
                                    
                                </TracksCont>
                            </>
                            :
                            <LoadingCont>
                                <SpinLoader size={'40px'} />
                            </LoadingCont>
                    }
                </Body>
            </Card>
        </Cont>
    )
}
