import styled from "styled-components"

export const MenuOptionsList = styled.ul<any>`
  font-size: 12px;
  position: absolute;
  padding: 0px 0px;
  right: ${props => props.rt || '-75px'};
  z-index: 50;
  min-width: 150px;
  top: ${props => props.top || '40%'};
  color: ${props => props.theme.light.colors.labels.pri};
  background-color: ${props => props.theme.light.colors.backgrounds.base.pri};
  border: 1px solid ${props => props.theme.light.colors.separators.pri};
  border-radius: 10px;
  box-shadow: 0 5px 25px #c4c3c337;
`
export const Option = styled.li<any>`
  height: 35px;
  width: 100%;
  display: flex;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  position: relative;
  .lbl {
    margin-bottom: 0px;
    font-size: 12px;
     padding: 0px 10px;
     display: flex;
  }
  :hover {
    background-color: rgb(247 247 247);
  }
`