import styled from "styled-components";

export const Container = styled.div`
    width: 100vw;
    height: 100vh;
    display: grid;
    grid-template-columns: 2.5fr 1fr;
    @media(max-width: 768px) {
     grid-template-columns: 1fr;
    }
`
export const PromoSection = styled.section`
    width: 100%;
    height: 100%;
    position: relative;
    background:#08d57f;
    @media(max-width: 768px) {
      display: none;
    }
`
export const FormSection = styled.section`
    width: 100%;
    height: 100%;
    position: relative;
`
export const Copy = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
 p {
  font-weight: normal;
  line-height: 1.5;
  margin-bottom: 0px;
  color: rgb(88, 87, 87);
  position: relative;
}
h6 {
  font-size: 60px;
  font-weight: bold;
  margin-bottom: 10px;
  position: relative;
  color: #373636;
}
`