import { useState } from "react"
import { useParams } from "react-router-dom"
import { NavLink } from "react-router-dom"
// import NavListMenu from "../topNavMenuList"
import CatHeader, { CategoryListHeader, SubCatHeader } from "../categories"
import { CatCont, Counter, HamdbugerMenu, Header, HeaderCont, HeaderListItems, HeaderMenuCont, IconsGroup, Item, MenuCont } from "./styles"
import { useLocation } from "react-router-dom"
import { useReactiveVar } from "@apollo/client"
import { onlineCart, showNavModal, showOnlineCheckOutModal } from "../../../../../globals"
import { CartItemsCounter } from "./styles"
import { Icon, Divider } from "../../../../../components/icons/styles"
import { Chevron, Bag, User } from "../../../../../components/icons"
import { FormCont } from "../../forms/styles"
import NavListMenu from "../../../../../components/topNavMenuList"

const MainHeader = (props: any) => {

    const [menu, setMenu] = useState(false)

    const { category } = useParams()
    const location = useLocation() as any

    const params = new URLSearchParams(location.search)

    let { stocks } = useReactiveVar(onlineCart)
    const toggleSideNav = () => {
        showNavModal(true)
    }
    return (
        <Header>
            <HeaderCont>
                <FormCont>
                    <div>
                        <NavLink className='home' to='/'>
                            <h2>Stosyst</h2>
                        </NavLink>
                        <MenuCont>
                            <HamdbugerMenu onClick={() => toggleSideNav()}>
                                <div className="bar"></div>
                                <div className="bar"></div>
                                <div className="bar"></div>
                            </HamdbugerMenu>
                            <h6>Market</h6>
                        </MenuCont>
                    </div>

                    <h6 className='page__title'>Stosyst</h6>

                    <CategoryListHeader />
                    
                    <HeaderMenuCont>
                        <Icon onClick={() => showOnlineCheckOutModal(true)} id='bag'>
                            {
                                stocks.length ?
                                    <CartItemsCounter>
                                        {stocks.length}
                                    </CartItemsCounter>
                                    :
                                    null
                            }
                            <Bag />
                        </Icon>
                        <IconsGroup onClick={()=>setMenu(!menu)} >
                            <Icon>
                                <User />
                            </Icon>
                            <Icon id='cheveron'>
                                <Chevron />
                            </Icon>
                        </IconsGroup>
                        {menu && <NavListMenu hideMenuCallback={setMenu} />}
                    </HeaderMenuCont>
                </FormCont>
            </HeaderCont>
            <CatCont>
                <CatHeader  />
                <Divider />
            </CatCont>
            <SubCatHeader {...props} />
        </Header>
    )
}

export default MainHeader