import styled from "styled-components";



export const SearchSuggestions = styled.ul<any>`
    width: 100%;
    min-height: 40px;
    max-height: 400px;
    overflow-y: scroll;
    overflow:hidden;
    position: relative;
    right: 0px;
    padding: 0px;
    display: flex;
    flex-direction: column;
    background: #ffffff;
`
export const Suggestions = styled.li<any>`
    width: 100%;
    height: 40px;
    padding: 0px 10px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: start;
    cursor: pointer;
    p {
        position: relative;
        margin-bottom:0px;
        color: #393939;
        text-align: left;
        font-weight: 500;
        font-size: 13px;
        padding: 0px 15px;
    }
    :hover{
      background: whitesmoke;
     }
     .icon {
      width: 20px;
      height: 20px;
     }
     #cancel {
      align-self: end;
       width: 20px;
      height: 20px;
     }
`


export const FormListItemsCont = styled.form<any>`
    height: ${props=>props.focus ? 450: 45}px;
    transition: all .15s ease-in-out;
    width: 350px;
    align-items: start;
    background: #ffffff;
    border-radius: 23px;
    margin: auto;
    z-index: 2000;
    border: 1px solid rgba(71, 75, 76, 0.055);
    box-shadow: ${props => props.focus ?  'rgba(196,195,195,0.216) 0px 5px 25px':'none'} ;
`
export const Form = styled.form<any>`
    height: 47px;
    width: 100%;
    grid-template-columns: 25px 1fr 40px;
    align-items: center;
    background: ${props => props.focus ? 'white' : '#f5f4f4'};
    padding: 0px 5px 0px 10px;
    display: ${props => props.mobile ? 'none' : 'grid'};
    border-radius: 23.5px 23.5px ${props => props.focus ? 0 : 23}px ${props => props.focus ? 0 : 23}px;
    @media(max-width: 420px) {
        height: 100%;
        border-radius: 25px;
        padding: 0px 10px;
        width: 100%;
        grid-template-columns: 30px 1fr 30px;
        display: ${props => props.mobile ? 'grid' : 'none'};
    }
`
export const Input = styled.input.attrs((props: any) => ({
    placeholder: props.placeholder,
    className: 'form-control',
    autocomplete: 'off',
    name: 'search',

}))`
  padding: 10px 10px;
  width: 100%;
  height: 47px;
  position: relative;
  border:none;
  align-items: center;
  background-color: inherit;
  font-size: 14px;
  color: ${props => props.theme.mode === 'dark' ? props.theme.dark.colors.labels.pri : props.theme.light.colors.labels.pri
    };
  &:focus {
    box-shadow: none;
    outline: none;
    background-color: transparent;
    color: ${props => props.theme.mode === 'dark' ? props.theme.dark.colors.labels.pri : props.theme.light.colors.labels.pri
    };
  }
  ::placeholder {
    color: lightgrey;
  }
`;

export const FormCont = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
    overflow: visible;
    a {
      text-decoration: none;
    }
    p {
      margin-bottom: 0px;
      font-size: 10px;
      font-weight: bold;
    }
    h2 {
      font-size: 24px;
      font-weight: bold;
      color: #25ec78;
      margin-bottom: 0px;
      position: relative;
      span {
        bottom: -50%;
    float: right;
    position: absolute;
    font-size: 12px;
    font-weight: bold;
    margin-left: 5px;
    z-index: 100000;
    right: 3px;
    color: #888888;
      }
    }
    .icon {
      width: 20px;
      height: 20px;
    }
`

export const FilterContainer = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
    p {
        margin-bottom: 0px;
        font-size: 13px;
        font-weight: 600;
        margin-right: 10px;
        color: grey;
    }
    #ico {
      cursor: pointer;
      width: 11px;
      height: 11px;
    }
    .icon-cont {
      position: relative;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      background-color: rgba(0,0,0,.05);
      :hover {
        background-color: rgba(0,0,0,.08);
      }
      svg {
        stroke: grey;
      }
    }
    @media(max-width: 420px) {
        svg {
            position: relative;
           right: 7px;
        }
    }
`
export const MultiFormGroupContainer = styled.div<any>`
  background-color: inherit;
  height: 45px;
  width: 100%;
  border-radius: 8px;
  border: none;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  position: relative;
  padding: 0px 10px;
  justify-content: space-between;
  border: 1px solid #e6e1e1;
`
export const Double = styled.div<any>`
  width: 100%;
  height: auto;
  display: flex;
`