import styled from "styled-components";
export const SectionCont = styled.div`
    padding-top: 30px;
    width: 100%;
    position: relative;
    min-height: 100px;
`

export const FormContainer = styled.div<any>`
  height: 370px;
  width: 270px;
  right: 20px;
  /* padding: 0px 15px 15px 15px; */
  top: -100px;
  z-index: 2;
  border-radius: 10px;
  position: absolute;
  overflow: visible;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  border: 1px solid whitesmoke;
  background: white;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgba(71, 75, 76, 0.055);
  display: ${props => props.show ? 'flex' : 'none'};
  box-shadow: rgba(196, 195, 195, 0.216) 0px 5px 25px;
`;
export const SettingsCont = styled.div`
  width: 100%;
  min-height: 100px;
  position: relative;
   h5 {
    width: 100%;
    margin-bottom: 0px;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 10px;
    span {
        font-size: 14px;
        color: #1ed760;
        right: 0px
    }
  }
  p {
    margin-bottom: 0px;
  }
  position: relative;
  width: min(100% - 40px);
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0px;
  }
`
export const SectionHeader = styled.h1`
    width: 100%;
    margin-bottom: 0px;
    font-size: 18px;
    font-weight: 700;
    padding-left: 20px;
    margin-bottom: 10px;
    position: relative;
    span {
        font-size: 20px;
        color: #1ed760;
        right: 20px;
        text-align: right;
        position: absolute;
         font-weight: 500;
         cursor: pointer;
    }
`
export const SettingGroup = styled.div`
  width: 100%;
  min-height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: space-between;
  p{
    font-size: 14px;
    color: #1ed760;
    cursor: pointer;
  }
`
export const SettingItem = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  p{
    font-size: 14px;
    color: #1ed760;
    cursor: pointer;
    margin-bottom: 0px;
  }
`
export const Setting = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: space-between;
  flex-direction: column;
  p{
    font-size: 14px;
    color: #222121;
     width: 100%;
  }
  p:last-child {
    color: grey;
    position: relative;
  }
`
