import styled from "styled-components";

export const Group = styled.ul`
    padding-left: 0px;
    width: 100%;
`
export const Header = styled.header`
  margin: 5px 0px;
  color: #000000;
  margin: auto;
  display: flex;
  width: 100%;
  height: 30px;
  align-items: center;
  position: relative;
  justify-content: space-between;
  h6 {
    font-size: 10px;
    font-weight: 700;
    color: #2b2b2b;
    margin-bottom: 0px;
    text-transform: uppercase;
    a {
      color: #2b2c2c;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
`
export const ItemsCont = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
`
export const Footer = styled.footer`
  width: 940px;
  left: 60px;
  display: flex;
  height: 40px;
  align-items: center;
  position: relative;
  justify-content: space-between;
  h6 {
    font-size: 14px;
    margin-bottom: 0px;
    font-weight: 500;
  }
  p {
    margin-bottom: 0px;
    color: grey;
    font-size: 13px;
  }
`
