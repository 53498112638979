import styled from "styled-components";

export const Header = styled.header`
  margin: 5px 0px;
  color: #343435;
  margin: auto;
  display: flex;
  width: 100%;
  height: 30px;
  align-items: center;
  position: relative;
  justify-content: space-between;
  h6 {
    font-size: 10px;
    font-weight: 700;
    color: grey;
    margin-bottom: 0px;
    text-transform: uppercase;
  }
   a {
      color: #2b2c2c;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
`


export const PageCont = styled.div`
height: 100%;
position: relative;
width: auto;
padding: 0px;
.date--separator {
  font-size: 12px;
  margin-bottom:0px;
  width: 100%;
  text-align: center;
  font-weight: 500;
}
 @media(max-width: 768px) {
  .date--separator {
   position: relative;
    bottom: 28px;
    background: inherit;
  }
    background: whitesmoke;
  }
`;
export const PageCardCont = styled.div`
  height: 100%;
  position: relative;
  width: auto;
  padding: 0px;
  display: none;
  background: inherit;
  .date--separator {
    font-size: 12px;
    margin-bottom:0px;
    width: 100px;
    margin: auto;
    text-align: center;
    font-weight: 500;
  }
 @media(max-width: 768px) {
  display: block;
  .date--separator {
    position: relative;
    width: 100px;
  }
    /* background: whitesmoke; */
  }
`;

export const Separator = styled.p<any>`
  /* background: inherit; */
  background: whitesmoke;
  bottom: ${props=>props.i === 0 ? 17 : 28}px;
`
export const Container = styled.div.attrs({
    className: "container",
})`
/* height: 100%; */
position: relative;
max-width: 1000px;
padding: 0px;
padding-top: 15px;
@media(max-width: 768px) {
    background: whitesmoke;
    padding-top: 30px;
    height: auto;
}
`;

export const TableCont = styled.div`
  height: 100%;
  width: 99%;
  padding-right: 0px;
  position: relative;
  margin: 0 auto;
  @media(max-width: 768px) {
    display: none;
  }

`;

export const Main = styled.main`
    width: 100vw;
    height: 100vh;
    position: relative;
    padding-top: 140px;
     .window {
      width: 100%;
      text-align: center;
     }
     @media(max-width: 768px) {
      height: 100vh;
      background: whitesmoke;
  }
`
export const ListGroupCont = styled.div`
  width: 100%;
`
export const ItemListCont = styled.div`
  padding-top: 60px;
  height: 100vh;
  @media(max-width: 768px) {
    padding-top: 30px;
     height: auto;
  }
`

export const Footer = styled.footer`
  width: 100%;
  display: flex;
  height: 50px;
  margin-bottom: 20px;
  align-items: center;
  position: relative;
  justify-content: space-between;
  h6 {
    font-size: 16px;
    margin-bottom: 0px;
    font-weight: 500;
  }
  p {
    margin-bottom: 0px;
    color: grey;
    font-size: 13px;
  }
  @media(max-width:768px) {
    width:98%;
    margin: auto;
    height: 30px;
    margin-bottom: 30px;
     h6 {
      font-size: 18px;
       padding: 0px 10px;
     }
     p {
       padding: 0px 10px;
     }
     .divider {
      bottom: -40%;
      width:98%;
      right: 2%;
      margin: auto;
     }
    .divider:first-child {
      display: none;
    }
  }
`