import React, { useEffect, useState } from 'react'
import { Body, Content, ListItemsCont, Total } from './styles'
import { ListItem } from './item'
import { Divider } from '../../icons/styles'
import { formatMoney, getCartTotal } from '../../../utils'
import { CartItem, Order, initOrder } from '../../../models'
import { onlineCart } from '../../../globals'
import { useReactiveVar } from '@apollo/client'

const Cart = (props: any) => {
    const myCart: any = useReactiveVar(onlineCart);
    const [order, setOrder] = useState<Order>(initOrder);

    useEffect(() => {
        setOrder(myCart)
    }, [myCart])

    return (
          <Body {...props}>
              {
                  <ListItemsCont>
                      <Content>{
                        order.stocks.map((item: CartItem) =>
                              <ListItem
                                  key={item._id}
                                  stock={item}
                              />
                          )
                      }
                      </Content>
                      <Total>
                          <p>TOTAL:</p>
                        <p style={{ fontSize: 16 }}>{formatMoney(getCartTotal(order.stocks))}</p>
                          <Divider w={90} />
                      </Total>
                  </ListItemsCont>
              }
          </Body>
    )
}

export default Cart