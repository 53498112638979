import { useMutation, useReactiveVar } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import { Cancel, HidePass, ShowPass } from '../../../../../../components/icons'
import { Icon } from '../../../../../../components/icons/styles'
import { showAccModal } from '../../../../../../globals'
import { SAVE_EMAIL, SAVE_USERNAME } from '../../../../../../graphql/mutations/account'
import { GET_MY_ACCOUNT } from '../../../../../../graphql/queries'
import { Account } from '../../../../../../models/account'
import Button from '../../button'

import { Input } from '../inputs'
import { Container, FormGroup, FormGroupCont, FormItem, HeaderCont } from './styles'

const UsernameForm = (props: any) => {
    const [username, setUserName] = useState('')
    const [cancel, setCancel] = useState('')

    const [focused, setFocused] = useState('')

    const show = useReactiveVar(showAccModal)

    useEffect(() => {
        setUserName(props.username)
    }, [props.username])

    const [submit, { error, loading, data }] = useMutation(SAVE_USERNAME, {
        update: (cache, { data: { saveUserName } }) => {
            // let myAccount: Account = cache.readQuery({
            //     query: GET_MY_ACCOUNT
            // }) as any;

            // console.log(myAccount)

            // cache.writeQuery({
            //     query: GET_MY_ACCOUNT,
            //     data: {
            //         ...myAccount,
            //         email
            //     }
            // })
        }
    })

    if (error) console.log({ ...error });

    const handleChange = (e: any) => {
        e.persist();
        setUserName(e.target.value)
    }
    const clear = () => {
        setUserName('')
    }
    const focus = (name: string) => {
        setFocused(name)
    }
    const mouseEnter = () => {
        setCancel('username');
    }
    const mouseLeave = () => {
        setCancel('')
    }

    const handleSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault()
        submit({
            variables: {
                username
            }
        })
    }

    return (
        <Container show={show ==='username'}>
            <HeaderCont>
                <h6>Change Username</h6>
                <Icon onClick={() => showAccModal('')}>
                    <Cancel />
                </Icon>
            </HeaderCont>
            <FormItem onSubmit={(e: React.SyntheticEvent) => handleSubmit(e)}>
                <FormGroupCont>
                    <FormGroup>
                        <Input
                            value={username}
                            name='username'
                            focused={focused === 'username'}
                            label='Username'
                            placeholder='Account username'
                            changeCallback={(e: any) => handleChange(e)}
                            focusedCallback={(name: string) => focus(name)}
                        />
                        {
                            (cancel === 'username') &&
                            <Icon onClick={clear}>
                                <Cancel />
                            </Icon>
                        }
                    </FormGroup>
                </FormGroupCont>
                {
                    error && <p className="error">{error?.message}</p>
                }
                <Button
                    error={error}
                    disabled={!username}
                    data={data} loading={loading}
                    title={username ? 'Update Username' : 'Add Username '}
                 />
            </FormItem>
        </Container>
    )
}

export default UsernameForm