import { useMutation, useReactiveVar } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import { Cancel, HidePass, ShowPass } from '../../../../../../components/icons'
import { Icon } from '../../../../../../components/icons/styles'
import { showAccModal } from '../../../../../../globals'
import { SAVE_ACC_INFO } from '../../../../../../graphql/mutations/account'
import { GET_MY_ACCOUNT } from '../../../../../../graphql/queries'
import { Account, Info } from '../../../../../../models/account'
import Button from '../../button'

import { Input } from '../inputs'
import { Container, FormGroup, FormGroupCont, FormItem, HeaderCont } from './styles'
import { stripTypename } from '../../../../../../utils'

const NameForm = (props: any) => {

    // const {info} = props;

    const [focused, setFocused] = useState('')
    const [cancel, setCancel] = useState('')

    const [info, setInfo] = useState<any>(props.info)

    const show = useReactiveVar(showAccModal)

    useEffect(() => {
        console.log(props.info);
        
        // const { __typename, ...newInfo } = props.info
        setInfo(props.info)
    }, [props.info])
    

    const [submit, { error, loading, data }] = useMutation(SAVE_ACC_INFO, {
        update: (cache, { data: { saveAccInfo:{info} } }) => {
            let myAccount: Account = cache.readQuery({
                query: GET_MY_ACCOUNT
            }) as Account;

            // console.log(myAccount)
            // myAccount = {
            //     ...myAccount,
            //     addresses: [...data.saveAddress]
            // }

            // let { addresses }: any = myAccount

            // addresses = addresses.map((addr: Address) => addr?._id === data.saveAddress._id ? data.saveAddress : addr)

            cache.writeQuery({
                query: GET_MY_ACCOUNT,
                data: {
                    ...myAccount,
                    info: {...info}
                }
            })
        }
    })

    if(error) console.log({...error});
    if(data) console.log(data);
    
    const handleChange = (e: any) => {
        e.persist();
        setInfo({
            ...info,
            [e.target.name]: e.target.value
        })
    }

    const clearInput = (name: string) => {
        setInfo({
            ...info,
            [name]: ''
        })
    }
  
    const focus = (name: string) => {
        setFocused(name)
    }
    const mouseEnter = () => {
        setCancel('address');
    }
    const mouseLeave = () => {
        setCancel('')
    }

    const handleSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault()
        submit({
            variables: {
                info: stripTypename(info)
            }
        })
    }

    return (
        <Container show={show==='name'}>
            <HeaderCont>
                <h6>Change account name</h6>
                <Icon onClick={() => showAccModal('')}>
                    <Cancel />
                </Icon>
            </HeaderCont>
            <FormItem onSubmit={(e: React.SyntheticEvent) => handleSubmit(e)}>
                <FormGroupCont>
                    <FormGroup onMouseLeave={() => mouseLeave()} onMouseEnter={() => mouseEnter()}>
                        <Input
                            value={info?.name}
                            name='name'
                            focused={focused === 'name'}
                            label='Account name'
                            placeholder='Account name'
                            changeCallback={(e: any) => handleChange(e)}
                            focusedCallback={(name: string) => focus(name)}
                        />
                        {
                            (cancel === 'name') &&
                            <Icon onClick={()=>clearInput('name')}>
                                <Cancel />
                            </Icon>
                        }
                    </FormGroup>
                </FormGroupCont>
                {
                    error && <p className="error">{error?.message}</p>
                }
                <Button
                    error={error}
                    data={data}
                    loading={loading}
                    disabled={!info?.name}
                    title="Update Account Name" 
                />
            </FormItem>
        </Container>
    )
}

export default NameForm