import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { Chevron, Compress, HomeIcn, LineWeight, Plus } from '../../../../../components/icons'
import { Divider, Icon, IconBtn } from '../../../../../components/icons/styles'
import { showInventoryModal } from '../../../../../globals'
import SearchForm from '../../../../inventory/components/forms'
// import SearchForm from '../../forms'
// import Actions from '../main/menu'

import { FilterCont, HeaderCont, HeaderListItems, HeaderMainCont, Item, ItemCont, TabLink, TabLinks, TabLinksCont } from './styles'
import Actions from '../main/menu'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'


export const TableHeader = () => {
    const showEditForm = () => {
        //show modal to edit item details.
        showInventoryModal(true)
        // tableState({item: null, state: 'editing'})
    }
    return (
        <HeaderCont>
            <HeaderListItems>
                <Item>
                    <h6>STOCK</h6>
                </Item>
                <Item>
                    <h6>CATEGORY</h6>
                </Item>
                <Item>
                    <h6>INSTOCK</h6>
                </Item>
                <Item>
                    <h6>
                        SELLING PRICE
                        <span>
                            <IconBtn onClick={() => showEditForm()}>
                                <Icon>
                                    <Plus />
                                </Icon>
                            </IconBtn>
                        </span>
                    </h6>
                </Item>
                <Divider />
            </HeaderListItems>

        </HeaderCont>
    )
}


const CatHeader = (props: any) => {


    const {pathname} = useLocation()
    const GROUP_OPTION = [
        'DAYS',
        // 'WEEKS',
        'MONTHS',
        'YEARS'
    ]
    const FILTER_OPTION = [
        'ALL',
        'POS',
        'CASH',
        'TRANSFER'

    ]

    const [active, setActive] = useState('')

    const isPathTo = (name: string) => pathname.includes(name)

    return (
        <HeaderMainCont>
            <HeaderCont>
                <HeaderListItems>
                    <h6>Sales</h6>
                    <SearchForm />
                    <FilterCont>

                        <ItemCont >
                            <Item id='itm' className='item' onClick={() => setActive('group')}>
                                <Icon id='group'>
                                    <LineWeight />
                                </Icon>
                                <Icon id='cheveron'>
                                    <Chevron />
                                </Icon>
                                {active === 'group' &&
                                    <Actions closeCallback={() => setActive('')} name='group' options={GROUP_OPTION} />
                                }
                            </Item>
                        </ItemCont>
                        <ItemCont>
                            <Item className='item' onClick={() => setActive('filter')}>
                                <Icon id='group'>
                                    <Compress />
                                </Icon>

                                <Icon id='cheveron'>
                                    <Chevron />
                                </Icon>
                                {active === 'filter' &&
                                    <Actions closeCallback={() => setActive('')} name='filter' options={FILTER_OPTION} />
                                }
                            </Item>
                        </ItemCont>
                    </FilterCont>
                </HeaderListItems>
                <TabLinksCont>
                    <TabLinks>
                        <TabLink active={pathname === '/sales' || isPathTo('invoices')} >
                            <Link to='/sales'>Invoices</Link>
                        </TabLink>
                        <TabLink active={isPathTo('aggregates')} >
                            <Link to='aggregates' >Aggregates</Link>
                        </TabLink>
                        <TabLink active={isPathTo('payments')}  >
                            <Link to='payments'>Payments</Link>
                        </TabLink>
                    </TabLinks>
                </TabLinksCont>
            </HeaderCont>
        </HeaderMainCont>
    )
}

export default CatHeader