import React from 'react'
import { BarChartCont, Body, Cont, Header, LoadingCont } from './styles'
import { Card } from 'react-bootstrap'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    Title,
    Tooltip,
    BarElement,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useLazyQuery, useQuery, useReactiveVar } from '@apollo/client';
import { GET_SALES_EXPENSES } from '../../../../graphql/queries';
import { chartDuration } from '../../../../globals';
import { SpinLoader } from '../../../loaders';
import { roundAmount } from '../../../../utils';
import ErrorState from '../../../../pages/inventory/components/pagestate/error';
import EmptyState from '../../../../pages/inventory/components/pagestate/empty';

export const Visits = () => {


    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
    );

    const dateRange = useReactiveVar(chartDuration)
    const selectDuration = (range: string) => {
        chartDuration(range)
    }

    const L0 = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
    const L1 = [
        'JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC',
        'JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'
    ]
    const L2 = [
        '2013',
        '2014',
        '2015',
        '2016',
        '2017',
        '2018',
        '2019',
        '2020',
        '2021',
        '2022',
        '2023',
        '2024',
        '2025',
        '2026',
        '2027',
        '2028',
        '2029',
        '2030',
        '2031',
    ]
    const wks = L0.slice(new Date().getDay(), new Date().getDay() + 7)
    const mnths = L1.slice(new Date().getMonth() + 1, new Date().getMonth() + 13)
    let yrs = L2.slice(1, 11)

    const labels = dateRange === 'wk' ? wks : dateRange === 'mn' ? mnths : yrs

    let salesData: any = dateRange === 'wk' ? [0, 0, 0, 0, 0, 0, 0] : dateRange === 'mn' ? [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] : [0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    let expenseData: any = dateRange === 'wk' ? [0, 0, 0, 0, 0, 0, 0] : dateRange === 'mn' ? [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0] : [0, 0, 0, 0, 0, 0, 0, 0, 0, 0]

    const getYearlyIndex = (id: number) => {
        const yrDiff = new Date().getFullYear() - id
        yrs = L2.slice(yrDiff, 10)
        return 9 - id
    }
    const getMonthlyIndex = (id: number) => {
        return 11 - id

    }
    const getWeeklyIndex = (id: number) => {
        return 6 - id
    }
    const { loading, data: d, error } = useQuery(GET_SALES_EXPENSES, {
        variables: {
            duration: dateRange
        },
        fetchPolicy: "network-only",
    })

    const [refetchData, { loading: refetching, refetch, data: newData, error: refetchError }] = useLazyQuery(GET_SALES_EXPENSES, {
        fetchPolicy: "network-only",
    })
    if (d) console.log(d)

    if (error) console.log({ error })

    const setData = (data: any) => {
        const { sales, expenses } = data
        sales.forEach((record: any, i: number) => {
            salesData[
                dateRange === 'wk' ?
                    getWeeklyIndex(record._id) : dateRange === 'mn' ?
                        getMonthlyIndex(record._id) : getYearlyIndex(record._id)] = record.totalSales
        })
        expenses.forEach((record: any) => {
            expenseData[dateRange === 'wk' ?
                getWeeklyIndex(record._id) : dateRange === 'mn' ?
                    getMonthlyIndex(record._id) : getYearlyIndex(record._id)] = record.totalExpenses
        })
    }

    if (d) {
        setData(d.salesExpenses)
    }


    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'top' as const,
                display: false
            },
        },
        scales: {
            x: {
                grid: {
                    display: true,
                    color: 'white',
                    borderColor: 'whitesmoke',
                    backgroundColor: 'white',
                },
                ticks: {
                    color: 'grey',
                    font: {
                        size: 10,
                    }
                },
                border: {
                    color: 'whitesmoke'
                }
            },
            y: {
                grid: {
                    display: true,
                    color: 'whitesmoke',
                    borderColor: 'red',
                    backgroundColor: 'white',
                    zeroLineColor: 'red',
                },
                ticks: {
                    color: 'grey',
                    font: {
                        size: 11
                    },
                    stepSize: dateRange === 'wk' ? 200000 : dateRange === 'mn' ? 5000000 : 50000000,
                    callback: function (label: any, index: any) {
                        return label === 0 ? '0' : label >= 1000 && label < 1000000 ? `${(label / 1000)}K` : label >= 1000000 ? `${(label / 1000000)}M` : label
                    }
                },
                border: {
                    color: 'white'
                }
                // display: false
                // gridLines: {
                //   drawBorder: false,
                // },
            },

        }
    };

    const data = {
        labels,
        datasets: [
            {
                data: expenseData,
                backgroundColor: 'rgba(248, 11, 62, 0.398)',
            },
            {
                data: salesData,
                backgroundColor: 'rgba(53, 235, 74, 0.5)',
            },

        ],
        barThickness: 80
    };

    const getTotalSales = () => salesData.reduce((i: number, j: number) => i + j, 0)
    const getTotalExp = () => expenseData.reduce((i: number, j: number) => i + j, 0)


    
  return (
      <Cont>
          <Card>
              <Header>
                  <h6>Total visitors</h6>
              </Header>
              <Body>
                  <h4>{roundAmount(getTotalSales())}</h4>
                  <BarChartCont>
                      {
                        //   d ?
                        //       <>
                        //           <BarChartCont>
                                     
                        //           </BarChartCont>
                        //       </>
                        //       : 
                            <LoadingCont> {
                                loading ? 
                                <SpinLoader size={'40px'} /> 
                                : error ? 
                                <ErrorState retryCallback={refetch} /> 
                                : 
                                <p>No record here</p>
                            }
                            </LoadingCont>
                      } 
                      {/* <Bar options={options} data={data} /> */}
                  </BarChartCont>
              </Body>
          </Card> 
      </Cont>
  )
}
