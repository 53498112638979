import styled from "styled-components"

export const Cont = styled.header`   
   .card {
        padding: 0px 0px;
        overflow: hidden; 
        height: 360px;
        width: 100%;
        border-radius: 12px;
        margin-bottom: 15px;
        border-color: #eaeaea;
    }
`
export const Header = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px;
    height: 40px;
    background-color: #ffffff;
    h6 {
        margin-bottom: 0px;
        font-size: 14px;
    }
`
export const Body = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding: 0px 14px 10px 14px;
  h4  {
    font-weight: bold;
    margin: 0px;
    font-size: 1.25rem;
  }
`
export const TracksCont = styled.div<any>`
    height: 100%;
    width: 100%;
    position: relative;
    display: grid;
    grid-template-rows: repeat(4, 80px);
  `
export const TrackItem = styled.div<any>`
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    
  `
export const Item = styled.div<any>`
    height: 100%;
    width: 50%;
    position: relative;
     display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: column;
    h6 {
      font-size: 12px;
      margin: 0px;
    }
    p {
      font-size: 12px;
      color: grey;
      margin-bottom: 0px;
    }
  `
export const DataItem = styled.div<any>`
   align-items: end;
`
  export const LoadingCont = styled.div<any>`
   height: 100%;
   width: 100%;
   display: flex;
   position: absolute;
   flex-direction: row;
   align-items: center;
   justify-content: center;
   align-self: center ;
`