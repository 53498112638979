import { useQuery } from '@apollo/client'
import React from 'react'
import { Divider } from '../../../components/icons/styles'
import { StaffModalCont } from '../../../components/modals/styles'
import { showStaffModal } from '../../../globals'
import { GET_STAFF } from '../../../graphql/queries'
import { Person } from '../../../types/model'
import EmptyState from '../../inventory/components/pagestate/empty'
import ErrorState from '../../inventory/components/pagestate/error'
import LoadingState from '../../inventory/components/pagestate/loader'
import StaffViewCont from './components/container'
import Form from './components/forms'
import MainHeader from './components/header'
import StaffList from './components/list/staff'
import StaffItem from './components/staffItem'
import { Container, Main } from './styles'

export const dummies =[
    {
        id: 'idkksk',
        name: 'Amina Ibrahim',
        phone: '08077889837',
        email:'aminaibrahim@gmail.com',
        role: 'Administrator',
        dp: 'https://i.pinimg.com/474x/4a/02/4a/4a024aade5a7f2d0ea7239d9e75c8ac2.jpg'
    },
    {
        id: 'eeeekl',
        name: 'Jane Smith',
        phone: '08077889837',
        email:'janesmith@gmail.com',
        role: 'Cashier',
        dp: 'https://i.pinimg.com/474x/3b/91/24/3b912453bb9716f0db2c793b1d53f628.jpg'
    },
    {
        id: 'sfkdks',
        name: 'John Doe',
        phone: 'N/A',   
        email:'johndoe@yahoo.com',
        role: 'Manager',
        dp: 'https://i.pinimg.com/474x/0b/db/75/0bdb75473725abd09e274105adb7a984.jpg'  //default image if no DP is provided
    },
    {
        id: 'ddsdsfd',
        name: 'Mary Johnson',
        phone: '08077889837',
        email: 'maryjohnson@hotmail.com',
        role: 'Supplier',
        dp: 'https://i.pinimg.com/474x/cb/69/f8/cb69f83cc45dac8a89e97b3da3d52ea2.jpg'
    },
    {
        id: 'ddsdsfd',
        name: 'Mohammed Abdullahi',
        phone: '08077889837',
        email: 'maryjohnson@hotmail.com',
        role: 'Distributor',
        dp: 'https://i.pinimg.com/474x/18/45/d5/1845d5e713e09cd9b1a1a7d052dd6a6a.jpg'
    },
    {
        id: 'ddsdsfd',
        name: 'Solomon Johnson',
        phone: '08077889837',
        email: 'maryjohnson@hotmail.com',
        role: 'Editor',
        dp: 'https://i.pinimg.com/474x/de/55/9f/de559fdc159b8c32758413d2e703b2f5.jpg'
    }

]


const Staff = () => {

    const { data, loading, error, fetchMore, refetch } = useQuery(GET_STAFF, {
        fetchPolicy: "network-only",
    })

    let staff = []

    if (data) {
        staff = data.staff;
        // staff = data.staff.map((st: any) => {
        //     const i = Math.floor(Math.random() * 5)
        //     return {
        //         ...st,
        //         info: {
        //             ...st.info,
        //             dp: dummies[i]?.dp
        //         }
        //     }
        // })
        // console.log(data)
    }
    if(error) console.log({...error});
    
  return (
    <>
          <Main>
              <Container>
                <MainHeader /> 
                { 
                      loading ?
                          <LoadingState />
                          :
                          error ?
                              <ErrorState message='Oops Connection error' retryCallback={refetch} />
                              :
                              !staff.length ?
                                  <EmptyState clickCallback={showStaffModal(true)} btnLabel='add new' />
                                  :
                                  <StaffList  staff={staff} />
                }
              </Container>
              <StaffViewCont />
          </Main>
    </>
  )
}

export default Staff