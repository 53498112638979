
import React, {  } from 'react'
// import { roundAmount } from '../../charts/header'

import { Divider } from '../../../icons/styles'
import {
    Card,
    Header, ItemsCont,
} from '../styles'
import { format_date } from '../../../../utils'

import ShownInvoice from './listCard'
import { useLocation } from 'react-router-dom'
import ShownAggregate from './listCard'

type Props = {}

function ShownAggregateCard(props: any) {


    const { list } = props;
    const location = useLocation() as any
    const params = new URLSearchParams(location.search)

    return (
        <ItemsCont>{
            <Card>
                <Header>
                    <h6>{format_date(params.get("q"))}</h6>
                    <Divider /> {
                        // count > 5 &&
                        <h6>
                            {/* <Link to={generateLink(group, groupId)}>SHOW ALL</Link> */}
                        </h6>
                    }
                </Header>{
                    list.map((record: any, i: number) => (
                        <ShownAggregate
                            key={record._id.id}
                            stock={record.group[0].stocks} invoice={record}
                        />
                    ))
                }
                {/* <Footer>
                    <Divider bottom={100} />
                    <p>+{count - 5 <= 0 ? 0 : count - 5}</p>
                    <Icon rot={90} size={6}>
                        <Chevron />
                    </Icon>
                    <h6>{roundAmount(total)}</h6>
                </Footer> */}
            </Card>
        }
        </ItemsCont>
    )
}

export default ShownAggregateCard