import { useQuery, useReactiveVar } from '@apollo/client'
import React from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import ChatBox from '../../components/chat'
import InvoiceModal from '../../components/modals/invoice'
import { GET_INVOICES } from '../../graphql/queries/invoice.query'
import { invCriteria } from '../../types/defaults'

import EmptyState from '../inventory/components/pagestate/empty'
import ErrorState from '../inventory/components/pagestate/error'
import LoadingState from '../inventory/components/pagestate/loader'

import MainHeader from './component/headers/main'

import { OuterList } from './component/list/outer'
import { Container, ListGroupCont, Main, TableCont } from './styles'
import { CheckOutOnlineModal } from '../../components/modals/onlineCheckout'

const OrdersPage = (props: any) => {
    
    const queries = useReactiveVar(invCriteria)


    let offset = 0;


    const { data, loading, error, refetch, fetchMore, subscribeToMore } = useQuery(
        GET_INVOICES, {
            variables: {
                ...queries,
                offset
            },
            fetchPolicy: "network-only"
        }
    )

    let curInvoices: any[] = [];

    if (data) {
        curInvoices = data?.invoices
        offset = offset + 10;
    }
    if (error) {
        console.log(JSON.stringify(error, null, 2))
    }

    const fetchMoreData = () => {
        fetchMore({
            variables: {
                // filter,
                // query: query || ' ',
                // group,
                ...queries,
                offset: data?.invoices?.length,
            }
        })
    }
    return (
        <>
            <MainHeader />
            {/* <SideBar /> */}
            <Main>
                <Container>
                    {
                        loading ?
                            <LoadingState />
                            :
                            error ?
                                <ErrorState retryCallback={refetch} />
                                :
                                !data?.invoices?.length ?
                                    <EmptyState
                                        clickCallback={refetch}
                                        message='No sales records so far'
                                        btnLabel='Refresh'
                                        subMessage='Invoices appears here when created'
                                     />
                                    :
                                    <TableCont>
                                        <ListGroupCont>
                                            <InfiniteScroll
                                                dataLength={data?.invoices?.length}
                                                next={fetchMoreData}
                                                hasMore={true}
                                                loader={<></>}
                                                style={{ overflow: 'visible' }}
                                            >
                                            {
                                                curInvoices.map((group: any, i: number) => (
                                                    <OuterList
                                                        {...props}
                                                        list={group} 
                                                        total={group.total}
                                                        count={group.count}
                                                        groupId={group._id}
                                                        key={group.records[0]._id}
                                                        nextRecords={curInvoices[i + 1]?.records || []}
                                                        />
                                                ))
                                            }
                                            </InfiniteScroll>
                                        </ListGroupCont>
                                    </TableCont>
                }
                </Container>
                <ChatBox />
                <InvoiceModal />
                <CheckOutOnlineModal />
            </Main>
        </>
    )

}

export default OrdersPage