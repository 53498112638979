import { useQuery, useReactiveVar } from '@apollo/client'
import React, { useEffect, useState } from 'react'

import { dummy_products, queryOptions } from '../../types/defaults'
import SideBar from '../explore/components/sidebar'
import MainHeader from './components/headers/main'
import StockListGroup from './components/list/stockgroup'
import EmptyState from './components/pagestate/empty'
import ErrorState from './components/pagestate/error'
import LoadingState from './components/pagestate/loader'
import { ListGroupCont } from './components/styles'
import { Container, Main, Row, TableCont } from './styles'
import InfiniteScroll from "react-infinite-scroll-component";
import { LocalCheckOutModal } from '../../components/modals'
import StockModal from '../../components/modals/stock'
import { GET_PRODUCTS } from '../../graphql/queries/product.query'
import { ChatModal } from '../../components/modals/chat'
import CircularButton from './components/buttons/circular'
import ChatBox from '../../components/chat'
import { showInventoryModal, tableState } from '../../globals'
import { CheckOutOnlineModal } from '../../components/modals/onlineCheckout'
import StocksCard from '../../components/cards/stock'
import SideNavigator from '../../components/sidenav'
import AddStockBtn from './components/buttons/add'
import { GET_STORES } from '../../graphql/queries'
import StoreCard from './components/card'

const Stores = (props: any) => {

  // const [windowSize, setWindowSize] = useState({
  //   width: window.innerWidth,
  // });

  // useEffect(() => {
  //   const handleResize = () => {
  //       setWindowSize({
  //         width: window.innerWidth,
  //       });
  //     };
  //     window.addEventListener('resize', handleResize);
  //     return () => {
  //     window.removeEventListener('resize', handleResize);
  //   };
  // }, []);

  const queries = useReactiveVar(queryOptions)

  let offset = 0;

  const { data, loading, error, fetchMore, refetch } = useQuery(GET_STORES, {
    variables: {
      ...queries,
      offset,
      group: queries.group ? queries.group : 'category',
    },
    fetchPolicy: "network-only",
  })

  if (data) {
    console.log(JSON.stringify(data, null, 2));
    offset = offset + 10;
  }

  if (error) console.log({ ...error })

  const fetchMoreData = () => {
    fetchMore({
      variables: {
        ...queries,
        offset: data?.stores?.length,
        group: queries.group ? queries.group : 'category'
       
      }
    })
  }



  /* <p className='window'>{windowSize.width}</p> */
  return (
    <>
      <MainHeader />
      <Main>
        <Container>
          {
            loading ?
              <LoadingState />
              :
              error ?
                <ErrorState retryCallback={refetch} />
                :
                !data.stores.length ?
                  <EmptyState
                    btnLabel=''
                    message='No stores so far'
                    subMessage='Stores appears here when created'
                  />
                  :
                  <>
                        <InfiniteScroll
                          dataLength={data.stores.length}
                          next={fetchMoreData}
                          hasMore={true}
                          loader={null}
                          style={{ overflow: 'visible' }}
                        >
                      <Row>
                          {
                              data.stores.map((store: any) => (
                                <StoreCard key={store._id} store={store} />
                              ))
                          }
                        </Row>
                        </InfiniteScroll>
                    <InfiniteScroll
                      dataLength={data.stores.length}
                      next={fetchMoreData}
                      hasMore={true}
                      loader={null}
                      style={{ overflow: 'visible' }}
                    >
                        <Row>{
                          data.stores.map((store: any) => (
                            <StoreCard key={store._id} store={store} />
                          ))
                        }
                        </Row>
                    </InfiniteScroll>
              </>
          }
        </Container>
        <CheckOutOnlineModal /> 
        <SideNavigator />
      </Main>
    </>
  )
}

export default Stores