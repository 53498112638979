import React, { useEffect, useState } from 'react'
import { SmallCheckMark } from '../../../../../components/icons';
import { Icon } from '../../../../../components/icons/styles';
import { Loader } from '../../../../../components/loaders';
import { ButtonItem } from './styles'

const Button = (props: any) => {
  const { loading, data, title } = props;

  const [state, setState] = useState(title);
  useEffect(() => {
    if (loading) {
      setState('spin')
    } else if (data) {
      setState('done');
      setTimeout(() => {
        setState(title)
      }, 3000);
    } else {
      setState(title)
    }

  }, [data, loading])
  return (
    <ButtonItem {...props}>
      {
        state === 'spin' ?
          <Loader />
          : state === 'done' ?
            <Icon className="check">
              <SmallCheckMark />
            </Icon>
            :
            state
      }
    </ButtonItem>
  )
}

export default Button