import styled from "styled-components";

export const Form = styled.form<any>`
    height: 45px;
    width: 50%;
    grid-template-columns: 25px 1fr 40px;
    align-items: center;
    background: #f5f4f4;
    border-radius: 25px;
    margin: auto;
    padding: 0px 5px 0px 10px;
    display: ${props => props.mobile ? 'none' : 'grid'};
    @media(max-width: 420px) {
        height: 100%;
        border-radius: 25px;
        padding: 0px 10px;
        width: 100%;
        grid-template-columns: 30px 1fr 30px;
        display: ${props => props.mobile ? 'grid' : 'none'};
    }
`
export const Input = styled.input.attrs(props => ({
    placeholder: props.placeholder,
    className: 'form-control',
    name: 'search',
}))`
  padding: 10px 10px;
  width: 100%;
  height: 100%;
  position: relative;
  border:none;
  background-color: inherit;
  font-size: 14px;
  color: ${props => props.theme.mode === 'dark' ? props.theme.dark.colors.labels.pri : props.theme.light.colors.labels.pri
    };
  &:focus {
    box-shadow: none;
    outline: none;
    background-color: transparent;
    color: ${props => props.theme.mode === 'dark' ? props.theme.dark.colors.labels.pri : props.theme.light.colors.labels.pri
    };
  }
  ::placeholder {
    color: lightgrey;
  }
`;

export const FormCont = styled.div`
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns:130px 1fr 130px;
    border-radius: 10px 10px 0px 0px;
    position: relative;
    align-items: center;
    justify-content: space-between;
    a {
      text-decoration: none;
    }
    h2 {
      font-size: 24px;
      font-weight: bold;
      color: #25ec78;
      margin-bottom: 0px;
    }
    .icon {
      width: 20px;
      height: 20px;
    }
`

export const FilterContainer = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    p {
        margin-bottom: 0px;
        font-size: 13px;
        font-weight: 600;
        margin-right: 10px;
        color: grey;
    }
    #ico {
      cursor: pointer;
      width: 11px;
      height: 11px;
    }
    @media(max-width: 420px) {
        svg {
            position: relative;
           right: 7px;
        }
    }
`
export const MultiFormGroupContainer = styled.div<any>`
  background-color: inherit;
  height: 45px;
  width: 100%;
  border-radius: 8px;
  border: none;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  position: relative;
  padding: 0px 10px;
  justify-content: space-between;
  border: 1px solid #e6e1e1;
`
export const Double = styled.div<any>`
  width: 100%;
  height: auto;
  display: flex;
`