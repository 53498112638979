import styled from "styled-components";
export const ButtonsCont = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  bottom: -20px;
  position: relative;
`
export const ButtonItem = styled.button.attrs(props => ({
  diabled: props.disabled,
})) <any>`
  height: 40px;
  width: 100px;
  border-radius: 10px;
  position: relative;
  display: flex;
  margin-top: 15px;
  bottom: 0;
  right: 0;
  z-index:150;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  font-size: 13px;
  color: ${props => props.disabled ? '#b2b3b2' : 'white'} ;
  font-weight: 600;
  background-color: ${props => props.disabled ? 'whitesmoke' : '#212222'};
  &:hover {
    border: none;
    color: ${props => props.disabled ? '#b2b3b2' : 'white'};
    background-color: ${props => props.disabled ? 'whitesmoke' : '#121212'} ;
  }
  :hover {
    svg {
      stroke: "white";
    }
  }
  &:focus {
    border: none;
  }
  .check {
    width: 18px;
    height: 18px;
    svg {
      stroke: white;
    }
  }
`;
export const CancelBtn = styled.a`
  text-decoration: underline
  margin-bottom: 0px;
  width: 55px;
  cursor: pointer;
  margin-top: 15px;
  color: #212222;
  background-color: initial;
  font-size: 13px;
  &:hover {
    border: none;
    background-color: white;
    color: initial;
  }
`;
export const SubmitBtnItem = styled(ButtonItem)`
  margin-bottom: 0px;
  position: absolute;
  width: 100%;
  height: 45px;
  font-size: 13px;
`;
