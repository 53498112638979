import { useQuery, useReactiveVar } from '@apollo/client'
import React, { ReactElement } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useParams } from 'react-router-dom'
import { ChatModal } from '../../components/modals/chat'
import MainHeader from '../../components/topnav/headers/main'
import { GET_GOODS } from '../../graphql/queries'

import { queryOptions } from '../../types/defaults'
import EmptyState from '../inventory/components/pagestate/empty'
import ErrorState from '../inventory/components/pagestate/error'
import LoadingState from '../inventory/components/pagestate/loader'
import Card from './components/card'
import Footer from './components/footer'
import SideBar from './components/sidebar'
import { Container, Feed, Main, ProductGroup, ProductGroupCont } from './components/styles'

interface Props {

}

const data:any = [
    {
        name: "Cosmentics",
        img: 'https://i.pinimg.com/474x/32/2e/b8/322eb87d61c2359eb2f686dd38e17122.jpg'

    }
]

const Explorer = ({ }: Props): ReactElement => {

    const queries = useReactiveVar(queryOptions)
    const { category, subCategory } = useParams()

    function capFirstChar(str: string) {
        return str?.charAt(0).toUpperCase() + str?.slice(1);
    }

    const regenString: any = (str: string) => str ? str.replace(/-/g, " ") : str

    // const { data, loading, error, fetchMore, refetch } = useQuery(GET_GOODS, {
    //     variables: {
    //         query: '',
    //         category: capFirstChar(category),
    //         subCategory: capFirstChar(regenString(subCategory))
    //     },
    //     fetchPolicy: "network-only",
    // })

    let records = [

    ];

    // if (data) {
    //     console.log(data);
    //     records = data?.goods?.map((item: any) => {
    //         return item;
    //         const i = Math.floor(Math.random() * 16)
    //         return {
    //             ...item,
    //             media: [dummies[i]?.img]
    //         }
    //     })
    //     console.log(records)
    // }

    // if (error) console.log({ ...error })

    // const fetchMoreData = () => {
    //     fetchMore({
    //         variables: {
    //             ...queries,
    //             offset: data.goods.length,
    //         }
    //     })
    // }

    return (
        <>
            {/* {
                data ?
                    <MainHeader data={data} />
                    :
                    null
            } */}
            <Main>
                <Container> {
                        // data.map((d:any)=>{
                            <Card key={Math.random()} item={data} />
                        // })
                    }
                    
                    {/* {
                        loading ?
                            <LoadingState />
                            :
                            error ?
                                <ErrorState retryCallback={refetch} />
                                :
                                !data?.goods.length ?
                                    <EmptyState />
                                    :
                                    <InfiniteScroll
                                        dataLength={data.goods.length}
                                        next={fetchMoreData}
                                        hasMore={true}
                                        loader={null}
                                        style={{ overflow: 'visible' }}
                                    >
                                    {
                                        records.map((d: any) => (
                                            <Card key={Math.random()} item={d} />
                                        ))
                                    }
                                    </InfiniteScroll>
                    } */}
                </Container>
            </Main>
            <Footer />
        </>
    )
}

export default Explorer
