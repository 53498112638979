import React from 'react'
import { SkelItemCont, SkelImageCont, SkelItemInfo, SkelOpItemInfo, Flash, SkelInfo } from './styles'

const Skeleton = (props: any) => {
    return (
        <SkelItemCont h={70} {...props}>
            <SkelImageCont h={'55px'} w={'55px'} r={'6px'}>
                <Flash />
            </SkelImageCont>
            <SkelItemInfo >
                <div>
                    <SkelInfo mb={4}>
                        <Flash />
                    </SkelInfo>
                    <SkelInfo w={50}>
                        <Flash />
                    </SkelInfo>
                </div>
                <SkelInfo w={25}>
                    <Flash />
                </SkelInfo>
            </SkelItemInfo>
            <SkelOpItemInfo>
                <SkelInfo mb={4} w={70}>
                    <Flash />
                </SkelInfo>
                <SkelInfo w={40}>
                    <Flash />
                </SkelInfo>
            </SkelOpItemInfo>
        </SkelItemCont>
    )
}

export default Skeleton