import styled from "styled-components";

export const Container = styled.div.attrs({
    className: "container"
})`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: start;
    position: relative;
    padding-top: 20px;
    max-width: 1000px;
    height: 100%;
    @media (max-width: 768px) {
    padding: 0px 10px;
    
   }
    
    `
export const ItemsContainer = styled.section`
  width: 100%;
  display: flex;
  position: relative;
  flex-wrap: wrap;
   @media (max-width: 768px) {
    display: flex;
    padding: 0px;
    
   }
`;
export const Header = styled.h6`
  margin: 16px 0px;
  font-weight: 700;
  font-size: 20px;
  @media (max-width: 420px) {
    margin: 10px 0px;
    font-weight: 700;
    font-size: 16px;
    /* background: yellow; */
  }
`;
