import styled from "styled-components";

export const Header = styled.header`
  margin: 5px 0px;
  color: #343435;
  margin: auto;
  display: flex;
  width: 100%;
  height: 30px;
  align-items: center;
  position: relative;
  justify-content: space-between;
  h6 {
    font-size: 10px;
    font-weight: 700;
    color: grey;
    margin-bottom: 0px;
    text-transform: uppercase;
  }
`
export const Container = styled.div.attrs({
    className: "container",
})`
height: 100%;
position: relative;
max-width: 1000px;
padding: 0px;
padding-top: 18px;
`;

export const TableCont = styled.div`
  height: 100%;
  width: 99%;
  padding-right: 0px;
  position: relative;
  margin: 0 auto;
  @media(max-width: 768px) {
    display: none;
  }
`;

export const Main = styled.main`
    width: 100vw;
    height: 100vh;
    position: relative;
    padding-top: 140px;
     .window {
      width: 100%;
      text-align: center;
     }
`
export const ListGroupCont = styled.div`
  width: 100%;
`
export const ItemListCont = styled.div`
  padding-top: 60px;
  height: 100vh;
  @media(max-width: 768px) {
    padding-top: 20px;
     height: auto;
     background: whitesmoke;
  }
`