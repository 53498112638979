import { useMutation, useReactiveVar } from "@apollo/client";
import { useState } from "react";
import { More } from "../../../../components/icons";
import { Divider, Icon } from "../../../../components/icons/styles";
import { showCheckOutModal } from "../../../../globals";
import { DELETE_INVOICE } from "../../../../graphql/mutations/invoice.mutation";
import { GET_INVOICES } from "../../../../graphql/queries/invoice.query";
import { invCriteria } from "../../../../types/defaults";
import { CartItem } from "../../../../types/model";
import { formatMoney, getExpStatus } from "../../../../utils";
import { ActionList } from "../actionList";
import ImageItem from "../image";
import { InvoiceCont, Col, Row } from "./styles";

export const Invoice = (props: any) => {
    const [menu, setMenu] = useState(false);

    let { invoice } = props;

    const { query, group, filter } = useReactiveVar(invCriteria)

    const [deleteInvoice, { error, loading, data }] = useMutation(DELETE_INVOICE, {

        update: (cache, { data }) => {
            // console.log(data)
            const cached: any = cache.readQuery({
                query: GET_INVOICES,
                variables: {
                    offset: 0,
                    filter,
                    group,
                    query: query || ' ',
                },
            })

            let newInvoices = cached.invoices.map((grp: any) => {
                return (grp.records.some((r: any) => r._id === data.deleteInvoice._id)) ?
                    (
                        {
                            ...grp,
                            records: grp.records.filter((inv: any) => inv._id !== data.deleteInvoice._id)
                        }
                    )
                    :
                    grp
            })
            cache.writeQuery({
                query: GET_INVOICES,
                variables: {
                    offset: 0,
                    filter,
                    group,
                    query: query || ' ',
                },
                data: {
                    invoices: newInvoices.filter((p: any) => p.records.length)
                }
            });
        }
    })

    const handleDelete = (e: any) => {
        e.stopPropagation()
        setMenu(false)
        deleteInvoice({
            variables: {
                id: invoice._id
            }
        })
    }

    const showMenu = () => {
        setMenu(!menu)
    }
    if (error)
        console.log({ error })

    const closeMenu = () => {
        setMenu(false);
    }
    return (
        <InvoiceCont
            // onClick={() => addRemoveItem(stock)}
            onMouseLeave={() => closeMenu()}
        // selected={
        //     invoice.stocks.some((cartItem: CartItem) => cartItem.item._id === stock._id)
        // }
        >
            <ImageItem
                source={invoice.group[0].stocks?.item.mediaUrls[0] || 'd063578d-733d-4aca-8809-e18368d55184'}
                multiple={false}
            />
            <Row>
                <Col>
                    <p>{invoice.group[0].stocks?.item.name}</p>
                    <p>{invoice.group[0].stocks.item.description}</p>
                </Col>
                <Col>
                    <p></p>
                    <p>{ }</p>
                </Col>
                <Col>
                    <p>{invoice.subCount}</p>
                </Col>
                <Col>
                    <Icon onClick={() => showMenu()}>
                        <More />
                    </Icon>
                    <p>{formatMoney(invoice.subTotal)}</p>
                </Col>
                <Divider />
            </Row>
            {
                menu &&
                <ActionList deleteCallback={handleDelete} {...props} invoice={invoice} />
            }
        </InvoiceCont>
    )
}
