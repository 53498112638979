import React from 'react'
import { Plus } from '../../../../../components/icons'
import { Divider, Icon } from '../../../../../components/icons/styles'
import { showInventoryModal, tableState } from '../../../../../globals'
import { IconBtn } from '../../buttons/styles'
import { HeaderCont, HeaderListItems, Item } from './styles'


const TableHeader = () => {
    const showEditForm = ()=>{
        //show modal to edit item details.
        showInventoryModal(true)
    }
    return (
        <HeaderCont>
            <HeaderListItems>
                <Item>
                    <h6>STOCK</h6>
                </Item>
                <Item>
                    <h6>CATEGORY</h6>
                </Item>
                <Item>
                    <h6>INSTOCK</h6>
                </Item>
                <Item>
                    <h6>
                        SELLING PRICE
                        {/* <span>
                            <IconBtn onClick={() => showEditForm()}>
                                <Icon>
                                    <Plus />
                                </Icon>
                            </IconBtn>
                        </span> */}
                    </h6>
                </Item>
                <Divider />
            </HeaderListItems>
        </HeaderCont>
    )
}

export default TableHeader