import styled from "styled-components";

export const ExpenseCont = styled.div<any>`
  width: 100%;
  height: 60px;
  display: grid;
  /* grid-template-columns: 50px auto; */
  gap: 0px 10px;
  border-radius: 8px;
  cursor: pointer;
  position: relative;
  margin: auto;
  align-items: center;
  padding: 0px;
  li:nth-child(3) {
    .icon {
      width: 22px;
      height: 22px;
      display: none;
    }
  }
  &::before {
    content:"";
    display: block;
    position: absolute;
    height: 100%;
    width: 101%;
    left: -6px;
    bottom: 0;
    border-radius: inherit;
    background: ${props => props.selected ? '#25ec78' : ''};
  }
  :hover {
    cursor: pointer;
    &::before {
      background: ${props => props.selected ? '#25ec78' : 'whitesmoke'};
    }
    ul {
      .divider {
        display: none;
      }
    }
    li {
      p:last-child {
        color: #363636;
      }
    }
    li:nth-child(3) {
      .icon { 
        display: flex;
      }
      p,.divider {
        display: none;
      }
    }
  }
  /* :hover {
    cursor: pointer;
    
    &::before {
      background: ${props => props.selected ? '#25ec78' : 'whitesmoke'};
    }
    ul {
      .divider {
        display: none;
      }
    }
    li {
      p:last-child {
        color: #363636;
      }
    }
    li:nth-child(3) {
      .icon { 
        display: flex;
      }
      p,.divider {
        display: none;
      }
    }
  } */
  background: ${props => props.selected ? '#25ec78' : 'white'};
`;
export const ImageCont = styled.div`
  width: 50px;
  height: 50px;
  border: none;
  position: relative;
  border-radius: 6px;
  position: relative;
  z-index: 1;
  background: #e3e3e3;
  img {
    height: 100%;
    width: 100%;
    border-radius: inherit;
    object-fit: cover;
    border: none;
    mix-blend-mode: multiply;
    filter: contrast(1);
    vertical-align: top center;
  }
`;
export const ExpensPropsList = styled.ul`
  width: 100%;
  height: 100%;
  display: grid;
  position: relative;
  margin: 0px;
  padding-left: 0px;
  grid-template-columns:47% 1fr 1fr;

`;
export const PropItem = styled.li<any>`
  height: 100%;
  width: 100%;
  cursor: pointer;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  p:first-child {
    margin-bottom: 0px;
    font-size: 13px;
  }
  p {
    margin-bottom: 0px;
    font-size: 12px;
    position: relative;
    width: 98%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #181818;
  }
  p:last-child {
    color:  ${props => props.selected ? '#393a3a' : 'grey'};
  }
  &:nth-child(3) {
    p {
      text-align: right;
      padding-right: 0px;
    }
  }
  &:nth-child(3) {
    align-items: flex-end;
    text-align: right;
    padding-right: 0px;
  }
`;

