import './App.css'
import { RouterProvider } from 'react-router-dom'
import { router } from './router'
import { GoogleOAuthProvider } from '@react-oauth/google';

import { useEffect } from 'react';
import { requestPermission } from './requestPermission';

const App = () => {

  useEffect(() => {
    requestPermission();
  }, []);
 
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID||''}>
      <RouterProvider router={router} />
    </GoogleOAuthProvider>
  )
}

export default App
