import React from 'react'
import { Loader } from '../../../../components/loaders';
import { ButtonItem } from './styles'

const Button = (props: any) => {
  const { loading } = props;
  const { title, disabled } = props
  return (
    <ButtonItem disabled={disabled}>
      {
        loading ? <Loader /> : title
      }
    </ButtonItem>

  )
}

export default Button