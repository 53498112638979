import styled from "styled-components";

export const StaffWrapper = styled.div`
  position: relative;
  width: 20%;
  height: 240px;
 
`
export const StaffCont = styled.div<any>`
  width: 100%;
  height: 240px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
   padding-top: 20px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  :hover {
    background-color: rgba(71, 75, 76, 0.055);
  }
  h4 {
    text-align: center;
    max-width: 130px;
    margin-bottom: 5px;
    margin-top: 10px;
    font-size: 14px;
  }
  p:last-child {
    margin-top: -4px;
    max-width: 180px;
    color: ${props => props.theme.light.colors.labels.sec};
    font-size: ${props => props.theme.typography.body1};
  }
`
export const ImageCont = styled.div`
  width: 150px;
  height: 150px;
  border: none;
  position: relative;
  border-radius: 50%;
  position: relative;
  z-index: 1;
  background: #e3e3e3;
  img {
    height: 100%;
    width: 100%;
    border-radius: inherit;
    object-fit: cover;
    border: none;
    mix-blend-mode: multiply;
    filter: contrast(1);
    /* vertical-align: top center; */
  }
`;