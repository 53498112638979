import { useReactiveVar } from '@apollo/client'
import React from 'react'
import { showInvoiceModal } from '../../globals'
import { globalInvoice, initInvoice } from '../../globals/defaults'
import InvoiceInfo from '../../pages/invoices/component/details'
// import { globalInvoice } from '../../types/defaults'
import { StockModalCont } from './styles'


const InvoiceModal = () => {
    const show = useReactiveVar(showInvoiceModal)
    const close = (e: Event) => {
        e.stopPropagation()
        globalInvoice(initInvoice)
        showInvoiceModal(false)
    }
    return (
        <StockModalCont onClick={(e: Event) => close(e)} show={show}>
            <InvoiceInfo/>
        </StockModalCont>
    )
}

export default InvoiceModal