import React, { useEffect, useState } from 'react'
import { getImageUrl } from '../../../../apollo'
import { Cancel } from '../../../../components/icons'
import { Divider, Icon, VDivider } from '../../../../components/icons/styles'
import { showInventoryModal, tableState } from '../../../../globals'
import { initProduct } from '../../../../globals/defaults'
import { formatTime, format_date, getExpStatus, simplifyExpDate } from '../../../../utils'
import { DetailsCont, HeaderCont, ImageCont, Info, InfoColumn, InfoColumnCont } from './styles'
import { Product } from '../../../../models'

const StockDetails = ({ stock:item }: { stock: Product }) => {
    const status = item ? getExpStatus(item) : '';

    let colorMark = ''

    if (status) {
        colorMark = status === 'weak' ? '#ffd108' : status === 'expired' ? 'red' : ''
    }
    const [stock, setStock] = useState(initProduct)

    useEffect(() => {
      setStock(item)
    }, [item])

    const closeDetails = ()=> {
        tableState({
            state: '',
            item: null
        })
        showInventoryModal(false)
    }
    
  return (
      <DetailsCont>
          <HeaderCont>
              <h6>Product Details</h6>
              <Icon onClick={closeDetails}>
                  <Cancel />
              </Icon>
              <Divider />
          </HeaderCont>
          <InfoColumnCont>
            <InfoColumn className='icolunm'>
                <h6>PRODUCT</h6>
                <Info>
                    <h6>NAME</h6>
                    <p>{stock.name}</p>
                </Info>
                <Info>
                    <h6>DESCRIPTION</h6>
                      <p>{`${stock.size.val||''}${stock.size.unit||''}`} {stock.description || '---'}</p>
                </Info>
                <Info>
                    <h6>CATEGORY</h6>
                    <p>{stock.categories.category || '---'}</p>
                </Info>
            </InfoColumn>
              <InfoColumn className='icolunm'>
                  <h6>MEDIA</h6>
                  <ImageCont>
                      {stock.mediaUrls[0] && <img src={getImageUrl(stock.mediaUrls[0])} alt="" />}
                  </ImageCont>
              </InfoColumn>
            <InfoColumn className='icolunm'>
                <h6>PRICING</h6>
                <Info>
                    <h6>COST PRICE</h6>
                    <p>{stock.costPrice || '---'}</p>
                </Info>
                <Info>
                    <h6>SELLING PRICE</h6>
                    <p>{stock.sellingPrice}</p>
                </Info>
                <Divider />
            </InfoColumn>
          <InfoColumn className='icolunm'>
                <h6>TRACKING</h6>
              <Info>
                  <h6>IN STOCK</h6>
                  <p>{stock?.q.val}</p>
              </Info>
              <Info>
                  <h6>STOCK LEVEL</h6>
                  <p>{stock.warningCount || '---'}</p>
              </Info>
              <Info>
                  <h6>EXPIRY</h6>
                  <p style={{ color: colorMark }}>{stock.expiry ? simplifyExpDate(stock.expiry) : '---'}</p>
              </Info>
              <Info>
                  <h6>WARNING PIRIOD</h6>
                  <p>{stock.expiryWarning ? `${stock.expiryWarning} Month${stock.expiryWarning > 1 ? 's' : ''}` : '---'}</p>
              </Info>
              <Info>
                  <h6>PRODUCT ID</h6>
                  <p>{stock._id?.toLocaleUpperCase()}</p>
              </Info>
              <Divider />
          </InfoColumn>
            <InfoColumn className='icolunm'>
             <h6>TIMESTAMP</h6>
              <Info>
                  <h6>ADDED</h6>
                  {/* <p>{format_date(stock.createdAt)} {formatTime(stock.createdAt)} <span> - </span>{stock?.added?.firstName || '---'} {stock?.added?.lastName || ''}</p> */}
              </Info>
              <Info>
                  <h6>LAST MODIFIED</h6>
                  {/* <p>{format_date(stock.updatedAt)} {formatTime(stock.updatedAt)}<span> - </span>{stock?.modified?.firstName || '---'} {stock?.modified?.lastName || ''}</p> */}
              </Info>
          </InfoColumn>
          </InfoColumnCont>
      </DetailsCont>
  )
}

export default StockDetails