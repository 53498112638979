import styled from "styled-components";

export const MessageCont = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h6 {
    font-size: 22px;
    width: 100%;
    text-align: center;
    font-weight: 700;
  }
  p {
    margin-bottom: 0px;
    color: #939292;
    font-weight: 500;
    font-size: 13px;
  }
`