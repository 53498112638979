import styled from "styled-components"

export const Cont = styled.header`   
   .card {
        padding: 0px 0px;
        overflow: hidden;
        height: 360px;
        width: 100%;
        border-radius: 12px;
        margin-bottom: 22px;
        padding-bottom: 10px;
        border-color: #eaeaea;
        @media(max-width: 768px) {
           margin-bottom: 10px;
        }
    }
`
export const Header = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px;
    height: 40px;
    background-color: #ffffff;
    h6 {
        margin-bottom: 0px;
        font-size: 14px;
    }
`
export const Body = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0px 14px 0px 14px;
  position: relative;
  bottom: 10px;
  h4  {
    font-weight: bold;
    margin: 0px;
    font-size: 1.25rem;
  }
`
export const Footer = styled.footer`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 14px;
    height: 40px;
    margin: 10px 0px;
    position: relative;
    bottom: 20px;
    p {
        margin-bottom: 0px;
        font-size: 11px;
        padding: 0px 10px;
        color: rgb(142, 142, 145)
    }
`
export const BtnGroup = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:2px;
    height: 26px;
    width: 120px;
    background: whitesmoke;
    border-radius: 6px;
`
export const ToggleBtn = styled.button<any>`
    display: flex;
    justify-content: center;
    align-items: center;
    padding:2px;
    height: 100%;
    width: 33%;
    box-shadow: none;
    outline: none;
    border: none;
    background: ${props=>props.active ? 'white': 'whitesmoke'};
    border-radius: 6px;
    p {
      margin-bottom: 0px;
      font-size: 11px;
    }
`
export const SubHeader = styled.header`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
    padding: 0px 14px;
    margin-top: -10px;
    margin-bottom: 20px;
    h6 {
        margin-bottom: 0px;
        font-size: 14px;
    }
    h4  {
      font-weight: 700;
      margin: 0px;
      font-size: 1.25rem;
    }
    h4:first-child {
      margin-bottom: 10px;
    }
`

export const BarChartCont = styled.div<any>`
    height: 200px;
    width: 100%;
    bottom:0px;
    border-radius: 12px;
    position: relative;
  `
export const LoadingCont = styled.div<any>`
   height: 100%;
   width: 100%;
   display: flex;
   position: absolute;
   flex-direction: row;
   align-items: center;
   justify-content: center;
   align-self: center ;
`