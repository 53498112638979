
import React, { SyntheticEvent, useState } from 'react'
// import { roundAmount } from '../../charts/header'

import { Ellipsis, Chevron } from '../../icons'
import { Icon, Divider } from '../../icons/styles'
import { ImageItem } from './image'
import { 
    Card,  
    Footer, Header, ItemsCont, 
} from './styles'
import { formatMoney, format_date, roundAmount } from '../../../utils'

import Invoice from './invoiceListItem'

type Props = {}

function PaymentCard(props: any) {

    const { list:items, count, total, groupId } = props;
    // console.log(`itesm: ${JSON.stringify((items), null, 2)}`)

    return (
        <ItemsCont>{
                <Card>
                    <Header>
                        {groupId.toUpperCase()}
                        <Divider />
                    </Header>{
                        items.filter((r: any) => r.stocks.length > 0).map((record: any, i: number) => (
                            <Invoice 
                                index={i} 
                                key={record._id} 
                                invoice={record} 
                                count={count} 
                                stock={record.stocks[0]} 
                            />
                        ))
                    }
                    <Footer>
                        <Divider bottom={100} />
                    <p>+{count - 5 <= 0 ? 0 : count - 5}</p>
                        <Icon rot={90} size={6}>
                            <Chevron />
                        </Icon>
                    <h6>{formatMoney(total)}</h6>
                    </Footer>
                </Card>
        }
        </ItemsCont>
    )
}

export default PaymentCard