import React from 'react'
import { NavLink } from 'react-router-dom'
import { getImageUrl } from '../../../../apollo'
import { BagPlus, Like } from '../../../../components/icons'
import { Icon } from '../../../../components/icons/styles'
import { formatMoney } from '../../../../utils'
import { Star } from '../../../details/components/icons'
import { CardCont, Description, ImageCont, LikeCont, PriceCont } from './styles'

const Card = (props: any) => {
    const { item } = props
    const setLocalStorage = ()=>{
            // localStorage.setItem('owner', item?.owner)
    }
    return (
        <NavLink onClick={setLocalStorage} className="prod--link" to={``}>
            <CardCont>
                {/* <LikeCont>
                    <Icon>
                        <Like />
                    </Icon>
                    <p>2K</p>
                </LikeCont> */}
                <div className="cart-btn">
                    {/* <Icon className='bag'>
                        <BagPlus />
                    </Icon> */}
                </div>
                <ImageCont>
                    {
                        <img src={item.img} alt="" /> 
                    }
                </ImageCont>
                <Description>
                    <p className='desc'>{item.name}</p>
                    <PriceCont>
                        {/* <h6>
                            <sub>N</sub>{formatMoney(item.sellingPrice)}
                        </h6> */}
                    </PriceCont>
                </Description>
            </CardCont>
        </NavLink>
    )
}

export default Card