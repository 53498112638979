import React from 'react'
import { BtnCont, ButtonItem } from './styles'

const Button = (props: any) => {
    const { title } = props
    return (
        <BtnCont>
            <ButtonItem>
                {title}
            </ButtonItem>
        </BtnCont>
    )
}

export default Button