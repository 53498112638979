import React, { SyntheticEvent, useState } from 'react'
import { getImageUrl } from '../../../apollo'
import { ImageItem } from './image'
import { Divider, Icon } from '../../icons/styles'
import { Ellipsis } from '../../icons'
import { formatMoney, getExpStatus } from '../../../utils'
import { cart, showInventoryModal, tableState } from '../../../globals'
import { GET_PRODUCTS } from '../../../graphql/queries'
import { queryOptions } from '../../../types/defaults'
import { useMutation, useReactiveVar } from '@apollo/client'
import { DELETE_PRODUCT } from '../../../graphql/mutations'
import { CartItem } from '../../../models'
import { Desc, DescCol, DescCont, Instock, MenuOptionsList, PriceCont, StockCont, StockPrice, Title, Option, TitleCont } from './styles'

const StockListItem = (props: any) => {
    const myCart = useReactiveVar(cart)
    const {stock, count, index}= props
    const [showMenu, setShowMenu] = useState<any>(false)

    const selectListOption = (e: SyntheticEvent) => {
        e.stopPropagation();
        setShowMenu(false)
    }
    const opendEditor = (e: any, state: string) => {
        e.stopPropagation();
        tableState({
            ...tableState(),
            state,
            item: props.stock
        })
        setShowMenu(false)
        showInventoryModal(true)
    }


    const updateCart = (s: any) => {
        if (s.q.val <= 0) return
        const { __typename, ...striped } = s;
        let { stocks } = cart()
        const i = stocks.find((item: CartItem) => item._id === s._id)

        stocks = (!i) ?
            [
                ...stocks, {
                    _id: stock._id,
                    quantity: 1,
                    booked: false,
                    delivered: 0,
                    item: striped,
                }
            ]
            :
            stocks.filter((s: any) => s._id !== i._id)

        cart({ ...cart(), stocks })

    }

    const [deleteProduct, { error, loading }] = useMutation(DELETE_PRODUCT, {
        update: (cache, { data: { deleteProduct: deleted } }) => {
            const cached: any = cache.readQuery({
                query: GET_PRODUCTS,
                variables: {
                    ...queryOptions()
                }
            })

            let newProducts = cached.products.map((prods: any) => {
                return (prods.records.some((r: any) => r._id === deleted._id)) ?
                    ({
                        ...prods,
                        records: prods.records.filter((p: any) => p._id !== deleted._id)
                    })
                    :
                    prods
            })
            cache.writeQuery({
                query: GET_PRODUCTS,
                variables: {
                    ...queryOptions()
                },
                data: {
                    products: newProducts.filter((p: any) => p.records.length)
                }
            });
        }
    });

    if (error) console.log({ error })

    const handelDeleteProduct = (id: string, e: any) => {
        e.stopPropagation();
        deleteProduct({
            variables: {
                id
            },
        })
    }
  return (
      <StockCont onClick={() => updateCart(stock)}>
          <ImageItem
            expiry={stock.expiry}
            expiryStatus={getExpStatus(stock)}
            source={getImageUrl(stock.mediaUrls[0] || 'd063578d-733d-4aca-8809-e18368d55184')}
            selected={
                myCart.stocks.some((cartItem: CartItem) => cartItem.item._id === stock._id)
            }
           />
          <DescCol>
              <DescCont>
                  <TitleCont>
                      <Title>{stock.name}</Title>
                      <Icon onClick={(e: SyntheticEvent) => setShowMenu(true)} rot={90} >
                          <Ellipsis />
                      </Icon>
                      <MenuOptionsList show={showMenu}>
                          <Option onClick={(e: SyntheticEvent) => opendEditor(e,'editing')}>
                              <p>Edit</p>
                          </Option>
                          <Option onClick={(e: SyntheticEvent) => selectListOption(e)} >
                              <p>Info</p>
                          </Option>
                          <Option onClick={(e: SyntheticEvent) => handelDeleteProduct(stock._id, e)}>
                              <p style={{ color: 'red' }}>Delete</p>
                          </Option>
                      </MenuOptionsList>
                  </TitleCont>
                  <Desc>{stock.description}</Desc>
              </DescCont>
              <PriceCont>
                  <Instock>{stock?.q?.val}</Instock>
                  <StockPrice>{formatMoney(stock.sellingPrice)}</StockPrice>
              </PriceCont>
              {
                  index + 1 !== count && <Divider />
              }
          </DescCol>
      </StockCont>
  )
}

export default StockListItem