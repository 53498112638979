import React from 'react'
import { Header, ItemsCont } from './styles'
import { Divider } from '../../../../../components/icons/styles'
import { Stock } from '../../stock';
import { queryOptions, sharedModal } from '../../../../../types/defaults';

import { useReactiveVar } from '@apollo/client';
import { format_date } from '../../../../../utils';
import { showStockEditModal, tableState } from '../../../../../globals';
import ShareForm from '../../forms/share';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

const StockList = (props: any) => {
    const { list: items, count, total, groupId } = props;
    const { query, group, filter } = useReactiveVar(queryOptions)
    const showEditModal = useReactiveVar(showStockEditModal)
    const table = useReactiveVar(tableState)

    const sm: any = useReactiveVar(sharedModal)

    const location = useLocation() as any
    const params = new URLSearchParams(location.search)

    
    return (
        <>
            <Header>
                <h6>{params.get("p") === 'date' ? format_date(params.get("q")) : params.get("q") || 'others'}</h6>
                <h6> <Link></Link></h6>
                <Divider />
            </Header>
            {
                items.map((stock: any) =>
                (
                    <ItemsCont key={Math.random()}>
                        <Stock key={stock._id} stock={stock} />
                        {
                            sm === stock._id && <ShareForm stock={stock} />
                        }
                    </ItemsCont>
                ))
            }
            {/* <Footer>
                <p>+{count - 5 <= 0 ? 0 : count - 5}</p>
                <h6>{formatFigures(total)}</h6>
            </Footer> */}
        </>
    )
}

export default StockList