import React from 'react'
import { ImageCont } from './styles'
import { Cancel } from '../../../../icons'
import { Icon } from '../../../../icons/styles'
import { getImageUrl } from '../../../../../apollo'

function StockImage(props: any) {
    const { source, expiry, expiryStatus, removeStockCallback } = props

    return (
        <ImageCont>
            <Icon onClick={removeStockCallback}>
                <Cancel />
            </Icon>
            {source ? <img src={getImageUrl(source)} alt="" /> : null}
        </ImageCont>
    )
}

export default StockImage