import React, { useState } from 'react'
import { SectionCont, Setting, SettingGroup, SettingItem, SettingsCont } from './styles'
import { Divider, Icon } from '../../../components/icons/styles'
import { showAccModal } from '../../../globals'
import PhoneForm from './components/forms/phone'
import { useQuery } from '@apollo/client'
import { GET_MY_ACCOUNT } from '../../../graphql/queries'
import NameForm from './components/forms/name'
import MailForm from './components/forms/email'
import BioForm from './components/forms/bio'
import UsernameForm from './components/forms/username'
import PwdForm from './components/forms/pwd'
import { PageLoader } from '../../../components/loaders'

const Account = () => {

    const [phone, setPhone] = useState<any>('');
    const [mail, setMail] = useState<any>('');
    const [username, setUserName] = useState<any>('');
    const [info, setInfo] = useState<any>(null);
    const { loading, data, refetch, error } = useQuery(GET_MY_ACCOUNT, {
        fetchPolicy: "network-only"
    })

    if (error) {
        console.log({ ...error });
    }
    if(data) console.log(data);
    
    const onEditPhone = (phone: string) => {
        showAccModal('phone')
        setPhone(phone)
    }
    const onEditBio = (info: any) => {
        showAccModal('bio')
        setInfo(info)
    }
    const onEditMail = (mail: string) => {
        showAccModal('mail')
        setMail(mail)
    }
    const onEditUsername = (username: string) => {
        showAccModal('username')
        setUserName(username)
    }
    return (
        <> {
            data ?
                <>
                    <SectionCont>
                        <SettingsCont>
                            <h5>Account Information</h5>
                            <SettingGroup>
                                <SettingItem>
                                    <Setting active={data.myAccount.email ? true : false}>
                                        <p>Email</p>
                                        <p>{data.myAccount.email || 'Add email'}</p>
                                    </Setting>
                                    <p>
                                        {
                                            data.myAccount.phone ?
                                                <span onClick={() => onEditMail(data.myAccount.email)}>
                                                    Change
                                                </span>
                                                :
                                                <span onClick={() => (showAccModal('mail'))}>
                                                    Add
                                                </span>
                                        }
                                    </p>
                                    {/* <p className='add__btn' onClick={() => (showAccModal('email'))}>+</p> */}
                                    <MailForm mail={mail} />
                                </SettingItem>
                                <SettingItem>
                                    <Setting active={data.myAccount.username ? true : false}>
                                        <p>Username</p>
                                        <p>@{data.myAccount?.username || 'Add username'}</p>
                                    </Setting>
                                    <p>
                                        {
                                            data.myAccount?.username ?
                                                <span onClick={() => onEditUsername(data.myAccount.username)}>
                                                    Change
                                                </span>
                                                :
                                                <span onClick={() => (showAccModal('username'))}>
                                                    Add
                                                </span>
                                        }
                                    </p>
                                    {/* <p className='add__btn' onClick={() => (showAccModal('email'))}>+</p> */}
                                    <UsernameForm username={username} />
                                </SettingItem>
                                <SettingItem>
                                    <Setting active={data.myAccount.password ? true : false}>
                                        <p>Password</p>
                                        <p>*************</p>
                                    </Setting>
                                    <p>
                                        <span onClick={() => (showAccModal('pwd'))}>
                                            Change
                                        </span>
                                    </p>
                                    <PwdForm />
                                </SettingItem>

                            </SettingGroup>
                            <Divider />
                        </SettingsCont>
                    </SectionCont>
                    <SettingsCont>
                        <h5>Personal Information</h5>
                        <SettingGroup>
                            <SettingItem>
                                <Setting active={data.myAccount?.info?.name ? true : false}>
                                    <p>Change</p>
                                    <p>{data.myAccount.info?.name || '-'}</p>
                                </Setting>
                                <p onClick={() => (showAccModal('name'))}>
                                    <span>Edit</span>
                                </p>
                                <NameForm info={data.myAccount?.info} />
                            </SettingItem>
                            <SettingItem>
                                <Setting active={data.myAccount.phone ? true : false}>
                                    <p>Phone</p>
                                    <p>{data.myAccount.phone || '-'}</p>
                                </Setting>
                                <p>
                                    {
                                        data.myAccount.phone ?
                                            <span onClick={() => onEditPhone(data.myAccount.phone)}>
                                                Change
                                            </span>
                                            :
                                            <span onClick={() => (showAccModal('phone'))}>
                                                Add
                                            </span>
                                    }
                                </p>
                                <PhoneForm phone={phone} />
                            </SettingItem>
                            <SettingItem>
                                <Setting active={data.myAccount.info?.bio ? true : false}>
                                    <p>Bio</p>
                                    <p>{data.myAccount?.info?.bio || '-'}</p>
                                </Setting>
                                <p>
                                    {
                                        data.myAccount.info?.bio ?
                                            <span onClick={() => onEditBio(data.myAccount.info)}>
                                                Change
                                            </span>
                                            :
                                            <span onClick={() => (showAccModal('bio'))}>
                                                Add
                                            </span>
                                    }
                                </p>
                                <BioForm info={info} />
                            </SettingItem>
                            <Divider />
                        </SettingGroup>
                    </SettingsCont>
                </>
                :
                error ?
                <p>Connection Error</p>
                :
                <PageLoader />
        }

        </>
    )
}

export default Account