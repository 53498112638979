import React, { useState } from 'react'
import { SectionCont, SectionHeader, Setting, SettingItem, SettingsCont } from './styles'
import { Divider } from '../../../components/icons/styles'
import Form from '../address/components/forms'
import { useQuery, useReactiveVar } from '@apollo/client'
import { showAccModal } from '../../../globals'
import { GET_MY_ACCOUNT } from '../../../graphql/queries'
import { PageLoader } from '../../../components/loaders'
import { Address as AddressModel, Info } from '../../../models/account'
import { stripTypename } from '../../../utils'

const Address = () => {
    const initAddress: AddressModel = {
        country: '',
        city:'',
        state: '',
        street: '',
        zipCode: '',
    }

    const [address, setAddress] = useState<AddressModel>(initAddress)

    const { loading, data, refetch, error } = useQuery(GET_MY_ACCOUNT, {
        fetchPolicy: "network-only"
    })

    if (error) {
        console.log({...error});
    }

    const showEditForm = (address: AddressModel)=>{
        showAccModal('address')
        setAddress(stripTypename(address))
    }

    return (
        <SectionCont> {
            data ?
                <>
                    <SectionHeader>Addresses <span onClick={() => (showAccModal('address'))}>+</span></SectionHeader>
                    <SettingsCont>{
                        data.myAccount.addresses.map((address: AddressModel) => (
                                <>
                                    <SettingItem key={address._id}>
                                        <Setting active={address.street}>
                                            <p className="street">{`${address.street}`}</p>
                                            <p>{`${address.city}, ${address.state} Nigeia`} </p>
                                        </Setting>
                                        <p onClick={() => showEditForm(address)}>Edit</p>
                                    </SettingItem>
                                </>
                        ))
                    }
                    <Divider />
                    </SettingsCont>
                    <Form addr={address} />
                </>
                : loading ?
                <PageLoader />
                :
                <div style={{ textAlign: "center", height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <p>Something went wrong</p>
                </div>

        }

        </SectionCont>
    )
}

export default Address