import { gql } from "@apollo/client";

export const DELETE_INVOICE = gql`
    mutation deleteInvoice( $id: String!) {
        deleteInvoice(id: $id) {
                _id
                tid
                stocks {
                    _id
                    item {
                        _id
                        name
                        description
                        categories {
                            name
                            category
                        }
                        mediaUrls
                        costPrice
                        sellingPrice
                        owner
                        added {
                            firstName
                            lastName
                            phone
                            email
                        }
                        modified {
                            firstName
                            lastName
                            phone
                            email
                        }
                        createdAt
                        updatedAt
                    }
                    booked
                    quantity
                    delivered
                }
                modifier
                customer {
                    firstName
                    lastName
                    phone
                    email
                    address
                }
                added {
                    firstName
                    lastName
                    phone
                    email
                }
                modified {
                    firstName
                    lastName
                    phone
                    email
                    address
                }
                recieved
                payable
                completed
                paymentMethod
                createdAt
                updatedAt
            }
        }
`