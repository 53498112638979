import React from 'react'
import { Calender, Phone } from '../../../../../components/icons'
import { Icon } from '../../../../../components/icons/styles'
import { chatView, globalUser, openChatBox } from '../../../../../globals'
import { format_date } from '../../../../../utils'
import Button from '../button'
import { MessageBtn, MoreBtn } from '../button/styles'
import { ButtonsCont, ContactsCont, ContactsItem, Container, DpCont, DpImageCont, ImageCont, SubInfo } from './styles'

const Preview = (props: any) => {
    const { creds, view, loading, data, error } = props
    // console.log(`Creds: ${creds}`)
    // const openChat = () => {
    //     globalUser(staff)
    //     openChatBox(true)
    //     chatView('chat')
    // }
    return (
        <DpCont in={view === 'dp'}>
            <DpImageCont>
                <ImageCont>
                    <img src='https://i.pinimg.com/474x/4a/02/4a/4a024aade5a7f2d0ea7239d9e75c8ac2.jpg' alt="" />
                </ImageCont>
                <h4>{ creds ? creds.name : ''}</h4>
                <p className='username'>{creds ? creds?.username : ''}</p>
            </DpImageCont>
            <ContactsCont>
                <ContactsItem>
                    <Icon>
                        <Phone />
                    </Icon> 
                    <p>
                        { creds ? creds.phone : ''}
                    </p>
                </ContactsItem>
                <ContactsItem>
                    <Icon id="date">
                        <Calender />
                    </Icon>
                    <p>
                        {/* November, 2024 */}
                        {format_date(new Date())}
                    </p>
                </ContactsItem>
            </ContactsCont> {
            
                error && <p className="error">{error?.message}</p>
            }
            <Button
                data={data}
                title='Add Staff'
                loading={loading}
            />
        </DpCont>
    )
}

export default Preview