import styled from "styled-components";
export const Main = styled.main`
  width: 100%;
  height: 100vh;
  position: relative;
  background: white;
`;
export const Container = styled.div.attrs({
  className: "container",
})`
  height: 100%;
  padding-top: 0px;
  max-width: 1000px;
  position: relative;
  margin-bottom: 0px;
  @media (max-width: 768px) {
    /* columns: 165px;
    column-gap: 5px; */
  }
`;
export const AccountCont = styled.div`
  width: 100%;
  position: absolute;
  top: -70px;
  background: white;
  h1 {
    padding: 0px 20px;
    margin: 0px 0px 20px 0px;
    font-size: 24px;
    font-weight: 700;
  }
`;

export const CustomerPriInfo = styled.div`
  position: relative;
  background: white;
  top: 60px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  /* padding: 0px 20px; */
   @media(max-width: 768px) {
    padding: 0px;
    top: 34px;
  }
`;
export const BizInfoCont = styled.div`
  position: relative;
  background: white;
  display: flex;
  flex-direction: column;
  /* padding: 0px 20px; */
  @media(max-width: 768px) {
    padding: 0px;
  }
`;
export const BizName = styled.h6`
  width: 100%;
  position: relative;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 0px;
`;
export const Address = styled.p`
  width: 100%;
  position: relative;
  font-size: 12px;
  color: rgb(83, 100, 113);
  margin-bottom: 0px;
`;
export const FollowsCont = styled.div`
  width: 100%;
  position: relative;
  margin-top: -35px;
  margin-bottom: 20px;
  /* padding: 0px 20px; */
   @media(max-width: 768px) {
    padding: 0px;
  }
`;
export const ContactsCont = styled.div`
  min-width: 420px;
  height: 50px;
  /* padding: 0px 20px; */
  position: relative;
  display: flex;
  align-items: start;
  flex-direction: column;
  margin-bottom: 75px;
   @media(max-width: 768px) {
    padding: 0px;
     margin-bottom: 45px;
  }
`;
export const Bio = styled.p`
  width: 50%;
  /* padding: 0px 20px; */
  margin-bottom: 12px;
  margin-top:16px;
  font-size: 14px;
  font-weight: normal;
  color: black;
   @media(max-width: 768px) {
    padding: 0px;
    width: 96%;
    max-width: 96%;
  }
`;

export const Button = styled.button<any>`
  height: 40px;
  width: 80px;
  border-radius: 30px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid lightgrey;
  background: white;
  font-size: 12px;
  font-weight: 500;
  span {
    font-size:  18px;
    margin-right: 5px;
    position: relative;
    bottom: 1px;
  }

  &:hover {
    border: none;
    background-color: ${(props) => (props.buy ? "#10c34f" : "#1ed760")};
    color: ${(props) => (props.buy ? "white" : "white")};
  }
  :hover {
    svg {
      stroke: "white";
    }
  }
 
`;
export const CircularBtn = styled.button<any>`
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin-right: 5px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid lightgrey;
  background: white;
  .icon {
    width: 18px;
    height: 18px;
  }
  #bell {
    width: 14px;
    height: 14px;
  }
  &:hover {
    border: none;
    background-color: ${(props) => (props.buy ? "#10c34f" : "#1ed760")};
    color: ${(props) => (props.buy ? "white" : "white")};
  }
  :hover {
    svg {
      stroke: "white";
    }
  }
`;

export const ContactsItem = styled.div`
  height: 100%;
  width: auto;
  margin-bottom: 7px;
  display: flex;
  align-items: start;
  position: relative;
  .icon {
    width: 18px;
    height: 18px;
    margin-right: 3px;
  }
  p {
    font-size: 14px;
    margin-bottom: 0px;
    color: rgb(83, 100, 113);
    margin-right: 7px;
    span {
      color: rgb(83, 100, 113);
      font-weight: normal;
    }
  }
  &:first-child {
    margin-right: 10px;
  }
  .address {
    max-width:700px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;
export const ItemsCont = styled.ul`
  width: 300px;
  height: 50px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0px;
  margin-top: 20px;
`;
export const Item = styled.li`
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  p {
    font-size: 14px;
    margin-bottom: 0px;
    font-weight: 700;
    span {
     color: rgb(83, 100, 113);
      font-weight: normal;
    }
  }
`;

export const NavLinks = styled.ul`
  padding: 0px;
  list-style: none;
  width: 100%;
  height: 40px;
  margin-bottom: 0px;
  display: flex;
  position: relative;
  margin: auto;
  width: 100%;
  a {
    text-decoration: none;
    height: 100%;
    color: inherit;
    align-items: center;
    display: flex;
    margin-bottom: 0px;
  }
  @media(max-width: 768px) {
     width: 100%;
  }
`;
export const LinkItem = styled.li<any>`
  height: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 0px;
  margin: 0px 16px;
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  &:first-child {
    margin-left: 0px;
  }
  :hover {
    p {
      color: black;
    }
  }
  p {
    margin-bottom: 0px;
    font-size: 12px;
    font-weight: 600;
    color: ${(props) => (props.active ? "black" : "rgb(83, 100, 113)")};
  }
  border-bottom: 2px solid  ${(props) => (props.active ? "#1b1c1b" : "initial")}
`;
