import { useQuery } from '@apollo/client'
import React, { ReactElement } from 'react'
import { useParams } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { NavLink } from 'react-router-dom'
import { Outlet } from 'react-router-dom'
import { getImageUrl } from '../../apollo'
import ChatBox from '../../components/chat'
import { Phone, Envelop, Location, NotifIcon, Calender } from '../../components/icons'
import { Divider, Icon } from '../../components/icons/styles'
import { ChatModal } from '../../components/modals/chat'
import { chatView, globalUser, openChatBox } from '../../globals'
import { GET_STORE } from '../../graphql/queries'
import { format_date } from '../../utils'
import { Main, AccountCont, Address, Banner, BizInfoCont, BizName, Button, CallToActionCont, CircularBtn, ContactsCont, ContactsItem, Container, CustomerLogo, FollowsCont, ImageCont, Item, ItemsCont, LinkItem, NavLinks, CustomerPriInfo, Bio } from './styles'

interface Props {

}


const Store = ({ }: Props): ReactElement => {

    const location = useLocation();
    const { id } = useParams()

    const { loading, data, refetch, error } = useQuery(GET_STORE, {
        variables: {
            id
        },
        fetchPolicy: "network-only"
    })
    
    if (error) {
        console.log({...error});
    }

    const openChat = ()=>{
        globalUser({
            ...data.store
        })
        openChatBox(true)
        chatView('chat')
    }

    return (
        <Main>
            <NavLink style={{ position: 'absolute', left: 20, top: 20, cursor:'pointer', zIndex: 10 }} to='/' title='Home'>
                <LinkItem active={location.pathname === '/'}>
                    <p>HOME</p>
                </LinkItem>
            </NavLink>
            <Banner>
                <ImageCont>
                    {
                        data?.store.info.banner && <img src={getImageUrl(data?.store.info.banner)} alt="" />
                    }
                </ImageCont>
            </Banner>
            <Container>
                <> {
                    data ?
                        <>
                            <AccountCont>
                                <CustomerLogo>
                                    <img src={getImageUrl(data?.store.info.dp)} alt="" />
                                </CustomerLogo>
                                <CallToActionCont>
                                    <CircularBtn onClick={openChat}>
                                        <Icon>
                                            <Envelop />
                                        </Icon>
                                    </CircularBtn>
                                    {/* <CircularBtn>
                                        <Icon id='bell'>
                                            <NotifIcon />
                                        </Icon>
                                    </CircularBtn> */}
                                    <Button>Follow</Button>
                                </CallToActionCont>
                            </AccountCont>
                            <CustomerPriInfo>
                                <BizInfoCont>
                                    <BizName>
                                        {data.store.info?.name ?? '-'}
                                    </BizName>
                                    <Address>
                                        @{data?.username ?? 'username'}
                                    </Address>
                                </BizInfoCont>
                                <Bio>
                                    {'Add store bio'}
                                </Bio>
                                <ContactsCont>
                                        <ContactsItem>
                                            <Icon>
                                                <Phone />
                                            </Icon>
                                            <p>
                                            {data?.store.phone ?? '-'}
                                            </p>
                                        </ContactsItem>
                                        <ContactsItem>
                                            <Icon>
                                                <Location />
                                            </Icon>
                                            {
                                            <p className='address'>
                                                {`${data.store.addresses[0]?.street ?? 'Unknown St. Name'}`}
                                                {/* <br /> */}
                                                {`${data.store.addresses[0]?.city ?? ''}, ${data.store.addresses[0]?.state ?? ''} State Nigeria`}
                                            </p>
                                            }                                            
                                        </ContactsItem>
                                        <ContactsItem>
                                            <Icon>
                                                <Calender />
                                            </Icon>
                                            <p>Joined {format_date(data?.store.createdAt)}</p>
                                        </ContactsItem>
                                    {/* </Engagements> */}
                                </ContactsCont>
                                <FollowsCont>
                                    <ItemsCont>
                                        <Item>
                                            <p>17K <span>Followers</span></p>
                                        </Item>
                                        <Item>
                                            <p>22.4K <span>Following</span></p>
                                        </Item>
                                        {/* <Item>
                                            <p>0K <span>Likes</span></p>
                                        </Item> */}
                                    </ItemsCont>
                                </FollowsCont>
                            </CustomerPriInfo>
                        </>
                        :
                        loading ?
                        <p>Loading...</p>
                        :
                        null
                }
                </>
                <NavLinks>
                    <Divider bottom={100} />
                    <NavLink to='/account' title='Account'>
                        <LinkItem active={location.pathname === '/store'}>
                            <p>STOCKS</p>
                        </LinkItem>
                    </NavLink>
                    <NavLink to='address' title='Address'>
                        <LinkItem active={location.pathname === '/account/address'}>
                            <p>ADDRESS</p>
                        </LinkItem>
                    </NavLink>
                    <Divider />
                </NavLinks>
                <Outlet />
            </Container>
            <ChatBox />
        </Main>
    )
}

export default Store
