import styled from "styled-components";

export const InvoiceCont = styled.div<any>`
  width: 100%;
  height: 60px;
  display: grid;
  grid-template-columns: 50px auto;
  gap: 0px 10px;
  overflow: visible;
  border-radius: 8px;
  cursor: pointer;
  position: relative;
  margin: auto;
  overflow:visible;
  align-items: center;
  padding: 0px;
  li:nth-child(4) {
    .icon {
      width: 22px;
      height: 22px;
      /* display: none; */
    }
  }
  :hover {
    cursor: pointer;
    background: whitesmoke;
    ul {
      .divider {
        display: none;
      }
    }
    li {
      p:last-child {
        color: #363636;
      }
    }
    li:nth-child(4) {
      .icon {
        display: flex;
      }
      p,.divider {
        display: none;
      }

    }
  }
  background: ${props => props.selected ? 'whitesmoke' : 'white'};
`;
export const ImageCont = styled.div`
  width: 40px;
  height: 40px;
  border: none;
  position: relative;
  border-radius: 6px;
  position: relative;
  z-index: 1;
  background: #e3e3e3;
  img {
    height: 100%;
    width: 100%;
    border-radius: inherit;
    object-fit: cover;
    border: none;
    mix-blend-mode: multiply;
    filter: contrast(1);
    vertical-align: top center;
  }
`;
export const Row = styled.ul`
  width: 100%;
  height: 100%;
  display: grid;
  position: relative;
  margin: 0px;
  padding-left: 0px;
  overflow:visible;
  grid-template-columns:44% 32% 1fr 1fr;
`;
export const Col = styled.li`
  height: 100%;
  width: 100%;
  cursor: pointer;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  overflow:visible;
  p:first-child {
    margin: 0px;
    font-size: 13px;
  }
  p {
    margin: 0px;
    font-size: 12px;
    position: relative;
    width: 98%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #363636;
  }
  p:last-child {
    color: grey;
  }
  &:nth-child(4) {
    align-items: flex-end;
    text-align: right;
    padding-right: 5px;
  }
`;

export const StatusBadge = styled.div`
  max-width: 80px;
  height: 25px;
  border-radius: 12px;
  padding: 0px 3px;
  display:flex;
  align-items: center;
  justify-content: center;
  background: rgba(240, 234, 205, 0.688);
  color: rgb(136, 122, 57);
  border: 1px solid rgba(136, 121, 57, 0.069);
  position:relative;
  .status {
    margin-bottom: 0px;
    font-size: 9px;
    text-align: center;
    width: auto;
     padding: 0px 5px;
     color: rgb(0, 0, 0);
     position: relative;
  }
  .icon {
    width: 7px;
    height: 7px;
    svg {
      stroke: rgb(136, 122, 57);
    }
  }
`
export const StatusOptionsList = styled.ul<any>`
  font-size: 12px;
  position: absolute;
  padding: 0px 0px;
  height: 130px;
  transform: scaleY(${props=>props.show ? 1 : 0}) ;
  transition: all 0.12s ease-in-out;
  right: -10px;
  z-index: 5;
  min-width: 110px;
  background-color: ${props => props.theme.light.colors.backgrounds.base.pri};
  border: 1px solid ${props => props.theme.light.colors.separators.pri};
  border-radius: 10px;
  box-shadow: 0 5px 25px #c4c3c337;
`
export const Option = styled.li<any>`
  height: 30px;
  width: 100%;
  display: flex;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  position: relative;
  p {
    margin-bottom: 0px;
    font-size: 12px;
     padding: 0px 10px;
     color: rgb(0, 0, 0);
  }
  :hover {
    background-color: rgb(247 247 247);
  }
`

export const Footer = styled.footer`
  width: 927px;
  left: 60px;
  display: flex;
  height: 40px;
  align-items: center;
  position: relative;
  justify-content: space-between;
  h6 {
    font-size: 14px;
    margin-bottom: 0px;
    font-weight: 500;
  }
  p {
    margin-bottom: 0px;
    color: grey;
    font-size: 13px;
  }
`
