import React, { SyntheticEvent } from 'react'
import { NavLink } from 'react-router-dom'
import { getImageUrl } from '../../../../apollo'
import { Like, BagPlus, BagMinus } from '../../../../components/icons'
import { Icon } from '../../../../components/icons/styles'
import { formatFigures, formatMoney } from '../../../../utils'
import { Star } from '../../../details/components/icons'
import { Card, CardHeader, Column, ImageCont, Numbers, NumbersCont } from './styles'
import { onlineCart } from '../../../../globals'
// import { CartItem } from '../../../../../models'
import { Link } from 'react-router-dom'
import { useReactiveVar } from '@apollo/client'
import { CartItem } from '../../../../models' 
import Button from '../buttons'

const StoreCard = (props: any) => {
    const { store } = props

    function getRandomNumber() {
        const randomNum = Math.floor(Math.random() * 2000) + 1;
        
        return randomNum;
    }

    return (
        <Column>
            <Card>
                <CardHeader>
                    <ImageCont>
                        {
                            <img src={getImageUrl(store.info.dp || 'd063578d-733d-4aca-8809-e18368d55184')} alt="" />
                        }
                    </ImageCont>
                    <Button/>
                </CardHeader>
                <Link to={`/store/${store?._id}`}>
                    <h6>{store.info.name}</h6>
                </Link>
                
                <p className='username'>{store.phone||'-'}</p>
                <p className='address'>
                    {store.addresses.length > 0 ? store.addresses[0].street : '-'}
                <br/>
                {store.addresses.length > 0 ? store.addresses[0].city : '-'} <span> </span>
                {store.addresses.length > 0 ? store.addresses[0].state : '-'} State
                </p>
                <NumbersCont>
                    <Numbers>
                        <h4>{formatFigures(getRandomNumber())} <span>Views</span></h4>
                    </Numbers>
                    <Numbers>
                        <h4>{formatFigures(getRandomNumber())} <span>Followers</span></h4>
                    </Numbers>
                </NumbersCont>
                <p className='footer'> <span>Active</span> </p>
            </Card>
        </Column>
    )
}

export default StoreCard