import React from 'react'
import { Plus } from '../../../../../components/icons'
import { Divider, Icon } from '../../../../../components/icons/styles'
import { showInventoryModal, tableState } from '../../../../../globals'
import { HeaderCont, HeaderListItems, Item } from './styles'


const TableHeader = () => {
    return (
        <HeaderCont>
            <HeaderListItems>
                <Item>
                    <h6>PRODUCT</h6>
                </Item>
                <Item>
                    <h6>CUSTOMER</h6>
                </Item>
                <Item>
                    <h6 style={ {textAlign: 'center' }}>QUANTITY</h6>
                </Item>
                <Item>
                    <h6>PRICE</h6>
                </Item>
                <Divider />
            </HeaderListItems>
        </HeaderCont>
    )
}

export default TableHeader