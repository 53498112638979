import { useQuery, useReactiveVar } from '@apollo/client'
import React, { ReactElement } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useParams } from 'react-router-dom'
import ChatBox from '../../components/chat'
import { ChatModal } from '../../components/modals/chat'

import { GET_GOODS } from '../../graphql/queries/product.query'
import { dummies, queryOptions } from '../../types/defaults'
import { getLocalStore } from '../../utils'
import EmptyState from '../inventory/components/pagestate/empty'
import ErrorState from '../inventory/components/pagestate/error'
import LoadingState from '../inventory/components/pagestate/loader'
import { ProdCard } from './components'
import Footer from './components/footer'
import SideBar from './components/sidebar'
import { Container, Feed, Main, ProductGroup, ProductGroupCont } from './components/styles'
import { useLocation } from 'react-router-dom'
import { CheckOutOnlineModal } from '../../components/modals/onlineCheckout'
import SideNavigator from '../../components/sidenav'
import MainHeader from './components/headers/main'
import { ProductList } from './styles'

interface Props {

}
const Explore = ({ }: Props): ReactElement => {

    const { query } = useReactiveVar(queryOptions)

    const location = useLocation() as any

    const params = new URLSearchParams(location.search)

    let offset: number = 0

    const { data, loading, error, fetchMore, refetch } = useQuery(GET_GOODS, {
        variables: {
            query,
            category: params.get("cat"),
            subCategory: params.get("subcat"),
            offset
        },
        fetchPolicy: "network-only",
    })
    let records = [];
    if (data) {
        offset = offset + 25;
        records = data.goods?.map((item: any) => {
            return item;
        })
    }

    if (error) console.log({ ...error })

    const fetchMoreData = () => {
        fetchMore({
            variables: {
                query,
                category: params.get("cat"),
                subCategory: params.get("subcat"),
                offset: data.goods?.length,
            }
        })
    }

    return (
        <>
            <MainHeader />
            <Main>
                <Container>
                    {
                        loading ?
                            <LoadingState />
                            :
                            error ?
                                <ErrorState retryCallback={refetch} />

                                :
                                !data.goods?.length ?
                                    <EmptyState
                                        btnLabel='Refresh'
                                        clickCallback={refetch}
                                        message={`No products in this category yet`}
                                        subMessage={`Product will appear here when added`}
                                    />
                                    :
                                    <>
                                        {
                                            <InfiniteScroll
                                                next={fetchMoreData}
                                                hasMore={true}
                                                loader={null}
                                                dataLength={data.goods.length}
                                                style={{ overflow: 'visible' }}
                                            >
                                                {
                                                    <ProductList className='product--list'> {
                                                        records.map((d: any) => (
                                                            <ProdCard key={d.id} item={d} />
                                                        ))
                                                    }
                                                    </ProductList>
                                                }
                                            </InfiniteScroll>
                                        }
                                    </>
                    }
                </Container>
                {/* <ChatBox /> */}
                <CheckOutOnlineModal />
                <SideNavigator />
            </Main>
        </>
    )
}

export default Explore
