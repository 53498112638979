import React, { useEffect, useState } from 'react'
import { Person } from '../../../../../components/chat/contacts/person'
import StaffItem from '../staffItem'

const StaffList = (props: any) => {
    const {staff} = props
    const [curStaff, setCurStaff] = useState<any>(new Object()) 

    const hideInfo = ()=>{
        console.log('Hidding');
        setCurStaff(new Object())
       
    }

    useEffect(() => {
      
        console.log(`cs:${curStaff}`)
    
    }, [curStaff]) 
    

  return (
    <>
          {
              staff.map((s: any) => 
              <StaffItem 
                staff={s}
                key={s._id} 
                selectedStaff={curStaff}
                hideStaffInfoCallback={hideInfo} 
                showStaffInfoCallback={()=>setCurStaff(s)} 
              />
            )
          }
    </>
  )
}

export default StaffList