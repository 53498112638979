import React from 'react'
import { NavLink } from 'react-router-dom'
import { getImageUrl } from '../../../../../apollo'
import { Icon } from '../../../../../components/icons/styles'
import { formatMoney } from '../../../../../utils'
import { Star } from '../../../../details/components/icons'
import { ItemsCont } from '../../../../inventory/components/list/stockgroup/styles'

import { CardCont, Description, ImageCont, PriceCont, RatingsCont, StatsCont } from './styles'
import { BagMinus, BagPlus } from '../../../../../components/icons'
import { AddToCartButton } from '../../../../explore/components/card/styles'
import { useReactiveVar } from '@apollo/client'
import { onlineCart } from '../../../../../globals'
import { CartItem } from '../../../../../models'
import { Link } from 'react-router-dom'


const Card = (props: any) => {
    const { item } = props
    const { stocks } = useReactiveVar(onlineCart)
    const updateCart = (e: any) => {
        e.stopPropagation()
        // if (item.q.val <= 0) return
        const { __typename, ...striped } = item;
        let { stocks } = onlineCart()
        const i = stocks.find((st: CartItem) => st._id === item._id)

        stocks = (!i) ?
            [
                ...stocks, {
                    _id: item._id,
                    quantity: 1,
                    booked: false,
                    delivered: 0,
                    item: striped,
                }
            ]
            :
            stocks.filter((s: any) => s._id !== i._id)

        onlineCart({
            ...onlineCart(),
            stocks
        })

    }
    return (
        // <NavLink className="link" to={`/product/${item._id}`}>
        <CardCont>
                <Link to={`/product/${item._id}`}>
                    <ImageCont>
                        {
                            <img src={getImageUrl(item.mediaUrls[0] || 'd063578d-733d-4aca-8809-e18368d55184')} alt="" />
                        }
                    </ImageCont>
                </Link>
            <Description>
                    <AddToCartButton selected={stocks.some((s: any) => s._id === item._id)} onClick={(e: any) => updateCart(e)} className="cart-btn">
                        <Icon className='bag'>
                            {
                                stocks.some((s: any) => s._id === item._id) ? <BagMinus /> : <BagPlus />
                            }
                        </Icon>
                    </AddToCartButton>
                   
                    <p className="desc">{item.name}</p>
                <PriceCont>
                    <h6>
                        <sub>N</sub>{formatMoney(item.sellingPrice)}
                    </h6>
                </PriceCont>
            </Description>
        </CardCont>
        // </NavLink>
    )
}

export default Card