import React from 'react'
import { getImageUrl } from '../../../../apollo'
import { simplifyExpDate } from '../../../../utils'
import { ExpiredDate, ExpiredMark, ImageCont } from './styles'

function ImageItem(props: any) {
    const { source, expiry, expiryStatus } = props
    const exp = expiry ? simplifyExpDate(expiry) : null
    return (
        <ImageCont>
            {
                (expiryStatus && expiryStatus === 'weak' || expiryStatus === 'expired') ? <ExpiredMark {...props} /> : <></>
            }
            {source?.length ? <img src={getImageUrl(source)} alt="" /> : null}
            {
                exp &&
                <ExpiredDate >
                    <p>{exp}</p>
                </ExpiredDate>
            }
        </ImageCont>
    )
}

export default ImageItem