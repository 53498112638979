import { useReactiveVar } from '@apollo/client'
import React from 'react'
import { Cancel } from '../../../../components/icons'
import { Divider, Icon, VDivider } from '../../../../components/icons/styles'
import { showInvoiceModal } from '../../../../globals'
import { globalInvoice, initInvoice } from '../../../../types/defaults'
import { CartItem, Invoice } from '../../../../types/model'
import { formatMoney, formatTime, format_date, getCartTotal } from '../../../../utils'
import ImageItem from '../../../inventory/components/image'
import Accordion from './accordion'


import { Container, Content, DataInfo, Header, ImageCont, InfoItem, InfoItemCont, InfoItemHeader, InfoSection, ItemInfo, ListItemCont, Total } from './styles'
import { getImageUrl } from '../../../../apollo'

const InvoiceInfo = (props: any) => {

    const invoice:any = useReactiveVar(globalInvoice)

    const close = () => {
        globalInvoice(initInvoice);
        showInvoiceModal(false)
    }

    const getBalaceAmount = () => {
        return +getCartTotal(invoice.stocks) - invoice.recieved
    }
    // const stock = invoice.stocks.find((p: CartItem) => p.item._id === stockId)

    return (

        <Container onClick={(e: any) => e.stopPropagation()} {...props}>
            <Header>
                <h6>Invoice Details</h6>
                <Icon onClick={close}>
                    <Cancel />
                </Icon>
                <Divider />
            </Header>
            <Content>
                <InfoSection>
                    {
                        invoice.stocks.map((stock: CartItem, i: number) => (
                            <ListItemCont className='stock'>
                                <ImageCont> {
                                    stock.item.mediaUrls[0] ?
                                        <img
                                            src={getImageUrl(stock.item.mediaUrls[0])}
                                            alt="product"
                                        />
                                        :
                                        null
                                    }
                                </ImageCont>
                                <ItemInfo>
                                    <DataInfo>
                                        <p>{stock.item.name}</p>
                                        <p>{stock.quantity}</p>
                                    </DataInfo>
                                    <DataInfo>
                                        <p className='desc'>{stock.item.description}</p>
                                        <p>{formatMoney(stock!.item!.sellingPrice * stock!.quantity)}</p>
                                    </DataInfo>
                                    {i + 1 !== invoice.stocks.length ? <Divider /> : null}
                                </ItemInfo>
                            </ListItemCont>
                        ))
                    }
                    <Total>
                        <Divider bottom={100} />
                        <p style={{ margin: 0 }} className='label'>TOTAL:</p>
                        <p className='total'>{formatMoney(+getCartTotal(invoice.stocks))}</p>
                        <Divider />
                    </Total>
                </InfoSection>
                <InfoItemCont>
                    <Accordion defaultState={true} type='invoice' invoice={invoice} />
                    <Accordion type='customer' invoice={invoice} />
                    <Accordion type='timestamp' invoice={invoice} />
                </InfoItemCont>
            </Content>
        </Container>
    )
}

export default InvoiceInfo