import { useLocation, Navigate } from "react-router-dom";

export function Secured({ children }: { children: JSX.Element }) {
    let location = useLocation();
    const store = localStorage

    // console.log(store.getItem('accType'))

    if (!store.getItem('token')) {
        return <Navigate to="/signin" state={{ from: location }} replace />;
    }

    return children;
}