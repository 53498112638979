import React from 'react'
import { Divider } from '../../../../../components/icons/styles'

import { HeaderCont, HeaderListItems, Item } from './styles'

const TableHeader = () => {
    return (
        <HeaderCont>
            <HeaderListItems>
                <Item>
                    <h6 >PRODUCT</h6>
                </Item>
                <Item>
                    <h6 style={{ paddingLeft: '4px' }}>CUSTOMER</h6>
                </Item>
                <Item style={{ textAlign: 'center' }}>
                    <h6>QUANTITY</h6>
                </Item>
                <Item>
                    <h6>PRICE</h6>
                </Item>
                <Divider />
            </HeaderListItems>
        </HeaderCont>
    )
}

export default TableHeader