import { useReactiveVar } from '@apollo/client'
import React, { useState } from 'react'
import { Cancel } from '../../../../../components/icons'
import { Icon } from '../../../../../components/icons/styles'
import { globalUser, showStaffModal } from '../../../../../globals'
import { Account } from '../../../../../models/account'
import StaffForm from '../forms'
import Form from '../forms'
import { HeaderCont } from '../forms/styles'
import Info from '../view'
import { Container, SwitchCont } from './styles'

const StaffViewCont = () => {
    const [view, setView] = useState('info')
    const modal = useReactiveVar(showStaffModal)
    const user: Account = useReactiveVar(globalUser) as any;
    const onSwitchView = (view: string) => {
        setView(view);
    }
  return (
    <StaffForm />
    //   <Container show={modal}>
    //       <SwitchCont>
    //           <HeaderCont>
    //               <h6>New Staff</h6>
    //               <Icon onClick={() => showStaffModal(false)}>
    //                   <Cancel />
    //               </Icon>
    //           </HeaderCont>
    //           <Info staff={user} in={view === 'info' && user?._id} />
    //           <Form staff={user} in={view === 'form' && !user?._id} />
    //       </SwitchCont>
    //   </Container>
  )
}



export default StaffViewCont