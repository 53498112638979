import React, { SyntheticEvent } from 'react'
import { NavLink } from 'react-router-dom'
import { getImageUrl } from '../../../../apollo'
import { Like, BagPlus, BagMinus } from '../../../../components/icons'
import { Icon } from '../../../../components/icons/styles'
import { formatMoney } from '../../../../utils'
import { Star } from '../../../details/components/icons'
import { AddToCartButton, CardCont, Description, ImageCont, LikeCont, PriceCont, Rating, StarsCont } from './styles'
import { onlineCart } from '../../../../globals'
// import { CartItem } from '../../../../../models'
import { Link } from 'react-router-dom'
import { useReactiveVar } from '@apollo/client'
import { CartItem } from '../../../../models'

const Card = (props: any) => {
    const { item } = props

    const { stocks: stockItems } = useReactiveVar(onlineCart)
    const setLocalStorage = ()=>{
        localStorage.setItem('owner', item?.owner)
    }
    
    const updateCart = (e: any) => {
        e.stopPropagation()
        // if (item.q.val <= 0) return
        const { __typename, ...striped } = item;
        let { stocks } = onlineCart()
        const i = stocks.find((st: CartItem) => st._id === item._id)

        stocks = (!i) ?
            [
                ...stocks, {
                    _id: item._id,
                    quantity: 1,
                    booked: false,
                    delivered: 0,
                    item: striped,
                }
            ]
            :
            stocks.filter((s: any) => s._id !== i._id)

        onlineCart({
             ...onlineCart(), 
             stocks 
        })

    }
    return (
        <div onClick={setLocalStorage} className="prod--link">
            <CardCont>
                <Link to={`/product/${item?._id}`}>
                    <ImageCont>
                        {
                            <img src={getImageUrl(item.mediaUrls[0] || 'd063578d-733d-4aca-8809-e18368d55184')} alt="" />
                            // <img src={item.img} alt="" />
                        }
                    </ImageCont>
                </Link>
               
                <Description>
                    <AddToCartButton
                        className="cart-btn"
                        onClick={(e: any) => updateCart(e)} 
                        selected={stockItems.some((s: any) => s._id === item._id)} 
                        >
                        <Icon className='bag'>
                            {
                                stockItems.some((s: any) => s._id ===item._id) ? <BagMinus/>  : <BagPlus/>
                            }
                        </Icon>
                    </AddToCartButton>
                    <p className='desc'>{item.name}</p>
                    {/* <Rating>
                        <StarsCont>
                            <Icon>
                                <Star />
                            </Icon>
                        </StarsCont>
                        <p>
                             <span> 502+ sold</span>
                        </p>
                    </Rating> */}
                    <PriceCont>
                        <h6>
                            {/* <sub>N</sub>{formatMoney(item.sellingPrice)} */}
                            <sub>N</sub>{formatMoney(item.sellingPrice)}
                            {/* <sub>.00</sub> */}
                        </h6>
                        {/* <p>MOQ: <span>200</span></p> */}
                    </PriceCont>
                    
                </Description>
            </CardCont>
         </div>
    )
}

export default Card