import { useQuery, useReactiveVar } from '@apollo/client'
import React from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useLocation } from 'react-router-dom'
import ChatBox from '../../components/chat'
import { LocalCheckOutModal } from '../../components/modals'
import InvoiceModal from '../../components/modals/invoice'
import { GET_SHOWN_INVOICES } from '../../graphql/queries/invoice.query'
import { invCriteria, queryOptions } from '../../types/defaults'

import EmptyState from '../inventory/components/pagestate/empty'
import ErrorState from '../inventory/components/pagestate/error'
import LoadingState from '../inventory/components/pagestate/loader'

import MainHeader from './component/headers/main'

import { ShownInvoiceList } from './component/list/shown'
import { Container, ItemListCont, ListGroupCont, Main, TableCont } from './styles'
import { GET_SHOWN_AGGREGATES } from '../../graphql/queries'
import ShownAggregateCard from '../../components/cards/aggregates/shown'
import TableHeader from './component/headers/tablex'
import SideNavigator from '../../components/sidenav'

const ShownAggregates = (props: any) => {
   
    const location = useLocation();
 
    const queries = useReactiveVar(queryOptions)

    let offset = 0;

    const params = new URLSearchParams(location.search)

    const { data, loading, error, fetchMore, refetch } = useQuery(GET_SHOWN_AGGREGATES, {
        variables: {
            p: params.get("p"),
            q: params.get("q"),
            offset,
            s: queries.query
        },
        fetchPolicy: "network-only",
    })

    if (data) {
        console.log(data)
        offset = offset + 10;
    }

    if (error) console.log({ ...error })

    const fetchMoreData = () => {
        fetchMore({
            variables: {
                p: params.get("p"),
                q: params.get("q"),
                offset: data?.shownAggregates?.length,
                s: ''
            }
        })
    }

    if (data) {
        offset = offset + 10;
    }
    if (error) {
        console.log({...error})
    }

 
    return (
        <>
            <Main>
                <Container>
                    <TableHeader />
                    <ItemListCont>
                    {
                        loading ?
                            <LoadingState />
                            :
                            error ?
                                <ErrorState retryCallback={refetch} />
                                :
                                !data?.shownAggregates?.length ?
                                    <EmptyState
                                        clickCallback={refetch}
                                        message='No sales records so far'
                                        btnLabel='Refresh'
                                        subMessage='Invoices appears here when created'
                                     />
                                    :
                                    <>
                                    <TableCont>
                                        <ListGroupCont>
                                            <InfiniteScroll
                                                dataLength={data?.shownAggregates?.length}
                                                next={fetchMoreData}
                                                hasMore={true}
                                                loader={<></>}
                                                style={{ overflow: 'visible' }}
                                            >
                                            {
                                                <ShownInvoiceList
                                                    groupId={params.get("p")}
                                                    list={data.shownAggregates}
                                                />
                                            }
                                            </InfiniteScroll>
                                        </ListGroupCont>
                                    </TableCont>
                                      <InfiniteScroll
                                                dataLength={data.shownAggregates.length}
                                                next={fetchMoreData}
                                                hasMore={true}
                                                loader={null}
                                                style={{ overflow: 'visible' }}
                                            > {
                                                    <ShownAggregateCard
                                                        groupId={params.get("p")}
                                                        list={data.shownAggregates}
                                                    />
                                                }
                                            </InfiniteScroll>
                                    </>
                }
                    </ItemListCont>

                </Container>
                <InvoiceModal />
                <SideNavigator />
                <LocalCheckOutModal />
            </Main>
        </>
    )

}

export default ShownAggregates