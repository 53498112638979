import React, { useEffect, useState } from 'react'
import { useMutation, useReactiveVar } from '@apollo/client'

// import Address from '../..'
import { Cancel, Drop } from '../../../../../components/icons'
import { Icon } from '../../../../../components/icons/styles'
import { showAccModal } from '../../../../../globals'
import { SAVE_ADDRESS } from '../../../../../graphql/mutations/account'
import { Address } from '../../../../../models/account'
import { stripTypename } from '../../../../../utils'
import Button from '../button'

import { Container, FormGroup, FormGroupCont, FormItem, HeaderCont } from './styles'
import { Input } from '../inputs'
import { ButtonsCont, CancelBtn } from '../button/styles'

const Form = (props: any) => {

    const { addr, closeAddressFormCallback } = props

    // let adds = addresses ?  [...addresses.map((ad: any)=>stripTypename(ad))] as Addr[] : []


    const [focused, setFocused] = useState('')
    const [address, setAddress] = useState<any>({
        street: '',
        city: '',
        state: '',
        country: '',
        zipCode: ''
    })
    const [cancel, setCancel] = useState('')
    const [show, setShow] = useState(true)

    // const show = useReactiveVar(showAccModal)

    // useEffect(() => {
    //     setAddress(addr)
    // }, [addr])


    const [saveMyAddress, { error, loading, data }] = useMutation(SAVE_ADDRESS, {
        update: (cache, { data: { saveAddress } }) => {
            
        }
    })
    if (error) console.log({ ...error });
    // if(data) console.log(data);

    const handleChange = (e: any) => {
        e.persist();
        setAddress({
            ...address,
            [e.target.name]: e.target.value
        })
    }

    const clearInput = (name: string) => {
        setAddress({
            ...address,
            [name]: ''
        })
    }
    const focus = (name: string) => {
        setFocused(name)
    }
    const mouseEnter = () => {
        setCancel('address');
    }
    const mouseLeave = () => {
        setCancel('')
    }

    const handleSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault()
        saveMyAddress({
            variables: {
                address: address._id ? stripTypename(address) : address
            }
        })
    }

    return (
        <Container show={show}>
            <HeaderCont>
                <h6>Address</h6>
                <Icon onClick={() => showAccModal('address')}>
                    <Cancel />
                </Icon>
            </HeaderCont>
            <FormItem onSubmit={(e: React.SyntheticEvent) => handleSubmit(e)}>
                <FormGroupCont>
                    <FormGroup onMouseLeave={() => mouseLeave()} onMouseEnter={() => mouseEnter()}>
                        <Input
                            name='street'
                            label='Street'
                            placeholder='Street name'
                            value={address?.street}
                            focused={focused === 'street'}
                            changeCallback={(e: any) => handleChange(e)}
                            focusedCallback={(name: string) => focus(name)}
                        />
                        {
                            (cancel === 'street') &&
                            <Icon onClick={() => clearInput('street')}>
                                <Cancel />
                            </Icon>
                        }
                    </FormGroup>
                    {/* <FormGroup >
                        <Input
                            name='suit'
                            label='Street address' 
                            placeholder='Millenium suit' 
                        />
                    </FormGroup> */}
                </FormGroupCont>
                <FormGroupCont>
                    <FormGroup top>
                        <Input
                            name='city'
                            label='City'
                            placeholder='your city'
                            value={address.city||''}
                            focused={focused === 'city'}
                            changeCallback={(e: any) => handleChange(e)}
                            focusedCallback={(name: string) => focus(name)}
                        />
                        {
                            <Icon id='drop'>
                                <Drop />
                            </Icon>
                        }
                        {/* <DropDown  options={['Katsina','Bauchi','Taraba', 'Ibadan', 'Imo','Bauchi', 'Lagos', 'Kaduna','Gombe', 'Zamfara']} /> */}
                    </FormGroup>
                    <FormGroup>
                        <Input
                            name='state'
                            label='State'
                            placeholder='your state'
                            value={address.state||''}
                            focused={focused === 'state'}
                            changeCallback={(e: any) => handleChange(e)}
                            focusedCallback={(name: string) => focus(name)}
                        />
                        {
                            <Icon id='drop'>
                                <Drop />
                            </Icon>
                        }
                    </FormGroup>
                </FormGroupCont>
                <FormGroupCont>
                    <FormGroup>
                        <Input
                            name='zipCode'
                            label='Zip code'
                            placeholder='27014'
                            value={address?.zipCode}
                            focused={focused === 'zipCode'}
                            changeCallback={(e: any) => handleChange(e)}
                            focusedCallback={(name: string) => focus(name)}
                        />
                    </FormGroup>
                </FormGroupCont>
                {
                    error && <p className="error">{error?.message}</p>
                }
                <ButtonsCont>
                    <CancelBtn onClick={closeAddressFormCallback}>Cancel</CancelBtn>
                    <Button
                        error={error}
                        data={data} loading={loading}
                        title={address._id ? 'Update Address' : 'Add address'}
                        disabled={!address?.street || !address.state || !address.city}
                    />
                </ButtonsCont>
            </FormItem>
        </Container>
    )
}

export default Form