import React, { useState } from 'react'
import TableHeader from '../tablex'

import { FormCont } from '../../forms/styles'
import { Bag, Chevron, User } from '../../../../../components/icons'
import { Divider, Icon } from '../../../../../components/icons/styles'
import { CartItemsCounter, CatCont, Header, HeaderCont, HeaderMenuCont, IconsGroup } from './styles'
import { useReactiveVar } from '@apollo/client'
import { onlineCart, showNavModal, showOnlineCheckOutModal } from '../../../../../globals'
import CatHeader from '../table'
import { NavLink } from 'react-router-dom'
import NavListMenu from '../../../../../components/topNavMenuList'
import { HamdbugerMenu, MenuCont } from '../../../../../components/topnav/headers/main/styles'



export const GROUP_OPTION = [
    'A-Z',
    'CATEGORY',
    'INSTOCK',
    'PRICE',
    'DATE ADDED',
]
export const FILTER_OPTION = [
    'ALL',
    'EXPIRING',
    'EXPIRED',
    'RUNNING OUT',
    'OUT OF STOCK',
]

const MainHeader = (props: any) => {
    let { stocks } = useReactiveVar(onlineCart)
    const [menu, setMenu] = useState(false)

    const [active, setActive] = useState('')

    const toggleSideNav = () => {
        showNavModal(true)
    }

    return (
        <Header>
            <HeaderCont>
                <FormCont>
                    <div>
                        {/* <NavLink className='home' to='/'>
                            <h2>Stosyst</h2>
                        </NavLink> */}
                        <MenuCont>
                            <HamdbugerMenu onClick={() => toggleSideNav()}>
                                <div className="bar"></div>
                                <div className="bar"></div>
                                <div className="bar"></div>
                            </HamdbugerMenu>
                            <h6>Inventory</h6>
                            <NavLink className='home' to='/'>
                                <h2>Stosyst</h2>
                            </NavLink>
                        </MenuCont>
                    </div>

                    <h6 className='page__title'>Stosyst</h6>

                    <HeaderMenuCont>
                        <Icon onClick={() => showOnlineCheckOutModal(true)} id='bag'>
                            {
                                stocks.length ?
                                    <CartItemsCounter>
                                        {stocks.length}
                                    </CartItemsCounter>
                                    :
                                    null
                            }
                            <Bag />
                        </Icon>
                        <IconsGroup onClick={() => setMenu(!menu)} >
                            <Icon>
                                <User />
                            </Icon>
                            <Icon id='cheveron'>
                                <Chevron />
                            </Icon>
                        </IconsGroup>
                        {menu && <NavListMenu hideMenuCallback={setMenu} />}
                    </HeaderMenuCont>
                </FormCont>
            </HeaderCont>
            <CatCont>
                <CatHeader />
                <Divider />
            </CatCont>
            <TableHeader />
        </Header>
    )
}

export default MainHeader