import { useQuery, useReactiveVar } from '@apollo/client'
import queryString from 'query-string'
import React, { useRef } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useLocation } from 'react-router-dom'
import ChatBox from '../../components/chat'
import { LocalCheckOutModal } from '../../components/modals'
import { ChatModal } from '../../components/modals/chat'
import InvoiceModal from '../../components/modals/invoice'
import { GET_INVOICES } from '../../graphql/queries/invoice.query'
import { invCriteria, queryOptions } from '../../types/defaults'

import EmptyState from '../inventory/components/pagestate/empty'
import ErrorState from '../inventory/components/pagestate/error'
import LoadingState from '../inventory/components/pagestate/loader'

import MainHeader from './component/headers/main'

import { OuterList } from './component/list/outer'
import { Container, ItemListCont, ListGroupCont, Main, TableCont } from './styles'
import { CheckOutOnlineModal } from '../../components/modals/onlineCheckout'
import InvoiceCard from '../../components/cards/invoice'
import SideNavigator from '../../components/sidenav'
import TableHeader from './component/headers/tablex'
import { CardLoader } from '../../components/cards/loaders'

const InvoicePage = (props: any) => {

    const location = useLocation();
    //const componentRef = useRef(null);

    const queries = useReactiveVar(queryOptions)

    // console.log({ ...queries })

    const { search } = useLocation()

    const qs = queryString.parse(search)
    let offset = 0;

    const { data, loading, error, refetch, fetchMore, subscribeToMore } = useQuery(
        GET_INVOICES, {
        variables: {
            ...queries,
            offset,
            group: queries.group || 'day'
        },
        fetchPolicy: "network-only"
    }
    )

    let curInvoices: any[] = [];

    if (data) {
        curInvoices = data?.invoices
        offset = offset + 10;
        // console.log(data)
    }
    if (error) {
        console.log({...error})
    }

    const fetchMoreData = () => {
        fetchMore({
            variables: {
                ...queries,
                offset: data?.invoices?.length,
            }
        })
    }
    return (
        <>
            <Main>
                <Container>
                    <TableHeader />
                    <ItemListCont>
                        {
                            loading ?
                                // <LoadingState />
                                <>
                                    <LoadingState />
                                    <CardLoader />
                                </>
                                :
                                error ?
                                    <ErrorState retryCallback={refetch} />
                                    :
                                    !data?.invoices?.length ?
                                        <EmptyState
                                            clickCallback={refetch}
                                            message='No sales records so far'
                                            btnLabel='Refresh'
                                            subMessage='Invoices appears here when created'
                                        />
                                        :
                                        <>
                                            <TableCont>
                                                <ListGroupCont>
                                                    <InfiniteScroll
                                                        dataLength={data?.invoices?.length}
                                                        next={fetchMoreData}
                                                        hasMore={true}
                                                        loader={<></>}
                                                        style={{ overflow: 'visible' }}
                                                    >
                                                        {
                                                            curInvoices.map((group: any, i: number) => (
                                                                <OuterList
                                                                    {...props}
                                                                    list={group}
                                                                    total={group.total}
                                                                    count={group.count}
                                                                    groupId={group._id}
                                                                    key={group.records[0]._id}
                                                                    nextRecords={curInvoices[i + 1]?.records || []}
                                                                />
                                                            ))
                                                        }
                                                    </InfiniteScroll>
                                                </ListGroupCont>
                                            </TableCont>
                                            <InfiniteScroll
                                                dataLength={data.invoices.length}
                                                next={fetchMoreData}
                                                hasMore={true}
                                                loader={null}
                                                style={{ overflow: 'visible' }}
                                            > {
                                                    curInvoices.map((group: any, i: number) => (
                                                        <InvoiceCard
                                                            {...props}
                                                            groupId={group._id}
                                                            list={group}
                                                            count={group.count}
                                                            total={group.total}
                                                            key={group.records[0]._id}
                                                            nextRecords={curInvoices[i + 1]?.records || []}
                                                        />
                                                    ))
                                                }
                                            </InfiniteScroll>
                                        </>
                        }
                    </ItemListCont>
                </Container>
                <SideNavigator />
                <InvoiceModal />
                <LocalCheckOutModal />
                <CheckOutOnlineModal />

            </Main>
        </>
    )

}

export default InvoicePage