import styled from "styled-components";
export const HeaderMainCont = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
`
export const HeaderCont = styled.div.attrs({
  className: 'container'
})`
  height: 100%;
  max-width: 1000px;
  padding:0px 0px;
  margin-bottom: 10px;
`
export const HeaderListItems = styled.ul`
  width: 100%;
  height: 70px;
  margin: auto;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  background: white;
  padding: 0px;
  h6 {
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 0px;
  }
`;
export const Item = styled.li<any>`
  height: 100%;
  padding: 0px 15px;
  cursor: pointer;
  z-index: 100000;
  display: flex;
  align-items: center;
  position: relative;
  color: #2d2c2c;
  overflow: visible;
   h6 {
      margin-bottom: 0px;
      font-size: 10px;
      position: relative;
      font-weight: 700;
  }
   #cheveron {
        width: 11px;
        height: 11px;
        svg {
          stroke: #2d2c2c;
        }
     }
  &:hover {
    color: #012009;
  }
`;