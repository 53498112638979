import { Divider } from '../../../../../components/icons/styles'
import { invCriteria, queryOptions } from '../../../../../types/defaults'
import { ListCont, ListItem } from './styles'

const Actions = (props: any) => {
    const { options, name, closeCallback } = props
    const groups = ['method', 'customer',  'date']
    const filters = ['','pendings']

    const getFilter = (i: number) => {
        let filter: any
        switch (filters[i]) {
            case '':
                filter  = ''
                break;
            case 'pendings':
                filter = 'pendings'
                break;
        
            default:
                break;
        }
        return filter;
    }

    const getGroup = (i: number) => {
        let group: any
        switch (groups[i]) {
            case 'method':
                group  = 'method'
                break;
            case 'customer':
                group = 'customer'
                break;
            case 'category':
                group = 'category'
                break;
            case 'date':
                group = 'date'
                break;
        
            default:
                break;
        }
        return group;
    }

    const selectOption = (i: number) => {
        name === 'group' ? 
            invCriteria({
            ...invCriteria(),
             group: getGroup(i)
        }) 
        : 
        invCriteria({
             ...invCriteria(), 
             filter: getFilter(i)
        })
        closeCallback()
    }
    return (
        <ListCont l={name==='group' ? -40 : 0} onMouseLeave={closeCallback}>
            {
                options.map((opt: string, i: number) => (
                    <ListItem active={
                        (name === 'group' && invCriteria().group === getGroup(i) || (name === 'filter' && invCriteria().filter === getFilter(i)))
                        } onClick={() => selectOption(i)}>
                        <p>{opt}</p> 
                    </ListItem>
                ))
            }
        </ListCont>
    )
}
export default Actions