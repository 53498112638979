import { useReactiveVar } from '@apollo/client'
import React from 'react'
import { showOnlineCheckOutModal } from '../../globals'
import { CheckOutModalCont } from './styles'
import { CheckOutOnline } from '../checkout/online'

export const CheckOutOnlineModal = () => {

    const show = useReactiveVar(showOnlineCheckOutModal)
    const close = () => {
        showOnlineCheckOutModal(false)
    }

    return (
        <CheckOutModalCont onClick={close} opened={show} >
            <CheckOutOnline />
        </CheckOutModalCont>
    )
}