import { useReactiveVar } from "@apollo/client"
import { globalUser, showChat } from "../../globals"
import ChatBox from "../chat"

export const ChatModal = () => {
    const user: any = useReactiveVar(globalUser)

    return (
        <>
            <ChatBox account={user} />
        </>
    )
}