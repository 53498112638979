import { useMutation, useReactiveVar } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import { More } from '../../../../components/icons'
import { Divider, Icon } from '../../../../components/icons/styles'
import { cart, showInventoryModal, showStockEditModal, tableState } from '../../../../globals'
import { initInvoice } from '../../../../globals/defaults'
import { DELETE_PRODUCT } from '../../../../graphql/mutations'
import { GET_PRODUCTS } from '../../../../graphql/queries'
import { queryOptions, sharedModal } from '../../../../types/defaults'
import { getExpStatus } from '../../../../utils'
import ImageItem from '../image'
import { MoreActions } from '../listMenu'
import { ImageCont, StockCont, PropItem, StockPropsList } from './styles'
import { getImageUrl } from '../../../../apollo'
import { CartItem, Invoice, Product as StockModel } from '../../../../models'

export const Stock = (props: any) => {
  const [showMenu, setShowMenu] = useState(false)
  const { _id, name, description, categories, expiry, q, mediaUrls, sellingPrice } = props.stock
  let { stock } = props;

  const myCart = useReactiveVar(cart)

  // const [invoice, setInvoice] = useState<Invoice>(initInvoice)

  const openMenu = (e: any) => {
    e.stopPropagation();
    setShowMenu(true)
  }

  const queryParams = {
    query: '',
    group: '',
    filter: '',
    offset: 0
  }

  const opendEditor = (e:any, state: string) => {
    e.stopPropagation();
    tableState({
      ...tableState(),
      state,
      item: props.stock,
    })
    showInventoryModal(true)
  }
  const showSharingForm = (e: any) => {
    e.stopPropagation();
    sharedModal(_id)
  }

  const [deleteProduct, { error, loading }] = useMutation(DELETE_PRODUCT, {
    update: (cache, { data: { deleteProduct:deleted } }) => {
      const cached: any = cache.readQuery({
        query: GET_PRODUCTS,
        variables: {
          ...queryOptions()
        }
      })

      let newProducts = cached.products.map((prods: any) => {
        return (prods.records.some((r: any) => r._id === deleted._id)) ?
          ({
            ...prods,
            records: prods.records.filter((p: any) => p._id !== deleted._id)
          })
          :
          prods
      })
      cache.writeQuery({
        query: GET_PRODUCTS,
        variables: {
          ...queryOptions()
        },
        data: {
          products: newProducts.filter((p: any) => p.records.length)
        }
      });
    }
  });

  if (error) console.log({ error })

  const handelDeleteProduct = (id: string, e: any) => {
    e.stopPropagation();
    deleteProduct({
      variables: {
        id
      },
    })
  }

  const closeMenu = () => {
    setShowMenu(false)
  }
  
  const updateCart = (s: StockModel) => {
    if(s.q.val<=0) return
    const { __typename, ...striped} = s;
    let { stocks } = cart()
    const i = stocks.find((item: CartItem) => item._id === s._id)

    stocks = (!i) ?
      [
        ...stocks, {
          _id: stock._id,
          quantity: 1,
          booked: false,
          delivered: 0,
          item: striped,
        }
      ]
      :
      stocks.filter((s: any) => s._id !== i._id)

    //  setInvoice({
    //   ...invoice, 
    //   stocks
    //  })

     cart({...cart(), stocks})
    
    // console.log(cart())
  }
  return (
    <StockCont
      onClick={() => updateCart(stock)}
      onMouseLeave={() => closeMenu()}
      selected={
        myCart.stocks.some((cartItem: CartItem) => cartItem.item._id === stock._id)
      }
    >
      <ImageItem
        expiry={expiry}
        source={mediaUrls[0]}
        expiryStatus={getExpStatus(stock)}
       />
      <StockPropsList>
        <PropItem selected={
          myCart.stocks.some((cartItem: CartItem) => cartItem.item._id === stock._id)
        }>
          <p>{name}</p>
          <p>{description}</p>
        </PropItem>
        <PropItem selected={
          myCart.stocks.some((cartItem: CartItem) => cartItem.item._id === stock._id)
        }>
          <p>{categories?.name||'---'}</p>
          <p>{categories?.category}</p>
        </PropItem>
        <PropItem selected={
          myCart.stocks.some((cartItem: CartItem) => cartItem.item._id === stock._id)
        }>
          <p>{q.val}</p>
        </PropItem>
        <PropItem selected={
          myCart.stocks.some((cartItem: CartItem) => cartItem.item._id === stock._id)
        }> 
          <Icon onClick={(e: any) => openMenu(e)}>
            <More />
          </Icon>
          <p>{sellingPrice}</p>
        </PropItem>
        <Divider />
      </StockPropsList>
      {
        showMenu &&
        <MoreActions
          closeMenuCallback={closeMenu}
          sharingCallback={showSharingForm}
          actions={
            [
              {
                label: 'Edit',
                callback: (e: any) => opendEditor(e,'editing'),
                // permitted: isAdmin() || can('edit', 'stocks')
              },
              {
                label: 'Stock Info',
                callback: (e: any) => opendEditor(e,'info'),
                permitted: true
              },
              {
                label: 'Delete Stock',
                callback: (e: any) => handelDeleteProduct(_id, e),
                // permitted: isAdmin() || can('delete', 'stocks')
              },
            ]
          }
        />
      }
    </StockCont>
  )
}
