import styled from "styled-components";
export const HeaderMainCont = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
`
export const HeaderCont = styled.div.attrs({
  className: 'container'
})`
  height: 100%;
  max-width: 1000px;
  padding:0px 0px;
  margin-bottom: 10px;
`
export const HeaderListItems = styled.ul`
  width: 100%;
  height: 70px;
  margin: auto;
  list-style: none;
  align-items: center;
  justify-content: space-between;
  position: relative;
  background: white;
  padding: 0px;

    display: grid;
    align-items: center;
    grid-template-columns: 25% 50% 25%;

  .add--btn {
    :hover {
      border: 1px solid whitesmoke;
    }
    border-radius: 4px;
    margin-left: 10px;
    height: 30px;
    width: 30px;
  }
  h6 {
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 0px;
  }
  @media(max-width: 768px) {
     display: flex;
    h6, .add--btn {
      display: none;
    }
  }
`;
export const ItemCont = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  border-radius: 6px;
  :hover {
    background: whitesmoke;
  }
  p {
    margin-bottom: 0px;
    font-size: 8px;
    color: grey;
  }
  #itm{
    border-radius: 6px;
    /* border-bottom-right-radius: 0px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px; */
    /* border-right: none; */
  }
  .item:last-child {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
`
export const Item = styled.div<any>`
  height: 100%;
  padding: 0px 10px;
  cursor: pointer;
  z-index: 100000;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: space-between;
  color: #2d2c2c;
  overflow: visible;
  
  border-radius: 6px;
  height: 30px;
  width: 50px;
  :hover {
    border: 1px solid whitesmoke;
  }
   #group {
        width: 14px;
        height: 14px;
        svg {
          stroke: #2d2c2c;
        }
     }
   #cheveron {
        width: 11px;
        height: 11px;
        svg {
          stroke: #2d2c2c;
        }
     }
  &:hover {
    color: #012009;
  }
`;