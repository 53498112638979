import styled from "styled-components"

export const Mask = styled.div`
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: white;
    font-size: 11px;
    position: absolute;
    display: flex;
    border-radius: inherit;
    align-items: center;
    justify-content: center; 
    background-color: rgba(0,0,0,.3);
`
export const DotMark = styled.div<any>`
    top: 0;
    left: 0;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center; 
    background-color: ${({ expiryStatus }) => expiryStatus === 'weak' ? '#ffd108' : 'red'}; 
`
export const Exp = styled(DotMark)`
    top: 73%;
    bottom: 0;
    left: 24%;
    width: 30px;
    height: 10px;
    border-radius: 5px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center; 
    background-color: rgba(0,0,0,.46);
    p {
        font-size: 9px;
        color: white;
        position: relative;
        margin-bottom: 0px;
        font-weight: 700;
    }

`
export const ImageCont = styled.div`
    border: none;
    position:relative;
    border-radius: 10px;
    width: 35px;
    height:35px ;
    overflow:hidden;
    break-inside: avoid;
    @media (max-width: 420px) {
    /* max-height:200px ; */
    }
    img {
        height: 100%;
        width: 100%;
        border-radius: inherit;
        object-fit: cover;
        border: none;
        mix-blend-mode: multiply;
        filter: contrast(1);
        vertical-align: top center;
        @media (max-width: 420px) {
        object-fit: contain;
        }
    }
`