import styled from "styled-components";

export const FooterCont = styled.footer`
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 60px;
  background: #ffffff;
  align-items: center;
  justify-content: space-between;
  padding: 0px 5px;
  display: none;
  @media (max-width: 420px) {
    display: flex;
  }
`

export const LinkItemsCont = styled.ul`
    width: 100%;
    height: 100%;
    padding: 0px 10px;
    position: relative;
    display: flex;
    list-style: none;
    align-items: center;
    justify-content: space-between;
    bottom: -8px;
`
export const LinkItem = styled.li`
    width: 40px;
    height: 100%;
    padding: 0px;
    bottom: 0px;
    display: flex;
    font-size: 9px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .icon{
            width: 22px;
            height: 22px;
    }

`


