import styled from "styled-components";



export const Container = styled.div<any>`
  width: 300px;
  height: 100vh;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  border: 1px solid whitesmoke;
  background: white;
  left: 67%;
  padding: 0px 0px 5px 0px;
  top: 2px;
  z-index: 2030;
  overflow: hidden;
  border-radius: 10px;
  box-shadow: rgba(196, 195, 195, 0.216) 0px 5px 25px;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgba(71, 75, 76, 0.055);
  &::before {
    content: '';
    position: absolute;
    bottom: -4px;
    height: 15px;
    width: 15px;
    border-bottom: 1px solid #c10909;
  }
`;
export const Header = styled.div`
  height: 45px;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  left: 0px;
  top: 0%;
  h6 {
    font-size: 11px;
    margin-bottom: 0px;
    padding: 0px 10px;
    font-weight: bold;
  }
  .icon {
    height: 9px;
    width: 9px;
    margin-right: 10px;
    cursor: pointer;
  }
`;
export const ImageCont = styled.div`
  width: 50px;
  height: 50px;
  border: none;
  position: relative;
  border-radius: 6px;
  position: relative;
  z-index: 1;
  background: #e3e3e3;
  img {
    height: 100%;
    width: 100%;
    border-radius: inherit;
    object-fit: cover;
    border: none;
    mix-blend-mode: multiply;
    filter: contrast(1);
    vertical-align: top center;
  }
`;
export const Content = styled.div`
  width: 100%;
  height: auto;
  /* height: cal(100%-45px); */
  /* overflow: scroll; */
  overflow-x: hidden; 
  `
export const InfoSection = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 0px 10px;
  h6 {
    font-size: 10px;
    margin-bottom: 0px;
    font-weight: 500;
    /* position: absolute; */
    background-color: white;
    /* top: -7px; */
    font-weight: 700;
  }
  `
export const ListItemCont = styled.div`
  width: 100%;
  height: 60px;
  display: grid;
  gap: 0px 10px;
  overflow: visible;
  cursor: pointer;
  position: relative;
  margin: auto;
  align-items: center;
  padding: 0px;
  grid-template-columns: 50px auto;
`;
export const ItemInfo = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  p {
    font-size: 12px;
    margin: 0px;
    max-width: 180px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 0px;
    color: #222222;
  }
`;
export const StoreInfo = styled(ItemInfo)`
  p {
    max-width: 140px;
  }
`;

export const DataInfo = styled.div`                                             
  width: 100%;
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  p {
    font-size: 13px;
    margin: 0px;
  }
  p:last-child {
     font-weight: normal;
     text-align: right;
  }
.desc {
     color: grey;
     font-weight: normal;
  }
`;

export const Total = styled.div`
    height: 40px;
    width: 100%;
    padding: 5px 0px;
    margin-top: 5px;
    position: relative;
    display: flex;
    align-items: center;
    overflow: visible;
    justify-content: space-between;
    .label {
         color: lightgrey;
         padding-left: 0px;
         font-size: 14px;
       }
    .total {
      padding: 0px 0px;
      margin: 0px;
      font-size: 15px;
      font-weight: 500;
      text-align: right;
}
`
export const InfoItemCont = styled.div`
    width: 100%;
    padding: 5px 10px;
    margin: 0px;
    position: relative;   
`
export const InfoItemHeader = styled.header`
    width: 100%;
    height: 35px;
    cursor: pointer;
    margin-bottom: 5px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between; 
    p, h6 {
        margin: 0px;
    }
    h6{
        font-size: 10px;
        margin-top: 0px;
        font-weight: 700;
    }
`
export const InfoItem = styled.div<any>`
    width: 100%;
    padding: 3px 0px;
    margin: 0px;
    height: 37px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 13px;
    p, h6 {
        margin: 0px;
    }
    p {
        color: grey;
    }
    h6{
        font-size: 9px;
        font-weight: 600;
        margin-top: 0px;
    }
    #method {
      font-size: 11px;
      font-weight: 500;
    }
    #status {
      font-size: 11px;
      font-weight: 30;
      width:${props => props.status ? 60 : 50}px;
      border-radius: 6px;
      padding: 0px 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 600;
      background: ${props => props.status ? '#90ee903d' : '#ff44001b'};
      color: ${props => props.status ? '#04dc04e4' : '#ff4400e1'};
    }
`
export const AccordionCont = styled.div<any>`
    width: 100%;
    overflow: hidden;
    height: ${props=>props.opened ? 'auto':'0px'};
    transition: 0.3s ease all;
`
