import { useMutation, useReactiveVar } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import { Cancel, HidePass, ShowPass } from '../../../../../../components/icons'
import { Icon } from '../../../../../../components/icons/styles'
import { showAccModal } from '../../../../../../globals'
import { SAVE_ACC_INFO, SAVE_PHONE_NUMBER } from '../../../../../../graphql/mutations/account'
import { GET_MY_ACCOUNT } from '../../../../../../graphql/queries'
import { Account } from '../../../../../../models/account'
import Button from '../../button'

import { Input } from '../inputs'
import { Container, FormGroup, FormGroupCont, FormItem, HeaderCont } from './styles'

const BioForm = (props: any) => {

    const [focused, setFocused] = useState('')
    const [cancel, setCancel] = useState('')

    const show = useReactiveVar(showAccModal)

    const [info, setInfo] = useState<any>(props.info)

    useEffect(() => {
        setInfo(props.info)
    }, [props.info])


    const [submit, { error, loading, data }] = useMutation(SAVE_ACC_INFO, {
        update: (cache, { data: { saveAccInfo } }) => {
            let myAccount: Account = cache.readQuery({
                query: GET_MY_ACCOUNT
            }) as Account;

            // console.log(myAccount)
            // myAccount = {
            //     ...myAccount,
            //     addresses: [...data.saveAddress]
            // }

            // let { addresses }: any = myAccount

            // addresses = addresses.map((addr: Address) => addr?._id === data.saveAddress._id ? data.saveAddress : addr)

            // cache.writeQuery({
            //     query: GET_MY_ACCOUNT,
            //     data: {
            //         ...myAccount,
            //         addresses: [...saveAddress]
            //     }
            // })
        }
    })

    if (error) console.log({ ...error });
    if (data) console.log(data);

    const handleChange = (e: any) => {
        e.persist();
        setInfo({
            ...info,
            [e.target.name]: e.target.value
        })
    }

    const clearInput = (name: string) => {
        setInfo({
            ...info,
            [name]: ''
        })
    }

    const focus = (name: string) => {
        setFocused(name)
    }
    const mouseEnter = () => {
        setCancel('bio');
    }
    const mouseLeave = () => {
        setCancel('')
    }

    const handleSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault()
        submit({
            variables: {
                info
            }
        })
    }

    return (
        <Container show={show ==='bio'}>
            <HeaderCont>
                <h6>Change Bio</h6>
                <Icon onClick={() => showAccModal('')}>
                    <Cancel />
                </Icon>
            </HeaderCont>
            <FormItem onSubmit={(e: React.SyntheticEvent) => handleSubmit(e)}>
                <FormGroupCont>
                    <FormGroup onMouseLeave={() => mouseLeave()} onMouseEnter={() => mouseEnter()}>
                        <Input
                            value={info?.bio||''}
                            name='bio'
                            focused={focused === 'bio'}
                            label='Account description'
                            placeholder='Add your acccount description'
                            changeCallback={(e: any) => handleChange(e)}
                            focusedCallback={(name: string) => focus(name)}
                        />
                        {
                            (cancel === 'phone') &&
                            <Icon onClick={clearInput}>
                                <Cancel />
                            </Icon>
                        }
                    </FormGroup>
                </FormGroupCont>
                {
                    error && <p className="error">{error?.message}</p>
                }
                <Button
                    error={error}
                    disabled={!info?.bio}
                    data={data} loading={loading}
                    title={info?.bio ? 'Update Bio' : 'Add Bio '}
                />
            </FormItem>
        </Container>
    )
}

export default BioForm