import { useReactiveVar } from '@apollo/client'
import React, { } from 'react'
import { CancelBtn, AddButtonCont, Counter, IconBtn } from './styles'
import { Cancel, Cart, Plus } from '../../../../components/icons'
import { Icon } from '../../../../components/icons/styles'
import { cart, showCheckOutModal, showInventoryModal, tableState } from '../../../../globals'
import { initInvoice } from '../../../../globals/defaults'

const AddStockBtn = (props: any) => {
    const cartList = useReactiveVar(cart)
    const { stocks } = cartList;
    const clear = (e: any) => {
        e.stopPropagation()
        cart({ ...initInvoice })
    }
    const showEditForm = () => {
        tableState({
            item: null,
            state: 'editing'
        })
        showInventoryModal(true)
    }
    return (
        <AddButtonCont
            show={!stocks?.length}
            onClick={() => showEditForm()}>
            {
                stocks?.length ?
                    <Counter>
                        {stocks?.length}
                    </Counter>
                    :
                    null
            }
            <Icon>
                <Plus />
            </Icon>
            {/* <Icon>
                <Cart />
            </Icon> */}
            {/* <CancelBtn onClick={(e: any) => clear(e)}>
                <Icon>
                    <Cancel />
                </Icon>
            </CancelBtn> */}
        </AddButtonCont>
    )
}

export default AddStockBtn