import { useMutation, useReactiveVar } from '@apollo/client'
import { stripTypename } from '@apollo/client/utilities'
import React, { useEffect, useState } from 'react'
import { Container, FormItem, InfoItemHeader, InfoItem, InfoSection, Modal, PaymentOptionCont, ListCont, ListItem } from './styles'
import { Divider, Icon } from '../../icons/styles'
import { Chevron, Plus } from '../../icons'
import { CHECKOUT_ONLINE } from '../../../graphql/mutations'
import { GET_PRODUCTS } from '../../../graphql/queries'
import { queryOptions } from '../../../types/defaults'
import { formatMoney, genTransId, getCartTotal, getLocalStore } from '../../../utils'
import Form from './form/shipping'
import Button, { SubmitButton } from './form/button'
import { Account, Address, Order, initOrder } from '../../../models'
import { onlineCart } from '../../../globals'

const Payment = (props: any) => {
    const { total, openAddressCallback, openAddressForm, account:acc } = props
    const myCart: any = useReactiveVar(onlineCart);
    const [order, setOrder] = useState<Order>(initOrder);
    const [menu, setMenu] = useState(false);

    // const [account, setAccount] = useState<any>({
    // });


    useEffect(() => {
        setOrder(myCart)
    }, [myCart])
    
    useEffect(() => {
        setOrder({
            ...myCart,
            buyer: acc._id,
            shipping: {
                ...myCart.shipping,
                contact: {
                    ...myCart.shipping.contact,
                    // address: {
                    //     ...myCart.shipping.contact.address,
                    //     street:acc?.addresses[0]?.street,
                    //     city: acc?.addresses[0]?.city,
                    //     state: acc?.addresses[0]?.state
                    // }
                }
            }
        })
        // console.log(`account ${JSON.stringify(acc, null, 2)}`)
    }, [acc, myCart])
  
    let newOrder: any = {}

    const [checkOut, { error, loading, data }] = useMutation(CHECKOUT_ONLINE, {
        // update: (cache, { data: { checkOut } }) => {
        //     if (!props.order._id) {
        //         let result: any = cache.readQuery({
        //             query: GET_PRODUCTS,
        //             variables: {
        //                 ...queryOptions()
        //             }
        //         })
        //         checkOut.stocks.forEach(((stock: any) => {
        //             result = {
        //                 ...result,
        //                 products: result.products.map((p: any) => ({
        //                     ...p,
        //                     records: p.records.map((record: any) => (record._id === stock.item._id) ?
        //                         ({
        //                             ...record,
        //                             q: {
        //                                 ...record.q,
        //                                 val: record.q.val - stock.quantity
        //                             }
        //                         })
        //                         :
        //                         record
        //                     )
        //                 }))
        //             }
        //         }))
        //         cache.writeQuery({
        //             query: GET_PRODUCTS,
        //             data: {
        //                 products: result.products
        //             }
        //         });
        //     }
        //     // updatePrintIvoice(newInvoice)
        //     // showFeedBack()
        // }
    });

    if (error) console.log({ ...error })

    const handleSubmit = (e: any) => {
        e.preventDefault();
        e.stopPropagation(); 
        if (loading) {
            return
        }
        let { stocks } = order
        stocks = stocks.map((stock: any) => {
            const {
                _id,
                item, 
                item: { 
                    added, q, size, categories, modified 
                }, 
                ...rest 
            } = stock
            return {
                ...stripTypename(rest),
                item: {
                    ...stripTypename(item),
                    q: stripTypename(q),
                    size: stripTypename(size),
                    added: stripTypename(added),
                    modified: stripTypename(modified),
                    categories: stripTypename(categories),
                }
            }
        })

        // const { seenBy, ...rest } = order
        // const { __typename, ...addr } = account.addresses[0] as any
        
        // newOrder = {
        //     ...order,
        //     stocks,
        //     // added: acc?._id,
        //     // card: account.cards[0],
        //     // address:,
        //     tid: genTransId(),
        // }

        // if (order._id) {
        //     const { __typename, added, modified, ...i } = order
        //     newOrder = {
        //         ...i,
        //         stocks: [...stocks],
        //     }
        // }


        console.log({
            ...order,
            stocks
        });
        
        checkOut({
            variables: {
                order: {
                    ...order,
                    stocks
                    // address: acc?.addresses[0] ? acc?.addresses[0] :  {}
                }
            },
        })
    }

    const select = (e: any, via: string) => {
        e.stopPropagation();
        setOrder({
            ...order,
            payment: {
               ...order.payment,
               method: {
                ...order.payment.method,
                via
               }
            }
        });
        setMenu(false)
    }

    return (
        <Container {...props}>
            <FormItem onSubmit={(e: React.SyntheticEvent) => handleSubmit(e)}>
                <InfoSection>
                    <InfoItemHeader className='section-header'>
                        <h6>Payment method</h6>
                        <PaymentOptionCont>
                            <p onClick={() => setMenu(true)}>{order.payment.method.via}</p>
                            <Icon>
                                <Chevron />
                            </Icon> {
                                menu && 
                                <ListCont>
                                    <ListItem onClick={(e: any) => select(e, 'Cash')}>
                                        <p>Cash</p>
                                    </ListItem>
                                        <ListItem onClick={(e: any) => select(e, 'Transfer')}>
                                        <p>Transfer</p>
                                    </ListItem>
                                        <ListItem onClick={(e: any) => select(e, 'Card')}>
                                        <p>Card</p>
                                    </ListItem>
                                </ListCont>
                            }
                        </PaymentOptionCont>
                    </InfoItemHeader>
                    <InfoItem>
                        <h6>Card</h6>
                        <p>Visa**********280</p>
                    </InfoItem>
                    <Divider />
                </InfoSection>
                <InfoSection>
                    <InfoItemHeader>
                        <h6>Delivery</h6>
                        <Icon onClick={openAddressCallback}>
                            <Plus />
                        </Icon>
                    </InfoItemHeader>
                    <InfoItem>
                        <h6>Contact</h6> 
                        <p>{order?.shipping.contact.address.street}, {order?.shipping.contact.address.city}</p>
                        <p>{order?.shipping.contact.address.state} State, Nigeria</p>
                        <p>{order?.shipping.contact.phone}</p>
                    </InfoItem>
                    {openAddressForm && <Form {...props} />}
                    <Divider />
                </InfoSection>
                <InfoSection>
                    <InfoItemHeader>
                        <h6>Summary</h6>
                    </InfoItemHeader>
                    <InfoItem>
                        <p>Subtotal <span>2,470</span></p>
                        <p className='subinfo'>Discount <span>0.00</span></p>
                        <p className='subinfo'>Delivery <span>{order.shipping.fee.toFixed(2)}</span></p>
                    </InfoItem>
                    <Divider />
                </InfoSection>
                <InfoSection>
                    <InfoItem>
                        <p>
                            Total
                            <span className='total'>
                                <span>N</span>
                                {formatMoney(total)}
                            </span>
                        </p>
                    </InfoItem>
                </InfoSection>
                <SubmitButton
                    data={data}
                    loading={loading}
                    onClick={(e: Event) => handleSubmit(e)}
                    title={`${props.invoice?._id ? 'Update invoice' : 'Confirm payment'}`} />
            </FormItem>
        </Container>
    )
}
export default Payment