
import React, { SyntheticEvent, useState } from 'react'
// import { roundAmount } from '../../charts/header'

import { Ellipsis, Chevron } from '../../icons'
import { Icon, Divider } from '../../icons/styles'
import { ImageItem } from './image'
import { 
    Card, Desc, 
    DescCol, DescCont, 
    Footer, Header, 
} from './styles'
import { roundAmount } from '../../../utils'

import StockListItem from './stockListItem'
import Skeleton from '../../loaders/skeletons'

type Props = {}

function StocksCard(props: any) {

    const { list: items, count, total, groupId } = props;
    
    return (
        <Card>
            <Header>
               {groupId}
                <Divider />
            </Header>
            
            {
                items.map((item: any, index: number) => (
                    <StockListItem index={index} stock={item} count={items.length} key={item._id} />
                ))
            }
            <Footer>
                <Divider bottom={100} />
                <p>+{count - 5 <= 0 ? 0 : count - 5}</p>
                <Icon rot={90} size={6}>
                    <Chevron />
                </Icon>
                <h6>{roundAmount(total)}</h6>
            </Footer>
        </Card>
    )
}

export default StocksCard