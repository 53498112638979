import { useReactiveVar } from "@apollo/client";
import { ReactElement, useState } from "react";
import { Divider } from "../../../../components/icons/styles";
import { globalInvoice, initInvoice, invCriteria, queryOptions } from "../../../../types/defaults";
import { Invoice as InvoiceModel } from "../../../../types/model";
import { formatFigures, format_date, getNetInvoiceToatal, roundAmount, sortByPaid } from "../../../../utils";
import { Header } from "../../styles";

import { ElasticContainer } from "../containers/elastic";
import InvoiceInfo from "../details";
import { Invoice } from "../invoice";
import { Footer } from "../invoice/styles";
import { InnerList } from "./inner";
import { ItemsCont } from "./styles";
import { Link } from "react-router-dom";

export function OuterList(props: any): ReactElement {
    const { list:{records}, count, total, groupId } = props;
    const { group } = useReactiveVar(queryOptions)

   
    const generateLink = () => {
        return `/sales/aggregates/view?p=${group}&q=${encodeURIComponent(groupId)}`;
    }

    console.log(`ID ${groupId}`) 

    return (
        <section className="stocksContainer"> {
                <>
                    <Header>
                    <h6>{format_date(groupId?.getTime())}</h6>
                        {
                            count - 5 > 0 &&
                            <h6>
                                <Link to={generateLink()}>SHOW ALL</Link>
                            </h6>
                        }
                        <Divider />
                    </Header>
                    {
                        records.map((record: any, j: number) =>
                            <ElasticContainer key={record._id} contentHeight={55} initHeight={55}> 
                                    <ItemsCont>
                                        <Invoice 
                                            // stock={record.stocks[0]} 
                                            invoice={record} 
                                        /> 
                                    </ItemsCont>
                            </ElasticContainer>
                        )
                    }
                    <Footer>
                        <p>+{
                        count - 5 <= 0 ? 0 : count - 5
                        }</p> 
                        <h6> 
                            {
                                formatFigures(total)
                            }
                            {/* {roundAmount(getNetInvoiceToatal(list.records))} */}
                        </h6>
                    </Footer>
                </>
            }
        </section>
    )
}