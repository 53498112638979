
import React, { SyntheticEvent, useState } from 'react'
// import { roundAmount } from '../../charts/header'

import { Ellipsis, Chevron } from '../../icons'
import { Icon, Divider } from '../../icons/styles'
import { ImageItem } from './image'
import { 
    Card, Desc, 
    DescCol, DescCont, 
    Footer, Header, 
} from './styles'
import { format_date, roundAmount } from '../../../utils'

import ExpenseListItem from './expenseListItem'
import { queryOptions } from '../../../types/defaults'
import { useReactiveVar } from '@apollo/client'

type Props = {}

function ExpenseCard(props: any) {

    const { list: items, count, total, groupId } = props;
    const { group } = useReactiveVar(queryOptions)
    
    return (
        <Card>
            <Header>
                {(group === 'date' || group === '') ? format_date(groupId) : groupId || 'OTHERS'}
                <Divider />
            </Header> {
                items.map((item: any, index: number) => (
                    <ExpenseListItem index={index} item={item} count={items.length} key={item._id} />
                ))
            }
            <Footer>
                <Divider bottom={100} />
                <p>+{count - 5 <= 0 ? 0 : count - 5}</p>
                <Icon rot={90} size={6}>
                    <Chevron />
                </Icon>
                <h6>{roundAmount(total)}</h6>
            </Footer>
        </Card>
    )
}

export default ExpenseCard