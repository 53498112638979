import styled from "styled-components";
export const Container = styled.div<any>`
  height: 100%;
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  padding: 0px 10px;
  padding-bottom: 10px;
  overflow: hidden;
  transition: all 0.15s ease-in-out;
  left: ${(props) => (props.in ? 0 : -100)}%;
`;
export const Card = styled.div<any>`
  position: relative;
  height:100%;
  z-index: 300000000000000;
  width: 320px;
  border-radius: 10px;
  display: grid;
  grid-template-rows: 40px 1fr auto;
  overflow: hidden;
  background-color: ${props => props.theme.mode === 'dark' ? props.theme.dark.colors.backgrounds.base.pri : props.theme.light.colors.backgrounds.base.pri
    };
  transition: all .4s ease-in-out;
  right: ${props => props.slideIn ? -70 : -300}%;
`;
export const Header = styled.header`
  height: 40px;
  width: 100%;
  background: white;
  position: relative;
  display: grid;
  grid-template-columns:25px 1fr; 
  align-items: center;
  padding: 0px 10px;
  h4 {
    font-size: 14px;
    width:100%;
    text-align: center;
    font-weight: 600;
    margin-bottom: 0px;
  }
   .icon {
    width: 20px;
    height: 20px;
    cursor: pointer;
    svg {
        stroke:#343534;
    }
  }
`

export const BodyCont = styled.div<any>`
    height:100%;
    width: 100%;
    position: relative;
    overflow-x: hidden;
`;
export const Footer = styled(Header)`
  padding: 0px 10px;
  display: grid;
  grid-template-columns: 1fr 25px;
  align-items: center;
  p {
    margin-bottom: 0px;
    width: 100%;
    font-size: 14px;
    text-align: right;
    font-weight: bold;
    position: relative;
    cursor: pointer;
    &:hover {
      color: #25ec78;
    }
  }
  .icon {
    width: 14px;
    height: 14px;
    svg {
        stroke:#333433;
    }
  }
`

export const InfoItemHeader = styled.header`
  width: 100%;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  h6 {
    text-align: left;
    margin-bottom: 10px;
    font-size: 12px;
    margin-top: 0px;
    font-weight: 600;
    color: rgb(0, 0, 0);
    display: flex;
  }
  .icon {
    position: relative;
      bottom: 5px;
      width: 10px;
      height: 10px;
  }
`;
export const FormItem = styled.form`
  width: 100%;
  height: 94vh;
  padding-bottom: 30px;
  position: relative;
`;
export const InfoSection = styled.div`
  width: 100%;
  padding: 10px 0px;
  margin: 0px;
  position: relative;
`;
export const Body = styled.div<any>`
  height:100%;
  width: 100%;
  overflow: hidden;
  overflow-x: hidden;
  position: absolute;
  transition: all .15s ease-in-out;
  left: ${props => props.in ? 100 : 0}%;
  background-color: #ffffff;
 `;

export const Content = styled.div<any>`
    width:100%;
    max-height: 80vh;
    position: relative;
    overflow-x: hidden;
`
export const Total = styled.div`
    height: 40px;
    width: 100%;
    padding: 5px 16px;
    margin: auto;
    position: relative;
    display: flex;
    align-items: center;
    overflow: visible;
    justify-content: space-between;
    span {
        position: absolute;
        right: 0px;
    }
     p {
       &:first-child {
         color: lightgrey;
         padding-left: 0px;
       }
       &:last-child {
           padding-left: 0px;
           font-size: 16px;
       }
       padding: 0px 0px;
       margin-bottom: 0px;
       font-size: 16px;
       font-weight: 500;
       text-align: right;
     }
  `
  export const PaymentOptionCont = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    bottom: 5px;
    p {
      margin-bottom: 0px;
      font-size: 12px;
      text-align: right;
    }
    .icon {
      bottom: 0px;
      width: 12px;
      height: 12px;
    svg {
        stroke:#333433;
    }
  }

  `
export const ListCont = styled.ul<any>`
  top: 5px;
  width: 100px;
  margin: auto;
  right: 0%;
  padding-left: 0px;
  border-radius: 6px;
  z-index: 41000;
  position: absolute;
  background-color: white;
  overflow-y: auto;
  li:first-child {
    border-radius: 6px 6px 0px 0px;
  }
  li:last-child {
    border-radius: 0px 0px 6px 6px;
  }
  ::-webkit-scrollbar {
    width: 0 !important;
  }
  border: 1px solid rgba(71, 75, 76, 0.055);
  box-shadow: rgba(0, 0, 0, 0.05) 0 0 0 1px, rgba(0, 0, 0, 0.12) 0 15px 30px 0px;
`;
export const ListItem = styled.li<any>`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  position: relative;
  height: 30px;
  width: 100%;
  cursor: pointer;
  background-color: white;
  :hover {
    background: whitesmoke;
    p {
      color: black;
    }
  }
  p {
    width: 100%;
    margin-bottom: 0px;
    font-size: 12px;
    padding-left: 10px;
    color: ${(props) => (props.active ? "#25ec78" : "#707070")};
    text-align: left;
    font-weight: 500;
  }
`;

export const InfoItem = styled.div`
  width: 100%;
  padding: 0px 0px;
  margin: 0px;
  position: relative;
  display: flex;
  align-items: start;
  flex-direction: column;
  justify-content: space-between;
  font-size: 13px;
  p {
    margin: 0px;
    width:  100%;
    position: relative;
    font-weight: 500;
    color: rgba(60, 60, 67, 0.6);
    span {
      position: absolute;
      right: 0;
      text-align: right;
      span {
        position:relative;
        color: rgba(60, 60, 67, 0.3);
      }
    }
  }
  .subinfo {
    color: rgba(60, 60, 67, 0.3);
    font-size: 11px;
    font-weight: normal;
  }
  .total {
    color: #000000;
    font-size: 14px;
    font-weight: 600;
  }
  h6 {
    margin: 0px;
    font-size: 12px;
    font-weight: normal;
    text-align: left;
    margin-top: 0px;
    color: rgba(60, 60, 67, 0.3);
  }
`;

export const ListItemsCont = styled.div<any>`
    left:0%;
    width:100%;
    overflow: hidden;
    position: absolute;
    overflow-x: hidden;
    max-height: cal(100%-50px);
    transition: all .2s ease-in;
`
export const Modal = styled.div<any>`
  position: absolute;
  height: 100vh;
  width: 100vw;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: rgb(0 0 0 / 24%);
  display: ${(props) => (props.opened ? "block" : "none")};
`;