import { useState } from "react";
import { InputCont, TextInput } from "./styles";

export const Input = (props: any) => {
    const { name, value, type, required, label, focusedCallback, changeCallback } = props;
    return (
        <InputCont hasInput={value}  {...props} >
            <label>
                {label}
                {required && <span>*</span>}
            </label>
            
            <TextInput 
                {...props}
                onChange={changeCallback}
                onBlur={() => focusedCallback('')} 
                onFocus={() => focusedCallback(name)}
            /> 
        </InputCont>
    )
}