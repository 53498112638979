import React from 'react'
import Form from './components/forms'
import { Container, Copy, FormSection, PromoSection } from './styles'

const SignUp = () => {
  return (
      <Container>
      <PromoSection>
        <Copy>
          <h6>Shop <br></br>
            Sale. Excel<br></br>

          </h6>
          <p style={{ paddingLeft: 80 }}>
            Access and track your stocks and cashflow records<br></br>
            with few clicks anytime, anywhere
          </p>
        </Copy>
      </PromoSection>
        <FormSection>
            <Form />
        </FormSection>
      </Container>
  )
}

export default SignUp