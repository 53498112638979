import styled from "styled-components"

export const Cont = styled.header`   
   .card {
        padding: 0px 0px;
        overflow: hidden;
        height: 360px;
        width: 100%;
        border-radius: 12px;
        margin-bottom: 15px;
        border-color: #eaeaea;
    }
`
export const Header = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px;
    height: 40px;
    background-color: #ffffff;
    h6 {
        margin-bottom: 0px;
        font-size: 14px;
    }
`
export const Body = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding: 0px 14px 10px 14px;
  h4  {
    font-weight: bold;
    margin: 0px;
    font-size: 1.25rem;
  }
`
export const BarChartCont = styled.div<any>`
    height: 200px;
    width: 100%;
    bottom:0px;
    border-radius: 12px;
    position: relative;
  `
  export const LoadingCont = styled.div<any>`
   height: 100%;
   width: 100%;
   top: 0;

   display: flex;
   position: absolute;
   flex-direction: row;
   align-items: center;
   justify-content: center;
   align-self: center ;
   padding-bottom: 80px;
   p {
    color: grey;
   }
`