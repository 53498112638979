import styled from "styled-components";

export const HeaderCont = styled.header`
    height: 40px;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
     padding: 0px 10px;
    justify-content: space-between;
     h6 {
        font-size: 14px;
        margin-bottom: 0px;
        font-weight: 700;
    }
    .icon {
        height: 10px;
        width: 10px;
        cursor: pointer;
    }
`

export const DetailsCont = styled.div<any>`
    width: 300px;
    height: 100vh;
    z-index: 2700000000;
    display: grid;
    background: grey;
    gap: 0px 10px;
    right: 0%;
    border-radius: 10px;
    background: white;
    position: absolute;
    overflow: hidden;
    grid-template-rows: 40px 240px 1fr;
    background-color: ${props => props.theme.mode === 'dark' ? props.theme.dark.colors.backgrounds.elavations.pri : props.theme.light.colors.backgrounds.base.pri
    };
    .formGroup {
        margin-right: 10px;
    }
`;
export const InfoColumnCont = styled.div`
    width: 100%;
    height: 100vh;
    position: relative;
    display: flex;
    overflow-y: scroll;
    padding: 10px 10px 30px 10px;
    flex-direction: column;
`;
export const InfoColumn = styled.div`
    width: 100%;
    position: relative;
    margin-bottom: 10px;
    .icolunm:last-child {
        margin-bottom: 0px;
    }
    &:nth-child(1){
        padding: 0px;
    }
    h6 {
      margin-bottom: 4px 0px;
      font-size: 10px;
      position: relative;
      font-weight: 700;
    }
    
`;
export const ImageCont = styled.div`
    border: none;
    position:relative;
    border-radius: 10px;
    width: 280px;
    height:280px; ;
    overflow:hidden;
    break-inside: avoid;
    background: whitesmoke;
    @media (max-width: 420px) {
    max-height:200px ;
    }
    img {
        height: 100%;
        width: 100%;
        border-radius: inherit;
        object-fit: cover;
        border: none;
        mix-blend-mode: multiply;
        filter: contrast(1);
        vertical-align: top center;
        @media (max-width: 420px) {
        object-fit: contain;
        }
    }
`

export const InfoItems = styled.ul<any>`
    width: 100%;
    height: 100%;
    margin-right: 10px;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items:flex-start;
    margin-bottom: 0px;
     padding-left:0px;
    /* padding: 0px ${props => props.p || 0}px 0px 0px; */
    justify-content:${props => props.isInput ? 'space-between' : 'flex-start'};
`
export const Info = styled.div`
    width: 100%;
    height: 40px;
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content:center;
    h6 {
        font-size: 9px;
        margin-bottom: 0px;
        color: #8080807a;
    }
    p {
        font-size:13px;
        max-width: 95%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 0px;
        span {
            color: grey;
        }
    }
`