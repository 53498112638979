import React from 'react'
import { TracksCont, TrackItem, Item, DataItem, Body, Cont, Header, LoadingCont } from './styles'
import { Card } from 'react-bootstrap'

import { useLazyQuery, useQuery, useReactiveVar } from '@apollo/client';
import { GET_CHART_HEADER } from '../../../../graphql/queries';
import { chartDuration } from '../../../../globals';
import { SpinLoader } from '../../../loaders';
import { roundAmount, formatMoney } from '../../../../utils';
import { Divider } from '../../../icons/styles';

export const Tracks = () => {

    const { loading, data, error } = useQuery(GET_CHART_HEADER, {
        fetchPolicy: "network-only"
    })
    if (data) console.log(data)

    if (error) console.log({ error })



    return (
        <Cont>
            <Card>
                <Header>
                    <h6>Inventory status</h6>
                </Header>
                <Body>
                    {
                        data ?
                            <>
                                <TracksCont>
                                    <TrackItem>
                                        <Item>
                                            <h6>Categories</h6>
                                            <p>Products categories </p>
                                        </Item>
                                        <DataItem>
                                            <h6>{formatMoney(data.chartHeader.categories)}</h6>
                                        </DataItem>
                                        <Divider />
                                    </TrackItem>
                                    <TrackItem>
                                        <Item>
                                            <h6>Unique stocks</h6>
                                            <p>Total unique product in store</p>
                                        </Item>
                                        <DataItem>
                                            <h6>{formatMoney(data.chartHeader.uniqueStocks)}</h6>
                                        </DataItem>
                                        <Divider />
                                    </TrackItem>
                                    <TrackItem>
                                        <Item>
                                        <h6>Total stocks</h6>
                                        <p>Overall stock count in store</p>
                                        </Item>
                                        <DataItem>
                                            <h6>{formatMoney(data?.chartHeader.stocksCount)}</h6>
                                        </DataItem>
                                        <Divider />
                                    </TrackItem>
                                    
                                    
                                    <TrackItem>
                                        <Item>
                                            <h6>Net worth</h6>
                                            <p>Total value of inventory</p>
                                        </Item>
                                        <DataItem>
                                            <h6>{roundAmount(data.chartHeader.totalAmount)}</h6>
                                        </DataItem>
                                        <Divider />
                                    </TrackItem>
                                   
                                    
                                </TracksCont>
                            </>
                            :
                            <LoadingCont>
                                <SpinLoader size={'40px'} />
                            </LoadingCont>
                    }
                </Body>
            </Card>
        </Cont>
    )
}
