import styled from "styled-components";
export const SettingsCont = styled.div`
  width: 100%;
  min-height: 100px;
   h5 {
    width: 100%;
    margin-bottom: 0px;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 10px;
  }
  p {
    margin-bottom: 0px;
  }
  position: relative;
  width: min(100% - 40px);
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0px;
    width: 100%;
  }
`
export const SectionCont = styled.div`
    padding-top: 30px;
    width: 100%;
    position: relative;
    min-height: 100px;
`
export const SettingGroup = styled.div`
  width: 100%;
  min-height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: space-between;
  p{
    font-size: 14px;
    color: #1ed760;
  }
`
export const SettingItem = styled.div`
  width: 100%;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  p{
    font-size: 14px;
    color: #1ed760;
    margin-bottom: 0px;
    cursor: pointer;
  }
  .add__btn {
    font-size: 22px;
    font-weight: normal;
  }
  .icon {
    width:18px;
    height: 18px;
  }
`
export const Setting = styled.div<any>`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: space-between;
  flex-direction: column;
  position: relative;
  margin-bottom: 5px;
  p{
    font-size: 14px;
    width: 100%;
    margin-bottom: 0px;
    color: #222121;
  }
  p:last-child {
    color: ${props => props.active ? 'rgb(83,100,113)' : '#cecfcf'} ;
    position: relative;
  }
  &:first-child {
    margin-bottom: 10px;
  }
`
