
import React, { SyntheticEvent, useState } from 'react'
// import { roundAmount } from '../../charts/header'

import { Ellipsis, Chevron } from '../../icons'
import { Icon, Divider } from '../../icons/styles'
// import { ImageItem } from './image'
// import {
//     Card, Desc,
//     DescCol, DescCont,
//     Footer, Header,
// } from './styles'
import { roundAmount } from '../../../utils'

// import StockListItem from './stockListItem'
import Skeleton from '../../loaders/skeletons'
import { Card, Header, Footer } from '../stock/styles'
import { SkelInfo,Flash } from '../../loaders/skeletons/styles'

type Props = {}
const counts = [0,0,0,0,0]

function StocksLoader(props: any) {
    const {items} = props

    return (
        <Card>
            <Header>
                <SkelInfo w={40} mb={4}>
                    <Flash />
                </SkelInfo>
            </Header>
            {/* <Skeleton /> */}
            {
                items?.map((item: any, index: number) => (
                    <Skeleton key={Math.random()} />
                    // <StockListItem index={index} stock={item} count={items.length} key={item._id} />
                ))
            }
            <Footer>
                <SkelInfo w={20} mb={4}>
                    <Flash />
                </SkelInfo>
                {/* <Divider bottom={100} />
                <p>+{count - 5 <= 0 ? 0 : count - 5}</p>
                <Icon rot={90} size={6}>
                    <Chevron />
                </Icon>
                <h6>{roundAmount(total)}</h6> */}
            </Footer>
        </Card>
    )
}

export default StocksLoader




export const CardLoader = () => {
    const  getRandomArray = () => {
        // Generate a random length between 1 and 10
        const length = Math.floor(Math.random() * 10) + 1;

        // Create an array with random integers between 0 and 100
        const randomArray = Array.from({ length }, () => Math.floor(Math.random() * 101));

        return randomArray;
    }
  return (
      <>
          {
              [0,0,0,0,0,0,0,0].map((item, index) => (
                  <StocksLoader key={index} items={getRandomArray()} />
              ))
          }
      </>
  )
}

// export default index