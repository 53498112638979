import styled from "styled-components";

export const InputCont = styled.div<any>`
  background-color: inherit;
  height: 32px;
  border-radius: 8px;
  border: none;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  text-align: left;
  width: 100%;
  font-size: 13px;
   label {
    font-size: 11px;
    width: auto;
    left: 0px;
    font-size: 11px;
    color: grey;
    margin-bottom: 0px;
    pointer-events: none;
    position: absolute;
    background-color: transparent;
    transition: all 2s linear;
    bottom: ${({ focused, hasInput }: any) => focused || hasInput ? '17px' : 'auto'};
  }
  input {
    background-color: transparent;
    bottom: ${({ focused, hasInput }: any) => focused || hasInput ? '-5px' : 'auto'};
  }
  /* @media(max-width: 768px) {
    height: 40px;
  } */
`
export const TextInput = styled.input.attrs((props:any) => ({
    className: 'form-control',
    placeholder: props.focused ? props.placeholder : '',
}))`
  padding: 0.575rem .7rem 0.75rem 0rem;
  width: 100%;
  height: 100%;
  bottom: 'auto';
  height: 65%;
  bottom: 1%;
  position: absolute;
  border-radius: 0px 0px 5px 5px;
  border:none;
  background-color: none;
  font-size: 13px;
  color: ${props => props.theme.mode === 'dark' ? props.theme.dark.colors.labels.pri : props.theme.light.colors.labels.pri
    };
  &:focus {
    box-shadow: none;
    outline: none;
    background-color: transparent;
    color: ${props => props.theme.mode === 'dark' ? props.theme.dark.colors.labels.pri : props.theme.light.colors.labels.pri
    };
  }
  ::placeholder {
    color: #d1d0d0;
  }
  @media(max-width: 768px) {
    font-size: 14px;
  }
`;