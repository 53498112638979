import { useReactiveVar } from "@apollo/client";
import { ReactElement, useState } from "react";
import { Divider } from "../../../../components/icons/styles";
import { globalInvoice, initInvoice, invCriteria } from "../../../../types/defaults";
import { Invoice as InvoiceModel } from "../../../../types/model";
import { formatFigures, format_date, getNetInvoiceToatal, roundAmount, sortByPaid } from "../../../../utils";
import { Header } from "../../styles";

import { ElasticContainer } from "../containers/elastic";
import InvoiceInfo from "../details";
import { OrderItem } from "../order"; 
import { Footer } from "../order/styles";
import { InnerList } from "./inner";
import { ItemsCont } from "./styles";
import { Link } from "react-router-dom";

export function OuterList(props: any): ReactElement {
    const { count, total, groupId } = props;
    const { group } = useReactiveVar(invCriteria)
    const [printerIcon, showPrinterIcon] = useState(false)

    const [showInfo, setShowInfo] = useState(false)
    const [selectedIvoice, setSelectedInvoice] = useState(initInvoice)

    const { list } = props;
    const showInvoieInfo = (i: any)=>{
        setSelectedInvoice(i)
    }
    const hideInvoieInfo = (i: any)=>{
        setSelectedInvoice(initInvoice)
        setShowInfo(false)
    }
    const generateLink = (group: string, id: string) => {
        return `/invoices?p=${group}&q=${encodeURIComponent(id)}`;
    }

    return (
        <section
            onMouseLeave={() => showPrinterIcon(false)}
            onMouseEnter={() => showPrinterIcon(true)}
            className="stocksContainer"
        >
            {
                <>
                    <Header>
                        <h6>{group === 'date' ? format_date(list.records[0].createdAt) : list._id || 'Not specified'}</h6>
                        {
                            count - 5 > 0 &&
                            <h6>
                                <Link to={generateLink(group, groupId)}>SHOW ALL</Link>
                            </h6>
                        }
                        <Divider />
                    </Header>
                    {
                        list.records.filter((r: any)=> r.stocks.length > 0).map((record: any, j: number) =>
                            <ElasticContainer contentHeight={55} initHeight={55}> 
                                    <ItemsCont>
                                    <OrderItem showInfoCallback={showInvoieInfo} stock={record.stocks[0]} invoice={record} /> {
                                    } 
                                    </ItemsCont>
                            </ElasticContainer>
                        )
                    }
                    <Footer>
                        {/* <p>+{
                        count - 5 <= 0 ? 0 : count - 5
                        }
                        </p> 
                        <h6> 
                            {
                                formatFigures(total)
                            }
                            
                        </h6> */}
                    </Footer>
                </>
            }
        </section>
    )
}