import { useMutation, useReactiveVar } from '@apollo/client'
import React, { useState } from 'react'
import { Cancel, HidePass, ShowPass } from '../../../../../../components/icons'
import { Icon } from '../../../../../../components/icons/styles'
import { showAccModal } from '../../../../../../globals'
import { SAVE_PASSWORD } from '../../../../../../graphql/mutations/account'
import Button from '../../button'

import { Input } from '../inputs'
import { Container, FormGroup, FormGroupCont, FormItem, HeaderCont } from './styles'

const PwdForm = () => {

    const [focused, setFocused] = useState('')
    const [type, setType] = useState('password')
    const [newType, setNewType] = useState('password')
    const [pwd, setPwd] = useState('')
    const [newpwd, setNewPwd] = useState('')

    const show = useReactiveVar(showAccModal)

    const focus = (name: string) => {
        setFocused(name)
    }
    const switchType = () => {
        setType(type === 'password' ? 'text' : 'password')
    }
    const switchNewType = () => {
        setNewType(newType === 'password' ? 'text' : 'password')
    }

    const handleChange = (e: any) => {
        e.persist();
        setPwd(e.target.value)
    }
    const handleNewChange = (e: any) => {
        e.persist();
        setNewPwd(e.target.value)
    }


    const [submit, { error, loading, data }] = useMutation(SAVE_PASSWORD, {
        update: (cache, { data: { savePwd } }) => {
            // let myAccount: Account = cache.readQuery({
            //     query: GET_MY_ACCOUNT
            // }) as any;

            // console.log(myAccount)

            // cache.writeQuery({
            //     query: GET_MY_ACCOUNT,
            //     data: {
            //         ...myAccount,
            //         email
            //     }
            // })
        }
    })

    const handleSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault()
        submit({
            variables: {
                pwd,
                newpwd
            }
        })
    }


    return (
        <Container show={show === 'pwd'}>
            <HeaderCont>
                <h6>Change password</h6>
                <Icon onClick={() => showAccModal('')}>
                    <Cancel />
                </Icon>
            </HeaderCont>
            <FormItem onSubmit={(e: React.SyntheticEvent) => handleSubmit(e)}>
                <FormGroupCont>
                    <FormGroup top>
                        <Input
                            type={type}
                            name='pwd'
                            label='Current password'
                            focused={focused === 'pwd'}
                            placeholder='Current password'
                            changeCallback={(e: any) => handleChange(e)}
                            focusedCallback={(name: string) => focus(name)}
                        />
                        <Icon onClick={() => switchType()} id='pwd'> {
                            type === 'password' ? <HidePass /> : <ShowPass />
                        }
                        </Icon>
                    </FormGroup>
                    <FormGroup>
                        <Input
                            type={newType}
                            value={newpwd}
                            name='newpwd'
                            label='New password'
                            focused={focused === 'newpwd'}
                            placeholder='New password'
                            changeCallback={(e: any) => handleNewChange(e)}
                            focusedCallback={(name: string) => focus(name)}
                        />
                        <Icon onClick={() => switchNewType()} id='pwd'> {
                            newType === 'password' ? <HidePass /> : <ShowPass />
                        }
                        </Icon>
                    </FormGroup>
                </FormGroupCont>
                {
                    error && <p className="error">{error?.message}</p>
                }
                <Button
                    error={error}
                    data={data} 
                    loading={loading}
                    title='Change Password'
                    disabled={!pwd || !newpwd}
                 />
            </FormItem>
        </Container>
    )
}

export default PwdForm