import React from 'react'
import { Plus } from '../../../../../components/icons'
import { Divider, Icon } from '../../../../../components/icons/styles'
import { showStaffModal } from '../../../../../globals'
import AddBtn from '../button/add'
import { HeaderCont, IconBtn } from './styles'

const MainHeader = () => {

  return (
      <HeaderCont>
        <h6>Staff</h6>
        <div></div>
        <IconBtn onClick={() => showStaffModal(true)} title="Add Staff">
         <p>+</p>
        </IconBtn>
        <Divider />
      </HeaderCont>
  )
}

export default MainHeader