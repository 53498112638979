import React, { useEffect, useState } from 'react'
import { SmallCheckMark } from '../../../../components/icons';
import { Icon } from '../../../../components/icons/styles';
import { Loader } from '../../../../components/loaders'

import { ButtonItem, SaveBtnItem } from './styles'

const Button = (props: any) => {
  const { loading, data, title, disabled } = props;

  const [state, setState] = useState(title);

  useEffect(() => {
    if (loading) {
      setState('spin')
    } else if (data) {
      setState('done');
      setTimeout(() => {
        setState(title)
      }, 9000);
    }

  }, [data, title, loading])
  return (
    <ButtonItem {...props}>
      {
        state === 'spin' ? 
          <Loader />
          : state === 'done' ?
            <Icon className="check">
              <SmallCheckMark />
            </Icon>
            :
            state
      }
    </ButtonItem>
  )
}

export default Button
export const SaveButton = (props: any) => {
  const { loading, data, title, disabled } = props;

  const [state, setState] = useState(title);

  useEffect(() => {
    if (loading) {
      setState('spin')
    } else if (data) {
      setState('done');
      setTimeout(() => {
        setState(title)
      }, 9000);
    }

  }, [data, title, loading])
  return (
    <SaveBtnItem {...props}>
      {
        state === 'spin' ? 
          <Loader />
          : state === 'done' ?
            <Icon className="check">
              <SmallCheckMark />
            </Icon>
            :
            state
      }
    </SaveBtnItem>
  )
}

