import React, { useState } from 'react'
import { getImageUrl } from '../../../../../apollo'
import { Calender, Cancel, Phone } from '../../../../../components/icons'
import { Icon } from '../../../../../components/icons/styles'
import { chatUser, chatView, globalUser, openChatBox, showStaffModal } from '../../../../../globals'
import { initStaff } from '../../../../../globals/defaults'

import { format_date } from '../../../../../utils'
import Button from '../button'
import { MessageBtn, MoreBtn } from '../button/styles'
import { MoreActions } from './menu'
import { ButtonsCont, ContactsCont, ContactsItem, Container, DpCont, DpImageCont, ImageCont, InfoCont, SubInfo } from './styles'

export const avatar =  'https://nextoma-bucket.s3.us-east-2.amazonaws.com/a35f9e5f-771c-4afd-b4b7-0869842c8dc9'
const Info = (props: any) => {
    const { creds, view, loading, staff, showInfo, selectedStaff: usr, hideCallback, hideStaffInfoCallback } = props

    const showChat = (e: any) => {
        openChatBox(true)
        chatView('chat')
        chatUser(staff)
        // clickCallback(e);
    }

    const [showMenu, setShowMenu] = useState(false)
    // const [showInfo, setShowInfo] = useState(false)

    // const hideInfo = ()=>{
    //     setShowInfo(false)
    //     hideStaffInfoCallback()
    // }
   
    return (
        <InfoCont show={(usr?._id === staff?._id) && showInfo} >
            <Icon id='close' onClick={hideCallback}>
                <Cancel />
            </Icon> 
            <DpImageCont>
                <ImageCont>
                    <img src={usr?.dp ? getImageUrl(usr?.dp) : avatar} alt="" />
                    
                </ImageCont>
                <h4>{usr?.info?.name}</h4>
                <p className='username'>@{usr?.username}</p>
                {/* <p className='role'>{creds.role}</p> */}
            </DpImageCont>
            <ContactsCont>
                <ContactsItem>
                    <Icon>
                        <Phone />
                    </Icon>
                    <p>
                        {usr?.info?.bio}
                    </p>
                </ContactsItem>
                <ContactsItem>
                    <Icon id="date">
                        <Calender />
                    </Icon>
                    <p>
                        {format_date(usr?.createdAt)}
                    </p>
                </ContactsItem>
            </ContactsCont>
            <ButtonsCont>
                <MessageBtn onClick={showChat}>Message</MessageBtn>
                <MoreBtn onClick={()=>setShowMenu(true)}>...</MoreBtn>
                <MoreActions username={usr?.username} closeMenuCallback={()=>setShowMenu(false)} show={showMenu} />
            </ButtonsCont>
        </InfoCont>
    )
}

export default Info