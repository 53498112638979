import { useMutation } from "@apollo/client";
import { useLocation } from "react-router-dom";
import { Divider } from "../../../../../components/icons/styles";
import { DROP_STAFF } from "../../../../../graphql/mutations/staff.mutation";
import { GET_STAFF } from "../../../../../graphql/queries";

import { MenuOptionsList, Option } from "./styles";

export const MoreActions = (props: any) => {
    let { show, username, closeMenuCallback } = props



    const [drop, { error, loading }] = useMutation(DROP_STAFF, {
        update: (cache, { data: { dropStaff: dropped } }) => {
            const cached: any = cache.readQuery({
                query: GET_STAFF,
                
            })

            // let newProducts = cached.products.map((prods: any) => {
            //     return (prods.records.some((r: any) => r._id === deleted._id)) ?
            //         ({
            //             ...prods,
            //             records: prods.records.filter((p: any) => p._id !== deleted._id)
            //         })
            //         :
            //         prods
            // })
            // cache.writeQuery({
            //     query: GET_STAFF,
               
            //     data: {
            //         products: newProducts.filter((p: any) => p.records.length)
            //     }
            // });
        }
    });

    const onDrop = () => {
        drop({
            variables: {
                id: username
            },
        })
    }
    return (
        <MenuOptionsList
            {...props}
            onClick={() => closeMenuCallback()}
            onMouseLeave={() => closeMenuCallback()}
        >
                <Option>
                    <p>Profile</p>
                </Option>
                <Option>
                    <p>Username</p>
                </Option>
                <Option>
                    <p>Phone</p>
                </Option>
                <Option>
                    <p>Password</p>
                </Option>
                <Option delete onClick={() => onDrop()}>
                    <p>Delete Staff Account</p>
                </Option>
        </MenuOptionsList>
    )
}