import styled from "styled-components";

export const ButtonItem = styled.button.attrs(props => ({
  diabled: props.disabled,
})) <any>`
  height: 45px;
  width: 100%;
  border-radius: 10px;
  position: absolute;
  display: flex;
  margin-top: 15px;
  bottom: 0;
  right: 0;
  z-index:150;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  font-size: 13px;
  color: ${props => props.disabled ? '#b2b3b2' : 'white'} ;
  font-weight: 600;
  background-color: ${props => props.disabled ? 'whitesmoke' : '#08d57f'};
  &:hover {
    border: none;
    color: ${props => props.disabled ? '#b2b3b2' : 'white'};
    background-color: ${props => props.disabled ? 'whitesmoke' : '#05b86d'} ;
  }
  :hover {
    svg {
      stroke: "white";
    }
  }
  &:focus {
    border: none;
  }
  .check {
    width: 18px;
    height: 18px;
    svg {
      stroke: white;
    }
  }
`;