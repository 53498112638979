import { useReactiveVar } from "@apollo/client";
import { ReactElement, useState } from "react";
import { Divider } from "../../../../components/icons/styles";
import { globalInvoice, initInvoice, invCriteria } from "../../../../types/defaults";
import { Invoice as InvoiceModel } from "../../../../types/model";
import { formatFigures, format_date, getNetInvoiceToatal, roundAmount, sortByPaid } from "../../../../utils";
import { Header } from "../../styles";

import { ElasticContainer } from "../containers/elastic";
import InvoiceInfo from "../details";
import { Invoice } from "../invoice";
import { Footer } from "../invoice/styles";
import { InnerList } from "./inner";
import { ItemsCont } from "./styles";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

export function ShownInvoiceList(props: any): ReactElement {
    const { count, total, groupId } = props;
    const { group } = useReactiveVar(invCriteria)
    const [printerIcon, showPrinterIcon] = useState(false)

    // const [showInfo, setShowInfo] = useState(false)
    const [selectedIvoice, setSelectedInvoice] = useState(initInvoice)

    const { list } = props;
    const showInvoieInfo = (i: any)=>{
        setSelectedInvoice(i)
    }

    const location = useLocation() as any
    const params = new URLSearchParams(location.search)

    return (
        <section
            onMouseLeave={() => showPrinterIcon(false)}
            onMouseEnter={() => showPrinterIcon(true)}
            className="stocksContainer"
        >
            {
                <>
                    <Header>
                        <h6>{format_date(params.get("q"))}</h6>
                        <h6></h6>
                        <Divider />
                    </Header>
                    {
                        list.map((record: any, j: number) =>
                            <ElasticContainer key={record._id.id} contentHeight={55} initHeight={55}> 
                                    <ItemsCont>
                                        <Invoice showInfoCallback={showInvoieInfo} stock={record.group[0].stocks} invoice={record} /> {
                                    } 
                                    </ItemsCont>
                            </ElasticContainer>
                        )
                    }
                </>
            }
        </section>
    )
}