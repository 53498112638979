import styled from "styled-components"
export const Main = styled.main`
    width: 100vw;
    height: 100vh;
    position: relative;
    padding-top: 170px;
     .window {
      width: 100%;
      text-align: center;
     }
`
export const Container = styled.div.attrs({
    className: "container",
})`
  height: 100%;
  position: relative;
  max-width: 1000px;
  padding: 0px;
`;

export const TableCont = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  margin: 0 auto;
`;


export const TableActionsCont = styled.div`
    width: 100%;
    height: 40px;
    position: relative;
    background: #ffffff;
    display: grid;
    align-items: center;
    grid-template-columns: 70% 12% 12% 6%;
`
export const CatItemCont = styled.div`
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    p {
        margin-bottom: 0px;
        font-size: 12px;
    }
    .icon {
      width: 12px;
      height: 12px;
      cursor: pointer;
    }
  &:last-child{
    text-align: right;
    justify-content: end;
  }
`

