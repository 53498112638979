import { useMutation, useReactiveVar } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import { Cancel, HidePass, ShowPass } from '../../../../../../components/icons'
import { Icon } from '../../../../../../components/icons/styles'
import { showAccModal } from '../../../../../../globals'
import { SAVE_PHONE_NUMBER } from '../../../../../../graphql/mutations/account'
import { GET_MY_ACCOUNT } from '../../../../../../graphql/queries'
import { Account } from '../../../../../../models/account'
import Button from '../../button'

import { Input } from '../inputs'
import { Container, FormGroup, FormGroupCont, FormItem, HeaderCont } from './styles'

const PhoneForm = (props: any) => {

    const [focused, setFocused] = useState('')
    const [phone, setPhoneNumber] = useState(props.phone)
    const [cancel, setCancel] = useState('')

    const show = useReactiveVar(showAccModal)

    useEffect(() => {
        setPhoneNumber(props.phone)
    }, [props.phone])

    const [submit, { error, loading, data }] = useMutation(SAVE_PHONE_NUMBER, {
        update: (cache, { data: { savePhoneNumber } }) => {
            // let myAccount: Account = cache.readQuery({
            //     query: GET_MY_ACCOUNT
            // }) as any;

            // console.log(myAccount)

            // cache.writeQuery({
            //     query: GET_MY_ACCOUNT,
            //     data: {
            //         ...myAccount,
            //         phone
            //     }
            // })
        }
    })

     if(error) console.log({...error});

    const handleChange = (e: any) => {
        e.persist();
        setPhoneNumber(e.target.value)
    }
    const clear = () => {
        setPhoneNumber('')
    }
    const focus = (name: string) => {
        setFocused(name)
    }
    const mouseEnter = () => {
        setCancel('phone');
    }
    const mouseLeave = () => {
        setCancel('')
    }

    const handleSubmit = (e: React.SyntheticEvent) => {
        e.preventDefault()
        submit({
            variables: {
                phone
            }
        })
    }

    return (
        <Container show={show ==='phone'}>
            <HeaderCont>
                <h6>Change phone number</h6>
                <Icon onClick={() => showAccModal('')}>
                    <Cancel />
                </Icon>
            </HeaderCont>
            <FormItem onSubmit={(e: React.SyntheticEvent) => handleSubmit(e)}>
                <FormGroupCont>
                    <FormGroup onMouseLeave={() => mouseLeave()} onMouseEnter={() => mouseEnter()}>
                        <Input
                            value={phone}
                            name='phone'
                            focused={focused === 'phone'}
                            label='Contact phone number'
                            placeholder='080123456789'
                            changeCallback={(e: any) => handleChange(e)}
                            focusedCallback={(name: string) => focus(name)}
                        />
                        {
                            (cancel === 'phone') &&
                            <Icon onClick={clear}>
                                <Cancel />
                            </Icon>
                        }
                    </FormGroup>
                </FormGroupCont>
                {
                    error && <p className="error">{error?.message}</p>
                }
                <Button
                    error={error}
                    title={phone ? 'Update Number' : 'Add New Number '}
                    data={data} loading={loading}
                    disabled={!phone}
                />
            </FormItem>
        </Container>
    )
}

export default PhoneForm