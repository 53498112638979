import styled from "styled-components";

export const BtnCont = styled.div<any>`
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    width: 100%;
    bottom: 10px;
    right: 0px;
    padding: 0px 10px;
    `

export const ButtonItem = styled.button<any>`
  height: 40px;
  width: 100%;
  border-radius: 30px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  background: whitesmoke;
  font-size: 11px;
  color: #424242;
  font-weight: 600;
  &:hover {
    border: none;
    background-color: #08d57f;
  }

`;