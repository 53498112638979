import React from 'react'
import { MessageButton } from '../buttons/styles'
import { MessageCont } from './styles'

function ErrorState(props: any) {
  const {retryCallback} = props;
  const retry = ()=>{
    retryCallback()
  }
  return (
      <MessageCont>
          <h6>Something went wrong</h6>
          <p>Please check your internet connection and try again</p>
          <MessageButton onClick={retry}>Retry</MessageButton>
      </MessageCont>
  )
}

export default ErrorState