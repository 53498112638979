
import { getImageUrl } from "../../../apollo"
import { simplifyExpDate } from "../../../utils"
import { SmallCheckMark } from "../../icons"
import { Icon } from "../../icons/styles"
// import { DateIndictor, StockIndicator } from "../../images/styles"
import { DateIndictor, Glass, ImageCont, StockIndicator } from "./styles"

const ImageItem = (props: any) => {
    const { selected, source, expiry, expiryStatus } = props
    const exp = expiry ? simplifyExpDate(expiry) : null

    return (
        <ImageCont>
            {
                (expiryStatus && expiryStatus === 'weak' || expiryStatus === 'expired') ? <StockIndicator {...props} /> : <></>
            }
            <img src={source} alt="" />
            {
                exp && <DateIndictor ><p>{exp}</p></DateIndictor>
            }
            {
                selected &&
                <Glass>
                    <Icon>
                        <SmallCheckMark />
                    </Icon>
                </Glass>
            }
        </ImageCont>
    )
}
export { ImageItem }