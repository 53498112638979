
import { useReactiveVar } from "@apollo/client";
import { useEffect, useState } from "react";
import { getImageUrl } from "../../../../../apollo";
import { globalUser, openChatBox, showCheckOutModal, showStaffModal } from "../../../../../globals";
import Info, { avatar } from "../view/info";
import { ImageCont, StaffCont, StaffWrapper } from "./styles"

const StaffItem = (props: any) => {
    const { staff, showStaffInfoCallback, hideStaffInfoCallback } = props;
    const {info} = staff
    const [showInfo, setShowInfo] = useState(false)

    // const [user, setUser] = useState(usr)

    // useEffect(() => {
    //  setUser(usr)
    // }, [usr])
    
    // const hideUser = () => {
    //     console.log('Closing...');
    //     globalUser(null)

    //     console.log(user?._id === staff?._id);
        
    // }

    const hideInfo = () => {
        setShowInfo(false)
        hideStaffInfoCallback()
    }
    const show = () => {
        setShowInfo(true)
        showStaffInfoCallback()
    }

    
    
    return (
        <StaffWrapper onClick={() => show()}>
            <StaffCont>
                <ImageCont>
                    <img src={info?.dp ? getImageUrl(info?.dp) : avatar} alt=""  />
                </ImageCont>
                <h4>{info?.name}</h4>
                <p>{info?.role}</p>
            </StaffCont>
            <Info hideCallback={hideInfo} showInfo={show} {...props}/>
        </StaffWrapper>
    )
}
export default StaffItem