import React, { ReactElement, useState, useEffect, useRef, Fragment } from 'react'

import { useReactiveVar } from '@apollo/client';

import Payment from './payment';
import Cart from './cart';

import { BodyCont, Header, Footer, Card, Modal } from './styles';

import { onlineCart, showOnlineCheckOutModal } from '../../../globals';
import { Divider, Icon, IconBtn } from '../../icons/styles';
import { Chevron } from '../../icons';
import { CartItem } from '../../../models';
import { getLocalStore } from '../../../utils';

export function CheckOutOnline(props: any): ReactElement {

    const showCheckOut = useReactiveVar(showOnlineCheckOutModal);
    const myCart: any = useReactiveVar(onlineCart);
    let [order, setOrder] = useState(myCart)
    const [checkOut, setCheckOut] = useState(false)
    const [openAddressForm, setOpenAddressForm] = useState(false)

    const [account, setAccount] = useState<any>({

    });
   
    useEffect(() => {
        setOrder(myCart)
    }, [myCart])

    useEffect(() => {
        const account = getLocalStore()
        if (account) {
            // console.log(account.addresses[0])
            setAccount(account);
            console.log(`account ${JSON.stringify(account.addresses, null, 2)}`)
        }

    },[])

    const getCartTotal = (items: CartItem[]) => {
        return items.reduce((total: number, i: CartItem) =>
            total + i.item.sellingPrice * i?.quantity, 0)
    }

    const toggleCheckOut = () => {
        setCheckOut(!checkOut)
    }

    const handleClick = (e: React.SyntheticEvent) => {
        e.stopPropagation();
    }

    return (
        <Card
            slideIn={showCheckOut}
            onClick={(e: React.SyntheticEvent) => handleClick(e)}>
            <Header>
                {
                    checkOut ?
                        <Fragment>
                            <IconBtn title='Back' size={30} onClick={toggleCheckOut} >
                                <Icon rot={90}>
                                    <Chevron />
                                </Icon>
                            </IconBtn>
                            <h4>Checkout</h4>
                        </Fragment>
                        :
                        <Fragment>
                            <p></p>
                            <h4>Cart({order.stocks.length}) </h4>
                        </Fragment>
                }
                <Divider />
            </Header>
            <BodyCont>
                <Cart order={order} in={checkOut} />
                <Payment
                    in={checkOut}
                    account={account}
                    openAddressForm={openAddressForm}
                    total={getCartTotal(order.stocks)}
                    openAddressCallback={()=>setOpenAddressForm(true)}
                    closeAddressFormCallback={()=>setOpenAddressForm(false)}
                />
            </BodyCont> {
                checkOut ?
                    <></>
                    :
                    <Footer>
                        <p onClick={toggleCheckOut}>Continue</p>
                        <Icon onClick={toggleCheckOut} rot={-90}>
                            <Chevron />
                        </Icon>
                    </Footer>
            }
            <Modal onClick={() => setOpenAddressForm(false)} opened={openAddressForm} />
        </Card>
    )
}
