import styled from "styled-components";
export const Container = styled.div<any>`
  height: 400px;
  width: 250px;
  right: 20px;
  padding: 0px 10px 10px 10px;
  top: -100px;
  z-index: 2;
  border-radius: 10px;
  position: absolute;
  overflow: visible;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  border: 1px solid whitesmoke;
  background: white;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgba(71, 75, 76, 0.055);
  display: ${props => props.show ? 'flex' : 'none'};
  box-shadow: rgba(196, 195, 195, 0.216) 0px 5px 25px;
`;
export const HeaderCont = styled.header`
    height: 40px;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
     h6 {
        font-size: 14px;
        margin-bottom: 0px;
        font-weight: 700;
    }
    .icon {
        height: 10px;
        width: 10px;
        cursor: pointer;
    }
`
export const FormItem = styled.form`
  width: 100%;
  height: 100%;
  position: relative;
   .error {
    color: #ff0000b6;
    font-size: 12px;
    width: 100%;
    text-align: center;
    bottom: 50px;
    position: absolute;
  }
`;
export const FormGroupCont = styled.div<any>`
  margin: 8px 0px;
  position: relative;
  border-radius: 8px;
  width: ${(props) => props.w || 100}%;
  border: 1px solid rgb(0 0 0 / 6%);
`;

export const FormGroup:any = styled.div<any>`
  display: grid;
  position: relative;
  padding: 5px 6px;
  grid-template-columns: 92% 8%;
  align-items: center;
  height: ${(props) => props.h || 45}px;
  width: ${(props) => props.w || 100}%;
  background: ${(props) => (props.focused ? "whitesmoke" : "initial")};
  border-bottom-left-radius: ${(props) => (props.top ? "0px" : "6px")};
  border-bottom-right-radius: ${(props) => (props.top ? "0px" : "6px")};
  border-bottom: ${(props) => (props.top ? "1px" : "0px")} solid rgb(0 0 0 / 6%);
  border-right: ${(props) => (props.left ? "1px" : "0px")} solid #d3d3d35c;
  /* border-bottom-color: ${(props) => (props.top ? "#e6e1e1" : "white")}; */
  .icon {
    width: 8px;
    height: 8px;
    cursor: pointer;
    position: relative;
    /* border-radius: 50%; */
    :hover {
        background: whitesmoke;
    }
  }
`;