import React, { useState } from 'react'
import { Divider } from '../../../../components/icons/styles'
import { formatMoney, formatTime, format_date, getCartTotal } from '../../../../utils'
import { AccordionCont, InfoItem, InfoItemHeader } from './styles'

const Accordion = (props: any) => {
  const { type, defaultState, invoice } = props
  const [opened, setOpened] = useState(defaultState ?? false)
  const getBalaceAmount = () => {
    return +getCartTotal(invoice.stocks) - invoice.recieved
  }
  return (
    <>
      <InfoItemHeader onClick={() => setOpened(!opened)}>
        <h6>{
          type === 'invoice' ? 'INVOICE' : type === 'customer' ? 'CUSTOMER' : 'TIMESTAMP'
        }
        </h6>
        <p>{opened ? '-' : '+'}</p>
        <Divider />
      </InfoItemHeader>
      {
        type === 'invoice' ?
          <AccordionCont opened={opened}>
            <InfoItem>
              <h6>ID</h6>
              <p>{invoice!.tid?.toLocaleUpperCase()}</p>
            </InfoItem>
            <InfoItem>
              <h6>PAID</h6>
              <p>{formatMoney(invoice.recieved)}</p>
            </InfoItem>
            <InfoItem>
              <h6>BALANCE</h6>
              <p>{formatMoney(getBalaceAmount())}{getBalaceAmount() === 0 ? '.00' : ''}</p>
            </InfoItem>
            <InfoItem >
              <h6>PAYMENT METHOD</h6>
              <p id='method'>{invoice.paymentMethod.toUpperCase()}</p>
              <Divider />
            </InfoItem>
            <InfoItem  status={invoice.completed}>
              <h6>STATUS</h6>
              <p id='status'>{invoice.completed ? 'Completed':'Pending'}</p>
              <Divider />
            </InfoItem>
          </AccordionCont>
          :
          type === 'customer' ?
            <AccordionCont opened={opened}>
              <InfoItem>
                <h6>NAME</h6>
                <p>{invoice!.customer?.firstName || '---'}</p>
              </InfoItem>
              <InfoItem>
                <h6>EMAIL</h6>
                <p>{invoice!.customer?.email || '---'}</p>
              </InfoItem>
              <InfoItem>
                <h6>PHONE</h6>
                <p>{invoice!.customer?.phone || '---'}</p>
              </InfoItem>
              <InfoItem>
                <h6>ADDRESS</h6>
                <p>{invoice!.customer?.address || '---'}</p>
                <Divider />
              </InfoItem>
            </AccordionCont>
            :
            <AccordionCont opened={opened}>
              <InfoItem>
                <h6>ADDED</h6>
                {/* <p>{format_date(invoice.createdAt || new Date())} {formatTime(invoice.createdAt || new Date())}<span> - </span>{invoice?.added?.firstName || '---'}</p> */}
                <Divider />
              </InfoItem>
              <InfoItem>
                <h6>MODIFIED</h6>
                {/* <p>{format_date(invoice.updatedAt || new Date())} {formatTime(invoice.updatedAt || new Date())}<span> - </span>{invoice?.added?.firstName || '---'}</p> */}
              </InfoItem>
            </AccordionCont>
      }
    </>

  )
}

export default Accordion