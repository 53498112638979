import React, { SyntheticEvent, useEffect, useState } from 'react'
import { MenuOptionsList,Option } from './styles'

const PaymentMenthods = (props: any) => {
    const { show, selectMethodCallback } = props
    const [showOpt, setShowOpt] = useState(false)
    useEffect(() => {
        setShowOpt(show)
    }, [show])
    
  return (
      <MenuOptionsList show={showOpt}>
          <Option onClick={(e: SyntheticEvent) => selectMethodCallback(e, 'Cash')}>
              <p>Cash</p>
          </Option>
          <Option onClick={(e: SyntheticEvent) => selectMethodCallback(e, 'POS')} >
              <p>POS</p>
          </Option>
          <Option onClick={(e: SyntheticEvent) => selectMethodCallback(e, 'Transfer')}>
              <p>Transfer</p>
          </Option>
      </MenuOptionsList>
  )
}

export default PaymentMenthods