import { getImageUrl } from "../../../../../../apollo"
import { simplifyExpDate } from "../../../../../../utils"
import { DotMark, Exp, ImageCont } from "./styles"


const ImageItem = (props: any) => {
    const { source, expiry, expiryStatus } = props
    const exp = expiry ? simplifyExpDate(expiry) : null

    return (
        <ImageCont {...props}>
            {
                (expiryStatus && expiryStatus === 'weak' || expiryStatus === 'expired') ? <DotMark {...props} /> : <></>
            }
            {source && <img src={getImageUrl(source)} alt="" />}
            {
                exp && <Exp><p>{exp}</p></Exp>
            }
        </ImageCont>
    )
}
export { ImageItem }