import { useQuery } from '@apollo/client'
import React, { SyntheticEvent, useState } from 'react'
import { useParams } from 'react-router-dom'
import { NavLink } from 'react-router-dom'

import { CategoriesListCont, Option, CategoryItems, HeaderCont, HeaderListItems, HeaderMainCont, Item, MenuOptionsList } from './styles'
import { useLocation } from 'react-router-dom'

import SearchForm from '../../forms'
import { categories } from '../../../../inventory/components/forms/stock'
import { Divider, Icon } from '../../../../../components/icons/styles'
import { Link } from 'react-router-dom'
import { CheveronUpDown } from '../../../../../components/icons'

export const CategoryListHeader = (props: any) => {
    // const [active, setaAtive] = useState('cosmetics')
    const [showMenu, setShowMenu] = useState<any>(false)
    const { category, subCategory } = useParams()
    const location = useLocation() as any

    const params = new URLSearchParams(location.search)

    // const loc = useLocation()

    // const { pathname } = useLocation();
    // const params = new URLSearchParams(loc.search)

    const getCategories: any = () => categories.find((cat: any) => cat.name === params.get("cat")||'Food');

    const selectCategory = (e: SyntheticEvent) => {
        e.stopPropagation();
        setShowMenu(false)
    }
        
    return (
        <CategoriesListCont>
            {
                
                <CategoryItems onClick={() => setShowMenu(true)}>
                    <p className="divide">{params.get("cat") || 'Foods'}</p>
                    <Icon className='updown'>
                        <CheveronUpDown />
                    </Icon>
                    <MenuOptionsList show={showMenu}>
                        <Option active={params.get("cat") === 'Foods'} >
                            <Link
                                title='Foods'
                                to='/explore/?cat=Foods'
                                onClick={(e: SyntheticEvent) => selectCategory(e)}
                            >
                                <p>Foods</p>
                            </Link>
                        </Option>
                        <Option active={params.get("cat") === 'Fashion'} >
                            <Link
                                title='Foods'
                                to='/explore/?cat=Fashion'
                                onClick={(e: SyntheticEvent) => selectCategory(e)}
                            >
                                <p>Fashion</p>
                            </Link>
                        </Option>
                        <Option active={params.get("cat") === 'Cosmetics'} >
                            <Link
                                title='Cosmetics'
                                to='/explore/?cat=Cosmetics'
                                onClick={(e: SyntheticEvent) => selectCategory(e)}
                            >
                                <p>Cosmetics</p>
                            </Link>
                        </Option>
                        <Option active={params.get("cat") === 'Provisions'}>
                            <Link
                                to='/explore/?cat=Provisions'
                                title='Provisions'
                                onClick={(e: SyntheticEvent) => selectCategory(e)}
                            >
                                <p>Provisions</p>
                            </Link>
                        </Option>
                        <Option active={params.get("cat") === 'Pharmaceuticals'} >
                            <Link
                                title='Pharmaceuticals'
                                to='/explore/?cat=Pharmaceuticals'
                                onClick={(e: SyntheticEvent) => selectCategory(e)}
                            >
                                <p>Pharmaceuticals</p>
                            </Link>
                        </Option>
                    </MenuOptionsList>
                </CategoryItems>
            }
        </CategoriesListCont>
    )
}
export const SubCatHeader = (props: any) => {
    // const [active, setaAtive] = useState('cosmetics')
    const { category, subCategory } = useParams()
    const location = useLocation() as any

    const params = new URLSearchParams(location.search)

    const getCategories: any = () => {
        const cats: any = categories.find((cat: any) => cat.name === params.get("cat")) || categories[0];
        
        return cats
    }
        
    return (
        <HeaderCont>
            {
                <HeaderListItems> {
                        getCategories()?.items?.map((item: any) => (
                            <Item active={params.get("subcat") === item} key={item} >
                                <NavLink to={`/explore/?cat=${getCategories().name}&subcat=${encodeURIComponent(item) }`}>
                                    <p>{item}</p>
                                </NavLink>
                            </Item>
                        ))
                    }
                    <Divider />
                </HeaderListItems>
            }
        </HeaderCont>
    )
}
const CatHeader = () => {
    const { category } = useParams()
    return (
        <HeaderMainCont>
            <HeaderCont>
                <SearchForm /> 
            </HeaderCont>
        </HeaderMainCont>
    )
}

export default CatHeader