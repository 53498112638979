import { ReactElement, SyntheticEvent, useEffect, useState } from "react";
import StockImage from "./image";
import { Button, Desc, DesCont, Input, InputCont, Item, ItemCont, PriceInput, QtyInputCont, SubTotal } from "./styles";

import { Divider } from "../../../icons/styles";
import { formatMoney } from "../../../../utils";
import { onlineCart } from "../../../../globals";
import { CartItem } from "../../../../models";
// import { CartItem } from "../../../../types/model";

export function ListItem(props: any): ReactElement {
    const [stock, setStock] = useState(props.stock)
    const { stock: stockItem, isLastItem } = props;

    useEffect(() => {
      setStock(props.stock)
    }, [props.stock])
    


    const handleQuantityChange = (e: any, action: string) => {
        e.persist();
        onlineCart(
            {
                ...onlineCart(),
                stocks: onlineCart().stocks.map((i: CartItem) => {
                    if(i.item._id === stock.item._id ) {
                        return {
                            ...i,
                            quantity: action === '+' ? i.quantity + 1 : i.quantity - 1
                        }
                    }
                    return { ...i }
                }) 
            }
        )
    }

    const removeItem = () => {
        onlineCart({
            ...onlineCart(),
            stocks: onlineCart().stocks.filter((i: CartItem) =>i.item._id !== stock.item._id)
        })
    }

    return (
        <ItemCont>
            <Item>
                <StockImage 
                    removeStockCallback={removeItem} 
                    source={stock.item?.mediaUrls[0]} 
                />
                <DesCont>
                    <Desc>
                        <p title={stock.item.name + ' ' + stock.item.description}>
                            {stock.item.name}
                        </p>
                        <p style={{ color: 'grey' }}>
                            {stock.item.description}
                        </p>
                    </Desc>
                    <QtyInputCont>
                        <InputCont>
                            <Button onClick={(e: any) => handleQuantityChange(e, '-')}>
                                -
                            </Button>
                            <Input 
                                name='quantity'
                                value={stock.quantity} 
                            />
                            <Button onClick={(e: SyntheticEvent) => handleQuantityChange(e, '+')}>
                                +
                            </Button>
                        </InputCont>
                        <SubTotal>
                            {
                                formatMoney(stock.item.sellingPrice * stock.quantity)
                            }
                        </SubTotal>
                    </QtyInputCont>
                </DesCont>
                <Divider w={isLastItem ? 88 : 73} l={isLastItem ? 'auto' : '67px'} />
            </Item>
        </ItemCont>
    )
}