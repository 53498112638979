import styled from "styled-components";

export const Header = styled.header`
  margin: 5px 0px;
  color: #343435;
  margin: auto;
  display: flex;
  width: 100%;
  height: 30px;
  align-items: center;
  position: relative;
  justify-content: space-between;
  h6 {
    font-size: 10px;
    font-weight: 700;
    color: grey;
    margin-bottom: 0px;
    text-transform: uppercase;
  }
   a {
      color: #2b2c2c;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
`
export const Container = styled.div.attrs({
    className: "container",
})`
height: 100%;
position: relative;
max-width: 1000px;
padding: 0px;
padding-top: 20px;
`;

export const TableCont = styled.div`
  height: 100%;
  width: 99%;
  padding-right: 0px;
  position: relative;
  margin: 0 auto;
`;

export const Main = styled.main`
    width: 100vw;
    height: 100vh;
    position: relative;
    padding-top: 140px;
     .window {
      width: 100%;
      text-align: center;
     }
`
export const ListGroupCont = styled.div`
  width: 100%;
`