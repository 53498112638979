import styled from "styled-components";



export const SearchSuggestions = styled.ul<any>`
    width: 100%;
    min-height: 40px;
    max-height: 400px;
    overflow-y: scroll;
    overflow:hidden;
    position: relative;
    right: 0px;
    padding: 0px;
    display: flex;
    flex-direction: column;
    background: #ffffff;
`
export const Suggestions = styled.li<any>`
    width: 100%;
    height: 40px;
    padding: 0px 10px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: start;
    cursor: pointer;
    p {
        position: relative;
        margin-bottom:0px;
        color: #393939;
        text-align: left;
        font-weight: 500;
        font-size: 13px;
        padding: 0px 15px;
    }
    :hover{
      background: whitesmoke;
     }
     .icon {
      width: 20px;
      height: 20px;
     }
     #cancel {
      align-self: end;
       width: 20px;
      height: 20px;
     }
`


export const FormListItemsCont = styled.div<any>`
    height: ${props=>props.focus ? 450: 45}px;
    transition: all .15s ease-in-out;
    width: 400px;
    align-items: start;
    background: #ffffff;
    border-radius: 23px;
    margin: auto;
    z-index: 2000;
    border: 1px solid rgba(71, 75, 76, 0.055);
    box-shadow: ${props => props.focus ?  'rgba(196,195,195,0.216) 0px 5px 25px':'none'} ;
    @media(max-width: 768px) {
      width: 100%;
      height: 54px;
      border-radius: 26px;
     
    }
`
export const Form = styled.form<any>`
    height: 47px;
    width: 100%;
    grid-template-columns: 25px 1fr 30%;
    align-items: center;
    background: ${props => props.focus ? 'white' : '#f5f4f4'};
    padding: 0px 15px 0px 10px;
    display: ${props => props.mobile ? 'none' : 'grid'};
    border-radius: 23.5px 23.5px ${props => props.focus ? 0 : 23}px ${props => props.focus ? 0 : 23}px;
    @media(max-width: 768px) {
        height: 53px;
        border-radius: 25px;
        padding: 0px 15px 0px 10px;
        width: 100%;
        border: none;
        background: #f0f0f0;
        grid-template-columns: 30px 1fr 30%;
    }
`
export const Input = styled.input.attrs((props: any) => ({
    placeholder: props.placeholder,
    className: 'form-control',
    autocomplete: 'off',
    name: 'search',

}))`
  padding: 10px 10px;
  width: 100%;
  height:100%;
  position: relative;
  border:none;
  align-items: center;
  background-color: inherit;
  font-size: 14px;
  color: ${props => props.theme.mode === 'dark' ? props.theme.dark.colors.labels.pri : props.theme.light.colors.labels.pri
    };
  &:focus {
    box-shadow: none;
    outline: none;
    background-color: transparent;
    color: ${props => props.theme.mode === 'dark' ? props.theme.dark.colors.labels.pri : props.theme.light.colors.labels.pri
    };
  }
  ::placeholder {
    color: lightgrey;
  }
`;

// export const FormCont = styled.div`
//     width: 100%;
//     height: 100%;
//     display: flex;
//     position: relative;
//     align-items: center;
//     justify-content: space-between;
//     overflow: visible;
//     a {
//       text-decoration: none;
//     }
//     p {
//       margin-bottom: 0px;
//       font-size: 10px;
//       font-weight: bold;
//     }
//     h2 {
//       font-size: 24px;
//       font-weight: bold;
//       color: #25ec78;
//       margin-bottom: 0px;
//       position: relative;
//       span {
//         bottom: -50%;
//       float: right;
//       position: absolute;
//       font-size: 12px;
//       font-weight: bold;
//       margin-left: 5px;
//       z-index: 100000;
//       right: 3px;
//       color: #888888;
//       }
//     }
//     .icon {
//       width: 20px;
//       height: 20px;
//     }
// `
export const FormCont = styled.div`
    width: 100%;
    height: 45px;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
    .page__title {
      margin-bottom:0px;
      font-weight: 600;
      font-size: 22px;
      width: 100%;
      display: none;
      text-align: center;
      color: #25ec78;
    }
    h2 {
      font-size: 24px;
      font-weight: bold;
      color: #25ec78;
      margin-bottom: 0px;
    }
    .icon {
      width: 20px;
      height: 20px;
    }
    @media(max-width: 768px) {
      .home {
        display: none;
      }
      .page__title {
        display: block;
      }
    }
`
export const FilterContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: end;
    position: relative;
    cursor: pointer;
    .updown {
      svg {
        fill:grey;
      }
    }
    .divide::before {
      content:"";
      position: absolute;
      width: 2px;
      height: 70%;
      bottom: 3px;
      background: #c7c5c5;
      left: -7px;
    }
    p {
        margin-bottom: 0px;
        font-size: 13px;
        font-weight: 500;
        margin-right: 5px;
        color: #1c1c1c;
        text-align: right;
        position: relative;
    }
      .icon {
        cursor: pointer;
        width: 12px;
        height: 12px;
      }
    .icon-cont {
      position: relative;
      width: 27px;
      height: 27px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      background-color: rgba(0,0,0,.05);
      :hover {
        background-color: rgba(0,0,0,.08);
      }

      svg {
        stroke: grey;
      }
    }
    @media(max-width: 768px) {
        svg {
           position: relative;
           right: 0px;
        }
    }
`
export const MultiFormGroupContainer = styled.div<any>`
  background-color: inherit;
  height: 45px;
  width: 100%;
  border-radius: 8px;
  border: none;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  position: relative;
  padding: 0px 10px;
  justify-content: space-between;
  border: 1px solid #e6e1e1;
`
export const Double = styled.div<any>`
  width: 100%;
  height: auto;
  display: flex;
`

export const MenuOptionsList = styled.ul<any>`
  font-size: 12px;
  position: absolute;
  overflow: hidden;
  padding: 0px 0px;
  right: 0;
  z-index: 50;
  min-width: 150px;
  top: -140%;
  color: ${props => props.theme.light.colors.labels.pri};
  background-color: ${props => props.theme.light.colors.backgrounds.base.pri};
  border: 1px solid ${props => props.theme.light.colors.separators.pri};
  border-radius: 10px;
  box-shadow: 0 5px 25px #c4c3c337;

  transition: all .15s ease-in-out;
  transform: scale(${props => props.show ? 1 : 0});
`
export const Option = styled.li<any>`
  height: 35px;
  width: 100%;
  display: flex;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  position: relative;

  a {
    margin: 0px;
    text-decoration: none;
    p {
      margin-bottom: 0px;
      font-size: 12px;
      padding: 0px 10px;
      color: ${props => props.active ? 'black' : 'rgba(83, 100, 113, 0.521)'};
    }
  }
 
  :hover {
    background-color: rgb(247 247 247);
    p {
      color: black;
    }
  }
`