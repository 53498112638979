import { gql } from '@apollo/client'

export const GET_STAFF = gql`
    query staff {
        staff {
            _id
            info {
                dp
                name
                role
                bio
                storeId
                category
            }
            username
            createdAt
            updatedAt
        }
    }
`