import { gql, useReactiveVar } from '@apollo/client'
import React from 'react'
import apollo from '../../../../../apollo'
import { Divider } from '../../../../../components/icons/styles'
import { MATCHED_PRODS } from '../../../../../graphql/queries/stocks.query'
import { matchedProds, Product } from '../../../../../types/model'
import { formatMoney, getLocalStore } from '../../../../../utils'
import { ImageItem } from './imageView'



import { ItemInfo, OptItemInfo, SearchListCont, SearchListItemCont, SearchOptCont } from './styles'

const Search = (props: any) => {

    // const client = cache.cache as InMemoryCache;
    
    const { linkedTo } = getLocalStore()

    let matches: any = useReactiveVar(matchedProds)

    const reOderMatchedProducts = (product: Product, i: number) => {

       const d = apollo.readFragment({
            id: `Product:${matches[0]._id}`,
            fragment: gql`
                    fragment Product on Product {
                         _id
                         name
                         description
                         costPrice
                         sellingPrice
                         instock
                    }
                `,
        });

        const s = apollo.readQuery({
            query: MATCHED_PRODS,
            variables: {
                query: '',
                _id: ''
            }
          }
        )
    }

    

    return (
        <SearchOptCont {...props}>
                <p className='sp'>SIMILAR PRODUCTS</p>
                <SearchListCont>
                {
                    !matches?.slice(1, 3).length ? 
                    <p className='fb'>No similar products found</p>
                    :
                    <>
                    {
                                matches?.slice(1, 3).map((stock: Product, i:number) => (
                        <SearchListItemCont onClick={()=>reOderMatchedProducts(stock, i)}>
                            <ImageItem
                               
                                    source={stock?.mediaUrls[0]}
                            />
                            <ItemInfo>
                                    <p>{stock.name}</p>
                                    <p>{stock.description}</p>
                            </ItemInfo>
                            <OptItemInfo>
                                    <p>{stock?.q.val}</p>
                                    <p>{formatMoney(stock.sellingPrice)}</p>
                            </OptItemInfo>
                                <Divider />
                        </SearchListItemCont >
                        ))
                    }
                     </>
                }
                </SearchListCont>
        </SearchOptCont>
    )
}

export default Search