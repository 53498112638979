import styled from "styled-components";
export const HeaderMainCont = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
`
export const HeaderCont = styled.div.attrs({
  className: 'container'
})`
  height: 100%;
  max-width: 1000px;
  padding:0px 5px;
`
export const FilterCont = styled.div`
  display:flex;
  align-items: center;
  justify-content: end;
  @media (max-width: 768px) {
    display:none;
  }
`
export const HeaderListItems = styled.ul`
  width: 100%;
  height: 60px;
  margin: auto;
  list-style: none;
  align-items: center;
  justify-content: space-between;
  position: relative;
  background: white;
  padding: 0px;

    display: grid;
    align-items: center;
    grid-template-columns: 25% 50% 25%;

  .add--btn {
    border: 1px solid whitesmoke;
    border-radius: 4px;
    margin-left: 10px;
    height: 30px;
    width: 30px;
  }
  h6 {
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 0px;
  }
  @media(max-width: 768px) {
     display: flex;
    h6, .add--btn {
      display: none;
    }
  }
`;
export const ItemCont = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  border-radius: 6px;
  :hover {
    background: whitesmoke;
  }
  p {
    margin-bottom: 0px;
    font-size: 8px;
    color: grey;
  }
  #itm{
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    border-right: none;
  }
  .item:last-child {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
`
export const Item = styled.div<any>`
  height: 100%;
  padding: 0px 10px;
  cursor: pointer;
  z-index: 100000;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: space-between;
  color: #2d2c2c;
  overflow: visible;
  border: 1px solid whitesmoke;
  border-radius: 6px;
  height: 30px;
  width: 50px;
   h6 {
      margin-bottom: 0px;
      font-size: 10px;
      position: relative;
      font-weight: 700;
  }
   #cheveron {
        width: 11px;
        height: 11px;
        svg {
          stroke: #2d2c2c;
        }
     }
      #group {
        width: 14px;
        height: 14px;
        svg {
          stroke: #2d2c2c;
        }
     }
  &:hover {
    color: #012009;
  }
`;

export const TabLinksCont = styled.div`
width: 100%;
height: 40px;
display: flex;
align-items: center;
justify-content: center;

`
export const TabLinks = styled.ul`
list-style: none;
padding: 0px;
height:100%;
display: flex;
align-items: center;
justify-content: center;
margin: 0px;
border-radius: 6px;
/* background: whitesmoke; */

`
export const TabLink = styled.li<any>`
 height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 6px;
  a {
    text-decoration: none;
    font-size: 10.5px;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
    text-transform: uppercase;
    color: ${props => props.active ? 'black' : '#989797'};
    &:hover {
      color: #012009;
    }
    ::before {
    content: "";
    bottom: 0;
    left: 0;
    height: 2px;
    width: 100%;
    position: absolute;
    border-radius: 5px;
    background: ${props => props.active ? 'black' : 'transparent'};
  }
  }
`