import { useLocation } from "react-router-dom";
import { Divider } from "../../../../components/icons/styles";
import { MenuOptionsList, Option } from "./styles";

export const MoreActions = (props: any) => {
    let { actions, rt, closeMenuCallback } = props
    
    const location = useLocation();
    let allowedActions: any = []

    actions.forEach((action: any) => {
        if (action.permitted)
            allowedActions.push(action)
        }
    )

    return (
        <MenuOptionsList 
            {...props}
            onClick={() => closeMenuCallback()} 
            rt={rt} onMouseLeave={() => closeMenuCallback()} 
        >
            {
                actions.map((action: any, i: number) => (
                    <Option key={i} onClick={action.callback}>
                        <p style={{ display:'block' }} className="lbl">{action.label}</p>
                        {/* <Divider /> */}
                        {i < actions.length && <Divider />}
                    </Option>
            ))
            }
            {/* {
                <Option onClick={(e: any) => sharingCallback(e)}>
                    <p>Share</p>
                </Option>
            } */}
        </MenuOptionsList>
    )
}