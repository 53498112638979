import { Scale } from "chart.js";
import styled from "styled-components";

export const Container = styled.div<any>`
  height: 300px;
  width: 100%;
  position: absolute;
 display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  background: white;
  background-color: rgb(255, 255, 255);
  position: absolute;
  transition: all .15s ease-in-out;
  /* left: ${props => props.in ? 110 : 0}%; */
`;


export const DpCont = styled.div<any>`
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  background: white;
  background-color: rgb(255, 255, 255);
  position: absolute;
  transition: all .15s ease-out;
  left: ${props => props.in ? 0 : -110}%;
  .error {
    color: #ff000089;
    font-size: 12px;
    width: 100%;
    text-align: center;
    bottom: 80px;
    position: absolute;
  }
`;
export const InfoCont = styled.div<any>`
  left: -26px;
  top: -50px;
  z-index: 1000;
  height: 360px;
  width: 240px;
  padding-top: 15px;
  position: absolute;
  display: flex;
  align-items: center;
  border-radius: 10px;
  justify-content: start;
  flex-direction: column;
  transition: all .15s ease-in-out;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgba(71, 75, 76, 0.055);
  box-shadow: rgba(196, 195, 195, 0.216) 0px 5px 25px;
  transform: scale(${props => props.show ? 1 : 0});
  #close {
    height: 12px;
    width: 12px;
    svg {
      stroke: grey;
    }
    position: absolute;
    top: 10px; right: 10px;
    cursor: pointer;
  }
`;

export const DpImageCont = styled.div<any>`
  width: 220px;
  height: 220px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 20px;
  cursor: pointer;
  margin: 0px auto;
  h4 {
    text-align: center;
    max-width: 100%;
    margin-bottom: 5px;
    margin-top: 10px;
    font-size: 16px;
    font-weight: 600;
  }
  .username,.role {
    margin-top: -4px;
    margin-bottom: 3px;
    max-width: 100%;
    text-align: center;
    color: rgb(83, 100, 113);
    font-size: 12px;
  }
  .role {
    font-size: 13px;
    /* margin-bottom: 10px !important; */
  }
`

export const ContactsCont = styled.div`
  width: 100%;
  height: 40px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
`;
export const ContactsItem = styled.div`
  height: 100%;
  width: auto;
  padding: 0px 3px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  .icon {
    width: 14px;
    height: 14px;
    margin-right: 2px;
  }
  #date {
    width: 16px;
    height: 16px;
  }
  p {
    font-size: 13px;
    margin-bottom: 0px;
    color: rgb(83, 100, 113);
    span {
      color: rgb(83, 100, 113);
      font-weight: normal;
    }
  }
  &:first-child {
    /* margin-right: 5px; */
  }
`;

export const ImageCont = styled.div`
  width:150px;
  height: 150px;
  margin: auto;
  border: none;
  position: relative;
  border-radius: 50%;
  background: #e3e3e3;
  img {
    height: 100%;
    width: 100%;
    border-radius: inherit;
    object-fit: cover;
    border: none;
    mix-blend-mode: multiply;
    /* filter: contrast(1); */
  }
`;


export const SubInfo = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    p {
        margin-bottom: 0px;
        font-size: 13px;
        font-weight: 700;
        display: flex;
        color: #646464;
        span {
            font-size: 10px;
            .icon {
                width: 18px;
                width: 18px;
                margin-right: 3px;
            }
        }
    }
    p:last-child {
        margin-left: 3px;
    }
`

export const ButtonsCont = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 45px;
  gap: 0px 7px;
  /* margin-top: 30px; */
  position: absolute;
  padding: 0px 10px;
  bottom: 15px;
`;
export const MenuOptionsList = styled.ul<any>`
  font-size: 12px;
  position: absolute;
  overflow: hidden;
  padding: 0px 0px;
  right: -50%;
  z-index: 50;
  min-width: 150px;
  top: -80%;
  color: ${props => props.theme.light.colors.labels.pri};
  background-color: ${props => props.theme.light.colors.backgrounds.base.pri};
  border: 1px solid ${props => props.theme.light.colors.separators.pri};
  border-radius: 10px;
  box-shadow: 0 5px 25px #c4c3c337;
  transition: all .15s ease-in-out;
  transform: scaleY(${props => props.show ? 1 : 0});
`
export const Option = styled.li<any>`
  height: 35px;
  width: 100%;
  display: flex;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  position: relative;
  p {
    margin-bottom: 0px;
    font-size: 12px;
     padding: 0px 10px;
  }
  :hover {
    background-color: ${ props => props.delete ? '#f6050520' : 'rgb(247 247 247)'} ;
    color: ${ props => props.delete ? '#f60505de' : 'black'} ;
  }
`