import { useReactiveVar } from '@apollo/client'
import React, { useEffect, useState } from 'react'

import { queryOptions } from '../../types/defaults'
import MainHeader from './components/headers/main'
import {Main } from './styles'
import ChatBox from '../../components/chat'
import { Col, Container, Row } from 'react-bootstrap'
import { Sales } from '../../components/cards/charts/sales'
import { Tracks } from '../../components/cards/charts/tracks'
import { Status } from '../../components/cards/charts/status'
import { Tops } from '../../components/cards/charts/topten'
import { Visits } from '../../components/cards/charts/visits'
import { Performance } from '../../components/cards/charts/performance'
import SideNavigator from '../../components/sidenav'

const Analytics = (props: any) => {

  return (
    <>
      <MainHeader />
      <Main>
        <Container className='wrap'>
          <Row className='flex-wrap'>
            <Col xs={12} md={6} lg={4}>
              <Sales />
            </Col>
            <Col xs={12} md={6} lg={4}>
              <Tops />
            </Col>
            <Col xs={12} md={6} lg={4}>
              <Performance />
            </Col>
            <Col xs={12} md={6} lg={4}>
              <Visits />
            </Col>
            
            <Col xs={12} md={6} lg={4}>
              <Tracks />
            </Col>
            <Col xs={12} md={6} lg={4}>
              <Status />
            </Col>
            
          </Row>
        </Container>
          
            {/* <BarCard>
              <SalesExpenseChart />
            </BarCard>
            <ChartCard>
              <StaffsChart />
            </ChartCard>
          <ChartCard>
            <TrendsChart />
          </ChartCard>
          <ChartCard>
            <ProfitChart />
          </ChartCard>
          <ChartCard style={{ paddingTop: 0 }}>
            <ProductStatus />
          </ChartCard>
          <ChartCard style={{ paddingTop:0 }}>
            <ChartHeader />
          </ChartCard> */}
        {/* </Container> */}
        {/* <ChatBox /> */}
        <SideNavigator />
      </Main>
     
    </>
  )
}

export default Analytics