import { useMutation, useReactiveVar } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import { BriefCase, Calender, Cancel, Chevron, Drop, HidePass, Phone, PointedArror, ShowPass } from '../../../../../components/icons'
import { Icon } from '../../../../../components/icons/styles'
import { globalUser, showStaffModal } from '../../../../../globals'
import { SAVE_STAFF_INFO } from '../../../../../graphql/mutations/staff.mutation'
import { GET_STAFF } from '../../../../../graphql/queries'
import { Account } from '../../../../../models/account'
import Button from '../button'
import { NextBtn } from '../button/styles'
import Preview from '../view'
import InfoView from '../view'
import { Input } from './inputs'
import { ContactsCont, ContactsItem, DpCont, DpImageCont, FormGroup, FormGroupCont, FormItem, HeaderCont, ImageCont, InputsCont, SwitchCont } from './styles'

const StaffForm = (props: any) => {

    const { addresses, editIndex } = props

    const initCreds: any = {
        name: '',
        phone: '',
        username: '',
        password: '',
        dp: '',
        role: '',
    }

    const [creds, setCreds] = useState(initCreds);

    const [focused, setFocused] = useState('')

    const [cancel, setCancel] = useState('')
    const [type, setType] = useState('password')


    const [view, setView] = useState('form')
    const modal = useReactiveVar(showStaffModal)
    const user: Account = useReactiveVar(globalUser) as any;
    const onSwitchView = (view: string) => {
        setView(view);
    }

    const [submit, { error, loading, data }] = useMutation(SAVE_STAFF_INFO, {
        update: (cache, { data: {saveAddress} }) => {
            let myAccount:any = cache.readQuery({
                query: GET_STAFF
            })


            // cache.writeQuery({
            //     query: GET_MY_ACCOUNT,
            //     data: {
            //         ...myAccount,
            //         addresses: [...saveAddress]
            //     }
            // })
        }
    })
    if (error) console.log({ ...error });

    const handleChange = (e: any) => {
        e.persist();
        setCreds({
            ...creds,
            [e.target.name]: e.target.value
        })
    }

    const handleSubmit = (e: any) => {
        e.preventDefault();
        submit({
            variables: {
                info: {
                    ...creds,
                    // msgToken: locals().msgToken
                }
            }
        })
    }

    const switchType = () => {
        setType(type === 'password' ? 'text' : 'password')
    }

    const clearInput = (name: string) => {
        setCreds({
            ...creds,
            [name]: ''
        })
    }
    const switchView = (e: any,name: string) => {
        e.preventDefault()
        setView(name)
    }
    const focus = (name: string) => {
        setFocused(name)
    }
    const mouseEnter = () => {
        setCancel('address');
    }
    const mouseLeave = () => {
        setCancel('')
    }
    return (
        <FormItem show={modal} onSubmit={(e: React.SyntheticEvent) => handleSubmit(e)}>
            <SwitchCont>
                <HeaderCont>
                   <h6 onClick={()=>setView('form')}>
                        {
                           view ==='dp' && 
                           <span>
                                <Icon rot={90} onClick={() => setView('form')}>
                                    <Chevron />
                                </Icon>
                            </span>
                        }
                        New Staff
                    </h6>
                    <Icon onClick={() => showStaffModal(false)}>
                      <Cancel />
                   </Icon> 
               </HeaderCont>
                <InputsCont in={view === 'form'}>
                    <FormGroupCont>
                        <FormGroup 
                            top 
                            focused={focused === 'name'}
                            onMouseLeave={() => mouseLeave()} 
                            onMouseEnter={() => mouseEnter()}>
                            <Input
                                required
                                name='name'
                                label='full name'
                                placeholder='Full name'
                                value={creds.name}
                                focused={focused === 'name'}
                                changeCallback={(e: any) => handleChange(e)}
                                focusedCallback={(name: string) => focus(name)}
                            />
                            {
                                (cancel === 'name') &&
                                <Icon onClick={() => clearInput('name')}>
                                    <Cancel />
                                </Icon>
                            }
                        </FormGroup>
                        <FormGroup 
                            focused={focused === 'phone'} 
                            onMouseLeave={() => mouseLeave()} 
                            onMouseEnter={() => mouseEnter()}>
                            <Input
                                name='phone'
                                label='phone number'
                                placeholder='0801234565789'
                                value={creds.phone}
                                focused={focused === 'phone'}
                                changeCallback={(e: any) => handleChange(e)}
                                focusedCallback={(name: string) => focus(name)}
                            />
                            {
                                (cancel === 'phone') &&
                                <Icon onClick={() => clearInput('phone')}>
                                    <Cancel />
                                </Icon>
                            }
                        </FormGroup>
                    </FormGroupCont>

                    <FormGroupCont>
                        <FormGroup focused={focused === 'role'}>
                            <Input
                                name='role'
                                label='Role'
                                placeholder='Manager'
                                value={creds.role}
                                focused={focused === 'role'}
                                changeCallback={(e: any) => handleChange(e)}
                                focusedCallback={(name: string) => focus(name)}
                            /> {
                                <Icon id='drop'>
                                    <Drop />
                                </Icon>
                            }
                        </FormGroup>
                    </FormGroupCont>
                    
                    <FormGroupCont>
                        <FormGroup focused={focused === 'username'} top>
                            <Input
                                required
                                name='username'
                                label='username'
                                placeholder='@yourusername'
                                value={creds.username}
                                focused={focused === 'username'}
                                changeCallback={(e: any) => handleChange(e)}
                                focusedCallback={(name: string) => focus(name)}
                            />
                            {
                                (cancel === 'username') &&
                                <Icon onClick={() => clearInput('username')}>
                                    <Cancel />
                                </Icon>
                            }
                        </FormGroup>
                        <FormGroup focused={focused === 'password'}>
                            <Input
                                required
                                type={type}
                                name='password'
                                label='password'
                                placeholder='your state'
                                value={creds.password}
                                focused={focused === 'password'}
                                changeCallback={(e: any) => handleChange(e)}
                                focusedCallback={(name: string) => focus(name)}
                            />
                            <Icon onClick={() => switchType()} id='pwd'> {
                                type === 'password' ? <HidePass /> : <ShowPass />
                            }
                            </Icon>
                        </FormGroup>
                    </FormGroupCont>
                    <NextBtn
                        title='Continue'
                        onClick={(e: any) => switchView(e,'dp')}
                        disabled={!creds.username || !creds.password || !creds.name ||!creds.phone}
                    >
                        Continue
                    </NextBtn>
                </InputsCont> 
                <Preview data={data} error={error} loading={loading} view={view} creds={creds}  />
            </SwitchCont>
        </FormItem>
    )
}

export default StaffForm