import styled from "styled-components";

export const Main = styled.main`
    width: 100%;
    height: 100vh;
    position: relative;
    background: #ffffff;
    @media(max-width: 768px) {
      height: 100vh;
      padding-top: 50px;
    }
`
export const Container = styled.div.attrs({
    className: "container"
})`
    padding-top: 170px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: start;
    position: relative;
    max-width: 1150px;
    height: 100%;
    @media(max-width: 420px) {
      max-width: 100%;
      height: 100%;
      padding-left: 5px;
      padding-right: 5px;
      margin: auto;
      padding-top: 150px;
      justify-content: space-between;
    }
    `
export const Feed = styled.section`
    display: flex;
    flex-wrap: wrap;
    position: relative;
    @media (max-width: 768) {
        columns: 165px;
        column-gap: 5px;
  }
`
export const ProductGroupCont = styled.section`
  width: 100%;
  margin: 20px 0px 10px 0px;
  position: relative;
  display: flex;
  flex-direction: column;
  h4 {
    margin-bottom: 0px;
    font-size: 20px;
    font-weight: 700;
  }
`
export const ProductGroup = styled.div`
  width: 100%;
  margin:  0px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
`