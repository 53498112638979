import React, { useState } from 'react'
import TableHeader from '../tablex'
import SearchForm from '../../forms'

import { FormCont } from '../../forms/styles'
import { Cart, Chevron, Filter, Group, Plus, User } from '../../../../../components/icons'
import { Divider, Icon } from '../../../../../components/icons/styles'
import { Action, CatCont, FilterCont, Header, HeaderCont, HeaderMenuCont, IconsGroup, Item, TableActionsCont } from './styles'
import Actions from './menu'
import { queryOptions } from '../../../../../types/defaults'
import { useReactiveVar } from '@apollo/client'
import { showInventoryModal } from '../../../../../globals'
// import NavListMenu from '../../topNavMenuList'
import CatHeader from '../table'
import CartIcon from '../../../../inventory/components/buttons/cart'
import { NavLink } from 'react-router-dom'
import NavListMenu from '../../../../../components/topNavMenuList'
const GROUP_OPTION = [
    'PAY METHOD', 'CUSTOMER', 'DATE MODIFIED'
]
const FILTER_OPTION = [
    'ALL',
    'PENDINGS',
]

const MainHeader = (props: any) => {
    // const {setQueryCallback} = props
    const [menu, setMenu] = useState(false)

    const [active, setActive] = useState('')

    const { group, filter } = useReactiveVar(queryOptions)

    return (
        <Header>
            <HeaderCont>
                <FormCont>
                    <NavLink to='/'>
                        <h2>Stosyst</h2>
                    </NavLink>
                    <FilterCont>
                        <Item>
                            <h6 onClick={() => setActive('group')}>GROUP
                                <span>
                                    <Icon id='cheveron'>
                                        <Chevron />
                                    </Icon>
                                </span>
                            </h6>
                            {active === 'group' && <Actions closeCallback={() => setActive('')} name='group' options={GROUP_OPTION} />}
                        </Item>
                        <Item>
                            <h6 onClick={() => setActive('filter')}>FILTER</h6>
                            {
                                active === 'filter' &&
                                <Actions
                                    name='filter'
                                    options={FILTER_OPTION}
                                    closeCallback={() => setActive('')}
                                />
                            }
                        </Item>
                    </FilterCont>
                    {/* <SearchForm /> */}
                    <HeaderMenuCont>
                        <IconsGroup
                            onClick={() => setMenu(!menu)}
                            onMouseEnter={() => setMenu(true)}
                        >
                            <Icon>
                                <User />
                            </Icon>
                            <Icon id='cheveron'>
                                <Chevron />
                            </Icon>
                        </IconsGroup>
                        {/* <CartIcon /> */}
                        {menu && <NavListMenu hideMenuCallback={setMenu} />}
                    </HeaderMenuCont>
                </FormCont>
            </HeaderCont>
            <CatCont>
                <CatHeader />
                <Divider />
            </CatCont>
            <TableHeader />
        </Header>
    )
}

export default MainHeader