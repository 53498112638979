import styled from "styled-components";


export const Main = styled.main`
    width: 100%;
    height: 100%;
    position: relative;
`
export const Container = styled.div.attrs({
  className: "container"
})`
    padding-top: 160px;
    @media(max-width: 768px) {
    padding-left: 5px;
    padding-right: 5px;
    max-width: 100%;
    .prod--link {
      color: inherit;
      margin: 0px 0px;
      padding: 0px 0px;
      width: 49%;
  }
}
`
export const Column = styled.a.attrs({
  className: "column col-lg-3 col-md-4 col-sm-12 col-xs-12"
})`
border: none;
border-radius: 14px;
position:relative;
cursor: pointer;
overflow: hidden;
text-decoration:none;
color: inherit;
padding: 0px 5px;
margin-bottom: 10px;
&:hover {
   color: inherit;
}
@media(max-width: 768px) {
  padding: 0px;
}
`
export const Card = styled.div`
height: 270px;
display: flex;
flex-direction: column;
background: #f5f5f5; 
position:relative;
border-radius: 14px;
cursor: pointer;
overflow: hidden;
padding: 14px 14px;
a {
  margin-bottom: 0px;
  color: inherit;
  text-decoration: none;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  :hover {
    text-decoration: underline;
  }
}
h6 {
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 0px;
}
p {
  margin-bottom: 0px;
  font-size: 12px;
}
.username {
  font-size: 12px;
  color:  grey;
  margin-bottom: 5px;
}
.address {
  font-size: 14px;
    width: 98%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.footer {
  position: absolute;
  bottom: 8px;
  left: 10px;
  ::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #04a804;
  }
  span {
    left: 12px;
    bottom: 4px;
    position: relative;

  }
}
@media(max-width: 768px) {
  padding: 10px 10px;
  width: 100%;
  background: white; 
}

`
export const CardHeader = styled.div`
width: 100%;
height: auto;
align-items: center;
justify-content: space-between;
display: flex;
position:relative;
@media(max-width: 768px) {
  padding: 0px;
}
`
export const NumbersCont = styled.div`
width: 100%;
height: auto;
align-items: center;
display: flex;
position:relative;
margin-top: 14px;
@media(max-width: 768px) {
  padding: 0px;
}
`
export const Numbers = styled.div`
height: auto;
align-items: center;
justify-content: space-between;
display: flex;
position:relative;
margin-right: 10px;
h4 {
  margin-bottom: 0px;
  font-size: 14px;
  font-weight: 600;
  span {
    font-size: 14px;
    font-weight: normal;
    color: grey;
  }
}
@media(max-width: 768px) {
  padding: 0px;
}
`


export const ImageCont = styled.div`
border: none;
position:relative;
border-radius: 30px;
width: 60px;
height:60px;
margin-bottom: 8px;
overflow:hidden;
background: whitesmoke;
img {
    height: 100%;
    width: 100%;
    border-radius: inherit;
    object-fit: cover;
    border: none;
}
@media (max-width: 768px) {
 
  
}
/* @media(max-width: 390px) {
  width: 180px;
  padding: 0px;
} */

@media(min-width: 421px) and (max-width: 768px) {
 width: 100%;
  padding: 0px;
}
`

export const AddToCartButton = styled.div<any>`
   display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  background:#05050528;
  position: absolute;
  bottom:70px;
  right: 10px;
  border-radius: 50%;
  color: white;
  .icon {
    width: 24px;
    height: 24px;
     svg {
      fill: ${props => props.selected ? '#023B02' : 'white'};
    }
  }
  :hover {
    background:#0505053c;
  }
`



