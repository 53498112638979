import styled from "styled-components";

export const Main = styled.main`
    width: 100%;
    height: 100vh;
    display: grid;
    grid-template-columns: 70% 1fr;
`
export const MainContainer = styled.div`
   display: flex;
   flex-direction: column;
   padding: 100px 250px;
   box-sizing: border-box;
   .step {
    font-size: 12px;
    color: grey;
    margin-bottom:10px;
   }
   h2 {
    font-size: 32px;
    font-weight: 700;
    line-height: 38px;
    margin-bottom: 15px;
   }
   h6 {
    font-size: 18px;
    font-weight: normal;
    color: grey;
    margin-bottom: 45px;
   }
`
export const PromoContainer = styled.div`
   display: flex;
   align-items: center;
   justify-content: center;
   flex-direction: column;
   background: whitesmoke;
`
export const ProfileContainer = styled.div`
  position: relative;
   width: 100%;
`
export const FormItem = styled.form`
  width: 100%;
  min-height: 200px;
  margin-bottom: 50px;
  position: relative;
  overflow: hidden;
   .error {
    color: #ff0000b6;
    font-size: 12px;
    width: 100%;
    text-align: center;
    bottom: 50px;
    position: absolute;
  }
`;
export const FormGroupCont = styled.div<any>`
  margin: 8px 0px;
  position: relative;
  border-radius: 8px;
  width: ${(props) => props.w || 100}%;
  border: 1px solid rgb(0 0 0 / 6%);
`;

export const ButtonsCont = styled.div<any>`
    width: 100%;
    display: flex;
    justify-content: end;
    position: relative;
`;

export const Button = styled.button<any>`
  height: 45px;
  width: 100px;
  border-radius: 30px;
  position: relative;
  display: flex;
  bottom: 0;
  right: 0;
  z-index:150;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  font-size: 12px;
  color: #ededed;
  font-weight: 600;
  background: #373636;
  &:hover {
    border: none;
    background-color: #151616;
  }
  :hover {
    svg {
      stroke: "white";
    }
  }
  &:focus {
    border: none;
  }
`;
export const ChooseDpButton = styled.button<any>`
  height: 45px;
  width: 150px;
  border-radius: 30px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  font-size: 12px;
  color: #393838;
  border: 1px solid #b9b9b9;
  font-weight: 600;
  background: #ffffff;
  cursor: pointer;
  &:hover {
     cursor: pointer;
    border: 1px solid #6a6969;
    background-color: initial;
  }
  input {
     cursor: pointer;
  }
  :hover {
    svg {
      stroke: "white";
    }
  }
  &:focus {
    border: none;
  }
`;

export const DPCont = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: start;
`
export const Section = styled.div<any>`
    width: 100%;
    display: ${props=>props.show  ? 'flex' : 'none'};
    flex-direction: column;
    align-items: start;
    h4 {
        font-size: 22px;
        font-weight: bold;
         margin-bottom: 5px;
    }
    p {
      margin-bottom: 20px;
      font-size: 13px;
    }
`
export const StoreGroup = styled.div`
    width: 100%;
    display: flex;
    align-items: start;
    justify-content: start;
    flex-wrap: wrap;
`
export const Store = styled.div`
    width: 190px;
    .header-cont {
        display:flex;
        justify-content: space-between;
        margin-bottom:  7px;
    }
    h6 {
        color: #0f0f0f;
        font-size: 14px;
        margin-bottom: 3px;
    }
    p {
        font-size: 13px;
        color: grey;
    }
    &:first-child {
      margin-right: 10px;
    }
`
export const SoreLogo = styled.div`
  border: none;
  position: relative;
  width: 100px;
  height: 100px;
  overflow: hidden;
  border-radius: 50%;
  background: whitesmoke;
  img {
    height: 100%;
    width: 100%;
    border-radius: inherit;
    object-fit: cover;
    border: none;
    mix-blend-mode: multiply;
    filter: contrast(1);
    vertical-align: top center;
  }
`;
export const FollowButton = styled.button<any>`
  height: 45px;
  width: 70px;
  border-radius: 30px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  font-size: 12px;
  color: #393838;
  border: 1px solid #b9b9b9;
  font-weight: 600;
  background: #ffffff;
  &:hover {
    border: none;
    background-color: #1c1d1d;
    color: #ffffff;
  }
  :hover {
    svg {
      stroke: "white";
    }
  }
`;
export const Back = styled(FollowButton)<any>`
  margin-right: 15px;
   &:hover {
    border: 1px solid #9c9a9a;
    background-color: initial;
    color: #2d2d2d;
  }
`;

export const RadioGroup = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
`
export const Radio = styled.div<any>`
    height: 140px;
    width: 200px;
    background: ${props => props.selected ? '#419af933' : 'whitesmoke'};
    border-radius: 4px;
    cursor: pointer;

    padding: 15px;
    h6 {
        font-size: 16px;
        margin-bottom: 15px;
        color: ${props => props.selected ? '#419bf9' : 'black'};
        font-weight: 500;
    }
    p {
        margin-bottom: 0px;
        color: ${props => props.selected ? '#419bf9' : 'grey'};
        font-size: 14px;
        line-height: 1.4;
    }
   &:first-child {
    margin-right: 10px;
   }

`
export const DPControls = styled.div`
    padding-top: 10px;
    display: flex;
     height: 165px;
    left: 20px;
     position: relative;
    flex-direction: column;
    justify-content: space-between;
    .choose {
        margin-bottom: 0px;
        font-size: 14px;
        position: relative;
        top: 10px;
    }
`
export const CustomerLogo = styled.div`
  border: none;
  position: relative;
  width: 160px;
  height: 160px;
  overflow: hidden;
  border-radius: 50%;
  background: whitesmoke;
  img {
    height: 100%;
    width: 100%;
    border-radius: inherit;
    object-fit: cover;
    border: none;
    mix-blend-mode: multiply;
    filter: contrast(1);
    vertical-align: top center;
  }
`;

export const ColorOptions = styled.ul`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: start;
    padding: 0px;
    list-style: none;
`;
export const Color = styled.li`
    width: 40px;
    height: 40px;
    margin-right: 5px;
    border-radius: 50%;
    background: hsl(120, 92.00000000000003%, 85.29411764705883%);
`;
