import React from 'react'
import { FormContainer, SectionCont, SectionHeader, Setting, SettingItem, SettingsCont } from './styles'
import { Divider, Icon } from '../../../components/icons/styles'
import Form from '../address/components/forms'
import { useQuery, useReactiveVar } from '@apollo/client'
import { showAccModal } from '../../../globals'
import { GET_MY_ACCOUNT } from '../../../graphql/queries'
import { PageLoader } from '../../../components/loaders'
import { Pencil } from '../../../components/icons'
import { CreditCard } from '../../../models/account'

const Subscription = () => {

    const show = useReactiveVar(showAccModal)

    const { loading, data, refetch, error } = useQuery(GET_MY_ACCOUNT, {
        fetchPolicy: "network-only"
    })

    if (error) {
        console.log({...error});
    }

    return (
        <SectionCont> {
            data ?
                <>
                    <SectionHeader>Subscription<span onClick={() => (showAccModal('address'))}>+</span></SectionHeader>
                    <SettingsCont>
                                <>
                                    <SettingItem>
                                        <Setting>
                                            <p>Premium</p>
                                            <p>Due: Dec 17</p>
                                        </Setting>
                                        <p>
                                            <span>
                                                <Icon>
                                                    <Pencil />
                                                </Icon>
                                            </span>
                                        </p>
                                    </SettingItem>
                                </>
                        
                    
                     <Divider />
                    </SettingsCont>
                    <SectionHeader>Payment<span onClick={() => (showAccModal('payment'))}>+</span></SectionHeader>
                    <SettingsCont> {
                        data.myAccount.cards.map((card: CreditCard) => (
                                <>
                                    <SettingItem>
                                        <Setting>
                                            <p>{card.name}</p>
                                            <p>{`********${card.cardNumber.toString().slice(-4)}`} </p>
                                        </Setting>
                                        <p>
                                            <span>
                                                <Icon>
                                                    <Pencil />
                                                </Icon>
                                            </span>
                                        </p>
                                    </SettingItem>
                                </>
                        ))
                    }
                     <Divider />
                        <FormContainer show={show==='payment'}>
                            {/* <CreditCardForm /> */}
                        </FormContainer>
                    </SettingsCont>
                </>
                : loading ?
                <PageLoader />
                :
                <div style={{ textAlign: "center", height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <p>Something went wrong</p>
                </div>

        }

        </SectionCont>
    )
}

export default Subscription