import React, { ReactNode, SyntheticEvent, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Cancel, CheveronUpDown, Ellipsis, Time } from '../../../../components/icons'
import { Icon, IconBtn } from '../../../../components/icons/styles'
import { invCriteria, queryOptions } from '../../../../types/defaults'
import { Search } from '../../../explore/components/icons'
import { FilterContainer, FormListItemsCont, Form, Input, SearchSuggestions, Suggestions, MenuOptionsList, Option } from './styles'
import { Link } from 'react-router-dom'



const SearchForm = (props: any) => {
    const [focus, setFocus] = useState(false)
    const [query, setQuery] = useState('')
    const [queries, setQueries] = useState<any>([])
    const [showMenu, setShowMenu] = useState<any>(false)

    const loc = useLocation()

    const {pathname} = useLocation();
    const params = new URLSearchParams(loc.search)
    
    const getSearchedTerms = async () => {
        try {
            let qs: any = await localStorage.getItem('queries');
            if (!qs)  {
                
            } else {
                qs = JSON.parse(qs)?.filter((item: any) => item.page === pathname);
            }
           
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getSearchedTerms();
        // setShowSuggestions(searchModal)
    }, [focus]);


    const updateQueryHist = async (queries: any[]) => {
        try {
            await localStorage.setItem('queries', JSON.stringify(queries));
        }
        catch (err) {
            console.log(err);
        }
    };

    const handleChange = (e: any) => {

        const {value} = e.target
        setQuery(value)
        setFocus(false)
        // queryOptions({...queryOptions(), query: value})

        pathname === '/invoice' || pathname === '/invoices' ?
            invCriteria({
                 ...invCriteria(), 
                 filter: '', 
                 query: value 
            }) 
            :
            queryOptions({
                 ...queryOptions(), 
                 query: value 
            })
    }

    const handleSelection = (q: string) => {
        setQuery(q)
        let qs = queries
        qs = qs.map((item: any) => item.query === q ?
            ({
                 ...item, 
                 dateAdded: new Date().getTime()
            }) 
            : 
            item
        )
        setQueries([...qs]);
        updateQueryHist(qs)

        pathname === '/invoice' || pathname === '/invoices' ?
        invCriteria({ 
            ...invCriteria(), 
            filter: '',
            query: q 
        }) 
        :
        queryOptions({
             ...queryOptions(), 
             query: q 
        })

        setFocus(false)
        // handleFocus(false)
        // page === 'expenses' ? expenseCriteria({ ...expCrt, query: suggestion })
        //     : page === 'invoice' ? invCriteria({ ...invCrt, filter: '', query: suggestion })
        //         : groupingCriteria({ ...criteria, query: suggestion, filter: '' })
    }


    function handleClear() {
        if (query.trim()) {
            const i = queries.findIndex((item: any) => item.query === query);
            if (i === -1) {
                queries.unshift({
                    query,
                    page:pathname,
                    dateAdded: new Date().getTime(),
                });
            } else {
                queries[i] = {
                    ...queries[i],
                    dateAdded: new Date().getTime()
                };
            }
            updateQueryHist(queries)
            setQuery('');

            pathname === '/invoice' || pathname === '/invoices' ?
            invCriteria({ ...invCriteria(), filter: '', query: '' }) :
            queryOptions({ ...queryOptions(), query: '' })
            // queryOptions({ ...queryOptions(), query: '' })
            setFocus(false)

        }

    }

    const selectCategory = (e: SyntheticEvent)=>{
        e.stopPropagation();
        setShowMenu(false)
    }

    return (
        <FormListItemsCont >
            <Form 
                onSubmit={(e:SyntheticEvent)=>e.preventDefault()} 
                focus={focus} {...props}>
                <Icon>
                    <Search />
                </Icon>
                <Input 
                    value={query}
                    autoComplete='off'
                    placeholder='Search...'
                    // onBlur={() => setFocus(false)}
                    // onFocus={()=>setFocus(true)} 
                    onChange={(e: SyntheticEvent)=>handleChange(e)}
                />
            </Form> 
        </FormListItemsCont>
    )
}

export default SearchForm