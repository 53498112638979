import { useQuery, useReactiveVar } from '@apollo/client'
import React, { useEffect, useState } from 'react'

import { queryOptions } from '../../types/defaults'
import SideBar from '../explore/components/sidebar'
import MainHeader from './components/headers/main'
import StockList from './components/list/shown'
import EmptyState from './components/pagestate/empty'
import ErrorState from './components/pagestate/error'
import LoadingState from './components/pagestate/loader'
import { ListGroupCont } from './components/styles'
import { Container, Main, TableCont } from './styles'
import InfiniteScroll from "react-infinite-scroll-component";
import { LocalCheckOutModal } from '../../components/modals'
import StockModal from '../../components/modals/stock'
import { GET_SHOWN_STOCKS } from '../../graphql/queries/product.query'
import { ChatModal } from '../../components/modals/chat'
import CircularButton from './components/buttons/circular'
import ChatBox from '../../components/chat'
import { showInventoryModal, tableState } from '../../globals'
import { useLocation } from 'react-router-dom'
import { CheckOutOnlineModal } from '../../components/modals/onlineCheckout'

const ShownStocks = (props: any) => {

  const queries = useReactiveVar(queryOptions)

  const location = useLocation() as any
  const params = new URLSearchParams(location.search)

  let offset = 0;

  const { data, loading, error, fetchMore, refetch } = useQuery(GET_SHOWN_STOCKS, {
    variables: {
      p: params.get("p"),
      q: params.get("q"),
      offset,
      r: params.get("r"),
      s:queries.query
    },
    fetchPolicy: "network-only",
  })

  if (data) {
    offset = offset + 10;
  }

  if (error) console.log({...error})

  const fetchMoreData = () => {
    fetchMore({
      variables: {
        p: params.get("p"),
        q: params.get("q"),
        offset: data?.shownStocks?.length,
        r: params.get("r"),
        s: ''
      }
    })
  }
const openEditors = () => {
  tableState({
    item: null,
    state: 'editing'
  })
  showInventoryModal(true)
}
  
 
  /* <p className='window'>{windowSize.width}</p> */
  return (
    <>
      <MainHeader />
      <Main>
        <Container>
          {
            loading ?
              <LoadingState />
              :
              error ?
                <ErrorState retryCallback={refetch} />
                :
                !data.shownStocks.length ?
                  <EmptyState 
                    clickCallback={openEditors} 
                    message='No stocks so far'
                    btnLabel='Add'
                    subMessage='Stocks appears here when created'
                   />
                  :
                  <TableCont>
                    <ListGroupCont>
                      <InfiniteScroll
                        dataLength={data.shownStocks.length}
                        next={fetchMoreData}
                        hasMore={true}
                        loader={null}
                        style={{ overflow: 'visible' }}
                      >
                        {
                            <>
                              <StockList
                                {...props}
                                groupId={params.get("p")}
                                list={data.shownStocks}
                                // count={items.count}
                                // total={items.total}
                              />
                            </>
                        }
                      </InfiniteScroll>
                    </ListGroupCont>
                  </TableCont>
        }
        </Container>
        <StockModal />
        <LocalCheckOutModal />
        <CheckOutOnlineModal />
        <ChatBox />
        <CircularButton />
      </Main> 
    </>
  )
}

export default ShownStocks