import { useMutation } from '@apollo/client'
import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { Cancel, Google, HidePass, ShowPass } from '../../../../components/icons'
import { Icon } from '../../../../components/icons/styles'
import ErrorMessage from '../../../../Errors'
import { SIGN_IN, SIGN_IN_GOOGLE } from '../../../../graphql/mutations/account'


import Button from '../button'
import { Input } from '../inputs'
import { Container, ErrorLine, FormGroup, FormGroupCont, FormItem, FormOpt, TagLine } from './styles'
import { GoogleBtnCont } from '../../../signup/components/forms/styles'
import { GoogleBtn } from '../../../signup/components/button/styles'
import { useGoogleLogin } from '@react-oauth/google'

const Form = () => {
    const initCreds: any = {
        email: '',
        password: '',
        fcmToken: localStorage.getItem('FCM_TOKEN')
    }
    const [focused, setFocused] = useState('')
    const [cancel, setCancel] = useState('')
    const [type, setType] = useState('password')
    const [creds, setcreds] = useState(initCreds);
    const navigate = useNavigate();

    const focus = (name: string) => {
        setFocused(name)
    }
    const switchType = () => {
        setType(type === 'password' ? 'text' : 'password')
    }

    const storeUserInfo = ({ token, account }: any) => {
        localStorage.setItem('token', token);
        localStorage.setItem('account', JSON.stringify(account))
        navigate("/");
    }

    const [signIn, { error, loading, data }] = useMutation(SIGN_IN, {
        onCompleted({ signIn }: any) {
            if (signIn) {
                // console.log(`data:${JSON.stringify(signIn, null, 2)}`)
                storeUserInfo(signIn);
            }
           
            // const permisions: PermittedActions = JSON.parse(localStorage.getItem('perms') as any)
        }
    });

    const [signInGoogle, { error: e, loading: signing, data: d }] = useMutation(SIGN_IN_GOOGLE, {
        onCompleted({ signInGoogle }: any) {
            if (signInGoogle) {
                storeUserInfo(signInGoogle);
                // console.log(signInGoogle)
            }
        }
    });

    if (error) console.log({ ...error })
    // if (data) console.log(`d:${JSON.stringify(data, null, 2)}`)
       

    if (!loading && !error && data) {
        localStorage.setItem('token', data.signIn.token);
    }

    const handleClearInput = () => {
        setcreds({
            ...creds,
            // phone: ''
        })
    }
    const handleChange = (e: any) => {
        e.persist();
        setcreds({
            ...creds,
            [e.target.name]: e.target.value
        })
    }
    const handleSubmit = (e: any) => {
        e.preventDefault();
        signIn({
            variables: {
                creds: {
                    ...creds,
                }
            }
        })
    }

    const mouseEnter = (name: string) => {
        setCancel(name);
    }

    const mouseLeave = (name: string) => {
        setCancel(name);
    }
    const handleGoogleSignIn = (token: string) => {
        signInGoogle({
            variables: {
                token,
                fcmToken: localStorage.getItem('FCM_TOKEN')
            }
        })
    }

    const login = useGoogleLogin({
        onSuccess: res => handleGoogleSignIn(res.access_token),
        // onSuccess: res => console.log(res),
        onError: error => console.log(error),
        // onSuccess: res => handleGoogleSignUp(res.access_token)
    });

    return (
        <Container>
            <FormItem onSubmit={(e: React.SyntheticEvent) => handleSubmit(e) }>
                <h6>Sign in</h6>
                <FormGroupCont>
                    <FormGroup
                        top
                        onMouseLeave={() => mouseLeave('email')}
                        onMouseEnter={() => mouseEnter('email')}
                    >
                        <Input
                            value={creds.email}
                            name='email'
                            focused={focused === 'email'}
                            label='Email'
                            placeholder='user@example.com'
                            changeCallback={(e: any) => handleChange(e)}
                            focusedCallback={(name: string) => focus(name)}
                        />
                        {
                            (cancel === 'email') &&
                            <Icon onClick={handleClearInput}>
                                <Cancel />
                            </Icon>
                        }
                    </FormGroup>
                    <FormGroup
                        onMouseLeave={() => mouseLeave('password')}
                        onMouseEnter={() => mouseEnter('password')}
                    >
                        <Input
                            type={type}
                            name='password'
                            label='Password'
                            value={creds.password}
                            focused={focused === 'password'}
                            placeholder=' your account password'
                            changeCallback={(e: any) => handleChange(e)}
                            focusedCallback={(name: string) => focus(name)}
                        />
                        <Icon onClick={() => switchType()} id='pwd'> {
                            type === 'password' ? <HidePass /> : <ShowPass />
                        }
                        </Icon>
                    </FormGroup>
                </FormGroupCont>
                <FormOpt>
                    <p>Forgot Password?</p>
                </FormOpt>
                <Button 
                    loading={loading}
                    data={data}
                    title="Sign in" 
                    disabled={!creds.email || !creds.password} 
                />
                <ErrorLine>
                    {
                        error && <ErrorMessage error={error} />
                    }
                </ErrorLine>
              
            </FormItem>
            <div className='or'><p>OR</p></div>
            <GoogleBtnCont>
                <GoogleBtn onClick={() => login()}>
                    <Icon>
                        <Google />
                    </Icon>
                    Sign in with Google
                </GoogleBtn>
            </GoogleBtnCont>
              <TagLine>Don't have account? 
                    <span>
                        <NavLink to='/signup' title='Home' >Sign up</NavLink>
                    </span>
                </TagLine>
        </Container>
    )
}
export default Form