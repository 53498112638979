
import { gql } from '@apollo/client'

export const GET_INVOICES = gql`
    query getInvoices(
        $query: String, 
        $filter: String, 
        $group: String, 
        $offset: Int
    ) {
        invoices(query: $query,filter: $filter, group: $group, offset: $offset) {
            _id
            records {
                _id
                tid
                stocks {
                    _id
                    item {
                        _id
                        name
                        description
                        categories {
                            name
                            category
                        }
                        size {
                            val
                            unit
                        }
                        q {
                            val
                            unit
                        }
                        form
                        mediaUrls
                        sellingPrice
                        owner
                        expiry
                        added {
                            firstName
                            lastName
                            phone
                            email
                        }
                        modified {
                            firstName
                            lastName
                            phone
                            email
                        }
                        createdAt
                        updatedAt
                    }
                    booked
                    quantity
                    delivered
                }
                modifier
                customer {
                    firstName
                    lastName
                    phone
                    email
                    address
                }
                added {
                    firstName
                    lastName
                    phone
                    email
                }
                modified {
                    firstName
                    lastName
                    phone
                    email
                    address
                }
                recieved
                payable
                completed
                paymentMethod
                createdAt
                updatedAt
            }
            count
            total
        }
    }
`
export const GET_PAYMENTS = gql`
    query getPayments( $query: String, $filter: String, $group: String, $offset: Int){
        payments(query: $query,filter: $filter, group: $group, offset: $offset) {
            id
            count
            total
            group {
                _id {
                    day
                    month
                    year
                    paymentMethod 
                }
                subGroup {
                    _id
                    tid
                    stocks {
                        _id
                        item {
                            _id
                            name
                            description
                            categories {
                                name
                                category
                            }
                            size {
                                val
                                unit
                            }
                            q {
                                val
                                unit
                            }
                            form
                            mediaUrls
                            sellingPrice
                            owner
                            expiry
                           
                        }
                        quantity
                    }
                    modifier
                    customer {
                        firstName
                        lastName
                        phone
                        email
                        address
                    }
                    added {
                        firstName
                        lastName
                        phone
                        email
                    }
                    modified {
                        firstName
                        lastName
                        phone
                        email
                        address
                    }
                    recieved
                    completed
                    paymentMethod
                    subTotal
                    createdAt
                    updatedAt
                }
                count
                subGroupTotal
            }
            
        }
    }
`
export const GET_SHOWN_INVOICES = gql`
    query getShownInvoices( $p: String,  $q: String,$offset: Int, $s: String){
        shownInvoices(p: $p,q: $q,offset: $offset , s: $s) {
            _id
                _id
                tid
                stocks {
                    _id
                    item {
                        _id
                        name
                        description
                        categories {
                            name
                            category
                        }
                        size {
                            val
                            unit
                        }
                        q {
                            val
                            unit
                        }
                        form
                        mediaUrls
                        sellingPrice
                        owner
                        expiry
                        added {
                            firstName
                            lastName
                            phone
                            email
                        }
                        modified {
                            firstName
                            lastName
                            phone
                            email
                        }
                        createdAt
                        updatedAt
                    }
                    booked
                    quantity
                    delivered
                }
                modifier
                customer {
                    firstName
                    lastName
                    phone
                    email
                    address
                }
                added {
                    firstName
                    lastName
                    phone
                    email
                }
                modified {
                    firstName
                    lastName
                    phone
                    email
                    address
                }
                recieved
                payable
                completed
                paymentMethod
                createdAt
                updatedAt
        }
    }
`
export const SearchInvoices = gql`
    query searchInvoices($query: String) {
        searchInvoices(query: $query) {
            _id {
                day
                month
                year
            }
            records {
                _id
                tid
                stocks {
                    _id
                    item {
                        _id
                        name
                        description
                        categories {
                            name
                            category
                        }
                        size {
                            val
                            unit
                        }
                        q {
                            val
                            unit
                        }
                        form
                        mediaUrls
                        costPrice
                        sellingPrice
                        owner
                        expiry
                        added {
                            firstName
                            lastName
                            phone
                            email
                        }
                        modified {
                            firstName
                            lastName
                            phone
                            email
                        }
                        createdAt
                        updatedAt
                    }
                    booked
                    quantity
                    delivered
                }
                recieved
                payable
                modifier
                customer {
                    firstName
                    lastName
                    phone
                    email
                    address
                }
                added {
                    firstName
                    lastName
                    phone
                    email
                }
                modified {
                    firstName
                    lastName
                    phone
                    email
                }
                createdAt
                updatedAt
            }
        }
    }
`

