import styled from "styled-components";
export const SectionCont = styled.div`
    padding-top: 30px;
    width: 100%;
    position: relative;
    min-height: 100px;
`
export const SettingsCont = styled.div`
  width: 100%;
  min-height: 100px;
   h5 {
    width: 100%;
    margin-bottom: 0px;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 10px;
    span {
        font-size: 14px;
        color: #1ed760;
        right: 0px
    }
  }
  p {
    margin-bottom: 0px;
  }
  position: relative;
  width: min(100% - 40px);
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0px;
  }
`
export const SectionHeader = styled.h1`
    width: 100%;
    margin-bottom: 0px;
    font-size: 18px;
    font-weight: 700;
    padding-left: 20px;
    margin-bottom: 10px;
    position: relative;
    span {
        font-size: 20px;
        color: #1ed760;
        right: 20px;
        text-align: right;
        position: absolute;
         font-weight: 500;
         cursor: pointer;
    }
`
export const SettingGroup = styled.div`
  width: 100%;
  min-height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: space-between;
  p{
    font-size: 14px;
    color: #1ed760;
    cursor: pointer;
  }
`
export const SettingItem = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  p{
    font-size: 14px;
    color: #1ed760;
    cursor: pointer;
    margin-bottom: 0px;
  }
`
export const Setting = styled.div<any>`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: space-between;
  flex-direction: column;
  p{
    font-size: 14px;
    color: #222121;
     width: 100%;
  }
  p:last-child {
    color: ${props => props.active ? 'rgb(83,100,113)' : '#cecfcf'} ;
    position: relative;
  }
  .street {
    max-width: 50px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
`

