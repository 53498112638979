import styled from "styled-components";

export const HeaderCont = styled.header.attrs({
    className: "container",
})`
    display: flex;
    margin: auto;
    padding: 0px;
    align-items: center;
    height: 45px;
    width: min(100% - 15px);
    position: relative;
    justify-content: space-between;
    .icon {
        width: 32px;
        cursor: pointer;
    }

`
export const IconBtn = styled.button.attrs<any>({
   
}) <any>`
  height: 30px;
  width: 30px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  cursor: pointer;
  z-index: 1;
  position: relative;
  background: white;
  p {
    margin-bottom: 0px;
  }
  &:hover {
    border: none;
    background-color: #0b0b0b1d;
  }
`;