import React, { useState } from 'react'
import { Section, Back, Button, ButtonsCont, ChooseDpButton, Color, ColorOptions, CustomerLogo, DPCont, DPControls, FollowButton, FormGroupCont, FormItem, Main, MainContainer, ProfileContainer, PromoContainer, Radio, RadioGroup, SoreLogo, Store, StoreGroup } from './styles'
import { Input } from '../signin/components/inputs'
import { FormGroup } from '../signin/components/forms/styles'
import { UPLOAD_FILE } from '../../graphql/mutations'
import { useMutation } from '@apollo/client'
import { FileInput } from '../inventory/components/forms/stock/styles'
import { getImageUrl } from '../../apollo'
import { Loader } from '../../components/loaders'
import { ONBOARD } from '../../graphql/mutations/account'
import { useNavigate } from 'react-router-dom'

const Onboarding = () => {
    const [focused, setFocused] = useState('')
    const [nextCount, setNextCount] = useState(0)

    const [info, setInfo] = useState({
        name: '',
        accType: '',
        dp: '',
    })
    
    const navigate = useNavigate();

    const focus = (name: string) => {
        setFocused(name)
    }

    const handleChange = (e: any) => {
        e.persist();
        setInfo({
            ...info,
            [e.target.name]: e.target.value
        })
    }
    const setAccType = (acc: string) => {
        setInfo({
            ...info,
            accType: acc
        })
    }
    const [uploadFile, { loading: uploading, error: uploadError }] = useMutation(UPLOAD_FILE, {
        onCompleted: (data: any) => {
            setInfo({
                ...info,
                dp: data.uploadFile.uri
            })
        },
        onError: (error: any) => {
            console.log({ ...error });
        },
    });

    const handleFileChange = async (e: any) => {
        const file = e.target.files[0];
        if (!file) {
            return;
        } else {
            uploadFile({
                variables: {
                    file,
                },
            });
        }
    }

    
    const [onBoardUser, { error, loading, data }] = useMutation(ONBOARD, {
        onCompleted({ onBoard }: any) {
            if (onBoard) {
                localStorage.setItem('account', JSON.stringify(onBoard))
                navigate("/");
            }
        }
    });

    if (error) console.log(JSON.stringify(error,null, 2))
    if(data) console.log(data);

    const proceed = () => {
        if (uploading) return
        if (nextCount < 3) {
            setNextCount(nextCount + 1)
        } else {
            onBoardUser({
                variables: {
                    info: {
                        ...info,
                    }
                }
            })
        }
    }
    return (
        <Main>
            <MainContainer>
                <p className='step'>Step {nextCount + 1} of 4</p>
                <h2>Welcome! Let’s create your profile</h2>
                <h6>Let others get to know you better!</h6>
                <ProfileContainer>
                    <FormItem>
                        <Section show={nextCount === 0}>
                            <FormGroupCont>
                                <FormGroup>
                                    <Input
                                        name='name'
                                        label='Name'
                                        placeholder='Display name'
                                        value={info.name}
                                        focused={focused === 'name'}
                                        changeCallback={(e: any) => handleChange(e)}
                                        focusedCallback={(name: string) => focus(name)}
                                    />
                                </FormGroup>
                            </FormGroupCont>
                        </Section>
                        <Section show={nextCount===1}>
                            <h4>Avatar</h4>
                            <p>Improve visibility with a display picture or logo</p>
                            <DPCont>
                                <CustomerLogo>
                                    {
                                        info.dp && <img src={getImageUrl(info.dp)} alt=""  />
                                    }
                                    {
                                        uploading ?
                                            <Loader />
                                            :
                                            null
                                    }
                                </CustomerLogo>
                                <DPControls>
                                    <ChooseDpButton>
                                        {uploading ? <Loader /> : 'Choose a Logo'}
                                        <FileInput onChange={handleFileChange} />
                                    </ChooseDpButton>
                                    <h6 className='choose'>Or Choose default</h6>
                                    <ColorOptions>
                                        <Color />
                                        <Color />
                                        <Color />
                                        <Color />
                                        <Color />
                                    </ColorOptions>
                                </DPControls>
                            </DPCont>
                        </Section>

                        <Section show={nextCount === 2}>
                            <h4>Account Type:</h4>
                            <p></p>
                            <RadioGroup>
                                <Radio onClick={()=>setAccType('business')} selected={info.accType==='business'}>
                                    <h6>Business</h6>
                                    <p>
                                       Set up inventory, buy and sale off and online
                                    </p>
                                </Radio>
                                <Radio onClick={()=>setAccType('personal')} selected={info.accType === 'personal'}>
                                    <h6>Personal</h6>
                                    <p>
                                        Shop and follow your favorite store.
                                    </p>
                                </Radio>
                            </RadioGroup>
                        </Section>

                        <Section show={nextCount === 3}>
                            <h4>Popular stores to follow:</h4>
                            <p>Connect with stores and products from our recommended stores.</p>
                            <StoreGroup>
                                <Store>
                                    <div className='header-cont'>
                                        <SoreLogo>

                                        </SoreLogo>
                                        <FollowButton>Follow</FollowButton>
                                    </div>
                                    <h6>Zuma Cosmetics</h6>
                                    <p>Lagos, Nigeria</p>
                                </Store>
                                <Store>
                                    <div className='header-cont'>
                                        <SoreLogo>

                                        </SoreLogo>
                                        <FollowButton>Follow</FollowButton>
                                    </div>
                                    <h6>Moonshot Cosmetics Stores</h6>
                                    <p>Kaduna, Nigeria</p>
                                </Store>
                            </StoreGroup>
                        </Section>
                    </FormItem>
                    <ButtonsCont>
                        {
                            nextCount > 0 && 
                            <Back onClick={() => setNextCount(nextCount > 0 ? nextCount - 1 : nextCount)}>Back</Back>
                        }
                        <Button onClick={proceed}>{nextCount === 3 ? 'Finish' : loading ? <Loader/> : 'Continue'}</Button>
                    </ButtonsCont>
                </ProfileContainer>
            </MainContainer>
            <PromoContainer>

            </PromoContainer>
        </Main>
    )
}

export default Onboarding