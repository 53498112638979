import React, { useState } from 'react'
import TableHeader from '../tablex'
import SearchForm from '../../forms'

import { FormCont } from '../../forms/styles'
import { Cart, Chevron, Filter, Group, Plus, User } from '../../../../../components/icons'
import { Divider, Icon } from '../../../../../components/icons/styles'
import { Action, CatCont, FilterCont, Header, HeaderCont, HeaderMenuCont, IconsGroup, Item, TableActionsCont } from './styles'
import Actions from './menu'
import { queryOptions } from '../../../../../types/defaults'
import { useReactiveVar } from '@apollo/client'
import { showInventoryModal, tableState } from '../../../../../globals'
import CartIcon from '../../buttons/cart'
import CatHeader from '../table'
import { NavLink } from 'react-router-dom'
import NavListMenu from '../../../../../components/topNavMenuList'



const GROUP_OPTION = [
    'A-Z',
    'CATEGORY',
    'INSTOCK',
    'PRICE',
    'DATE ADDED',
]
const FILTER_OPTION = [
    'ALL',
    'EXPIRING',
    'EXPIRED',
    'RUNNING OUT',
    'OUT OF STOCK',
]

const MainHeader = (props: any) => {
    // const {setQueryCallback} = props
    const [menu, setMenu] = useState(false)

    const [active, setActive] = useState('')
    const showEditForm = () => {
        tableState({
            item: null,
            state: 'editing'
        })
        showInventoryModal(true) 
    }

    const { group, filter } = useReactiveVar(queryOptions)

    return (
        <Header>
            <HeaderCont>
                <FormCont>
                    <NavLink to='/'>
                        <h2>Stosyst <span>2.0</span> </h2>
                    </NavLink>
                    <HeaderMenuCont>
                        <IconsGroup
                            onClick={() => setMenu(false)}
                            onMouseEnter={() => setMenu(true)}
                        >
                            <Icon>
                                <User />
                            </Icon>
                            <Icon id='cheveron'>
                                <Chevron />
                            </Icon>
                        </IconsGroup>
                        {menu && <NavListMenu hideMenuCallback={setMenu} />}
                    </HeaderMenuCont>
                </FormCont>
            </HeaderCont>
            <CatCont>
                <CatHeader />
                <Divider />
            </CatCont>
            <TableHeader />
        </Header>
    )
}

export default MainHeader