import { gql, useMutation, useReactiveVar } from '@apollo/client'
import React, { useState } from 'react'
import { Cancel, Plus, SuccessMarkIcon } from '../../../../../components/icons'
import { Divider, Icon } from '../../../../../components/icons/styles'
import { Loader } from '../../../../../components/loaders'
import { SHARE_PRODUCT } from '../../../../../graphql/mutations'
import { GET_PRODUCTS } from '../../../../../graphql/queries/product.query'

import { queryOptions } from '../../../../../types/defaults'
import { matchedProds } from '../../../../../types/model'
import { formatMoney, getLocalStore } from '../../../../../utils'
import { updateProdCache } from '../../../../../utils/caches'
import Button from './button'
import { ImageItem } from './imageView'
import QInput from './quantity'
import Search from './search'
import SelectedItem from './selectedItem'
import { Container, Form, Header, InfoSection, InputCont, InputGroupCont, ItemInfo, ListItemCont, OptItemInfo } from './styles'

function ShareForm(props: any) {
    const { closeCallback } = props


    const [stock, setStock] = useState(props.stock)
    const [focus, setFocus] = useState('')
    const [opened, setOpened] = useState(false)
    const [count, setCount] = useState(0)
    const [done, setDone] = useState(false)

    const selectOpt = (opt: any, input: string) => {
        setFocus('')
    }

    const { group } = useReactiveVar(queryOptions)


    const openSearchList = (e: any) => {
        e.stopPropagation()
        // setOpened(!opened)
    }

    const store = getLocalStore()

    const handleOnChange = (e: any) => {
        e.persist();
        const { target: { value } } = e;
        setCount(+value)
    }

    const showFeedBack = () => {
        setDone(true);
        setTimeout(() => {
            setDone(false);
            setCount(0)
        }, 3000);
    };

    const [shareProduct, { error, loading, data: d }] = useMutation(SHARE_PRODUCT, {
        update: (cache, { data: { shareProduct } }) => {
            const data: any = cache.readQuery({
                query: GET_PRODUCTS,
            });
            cache.writeQuery({
                query: GET_PRODUCTS,
                data: {
                    products: updateProdCache(data.products, shareProduct[1], group),
                },
            });

            matchedProds(shareProduct[0])

            setStock(shareProduct[1])

            cache.writeFragment({
                id: `Product:${shareProduct[0]._id}`,
                fragment: gql`
                    fragment Stock on Product {
                         _id
                    }
                `,
                data: {
                    ...shareProduct[0]
                }
            });
            // showFeedBack();
        },
    });

    if (error) console.log(error);

    const handleSubmit = (e: Event) => {
        e.preventDefault()
        e.stopPropagation()
        if (count > 0) {
            shareProduct({
                variables: {
                    q: count,
                    addId: matchedProds()[0]._id,
                    subId: stock._id
                },
            });
        }

    }

    const adjustCount = (e: Event, val: number) => {
        e.stopPropagation()
        setCount(val >= 0 ? val : count);
    }
    return (
        <Container>
            <Header>
                <h6>Share</h6>
                <Icon>
                    <Cancel />
                </Icon>
            </Header>
            <Form onSubmit={(e: any) => handleSubmit(e)}>
                <InfoSection>
                    <Divider bottom={100} />
                    <h6>FROM:</h6>
                    <h6 className='store--id'>@{store.username}</h6>
                    <ListItemCont>
                        <ImageItem source={stock.stockImage} />
                        <ItemInfo>
                            <p>{stock.name}</p>
                            <p>{stock.description}</p>
                        </ItemInfo>
                        <OptItemInfo>
                            <p>{stock.instock}</p>
                            <p>{formatMoney(stock.sellingPrice)}</p>
                        </OptItemInfo>
                    </ListItemCont>
                </InfoSection>
                <InfoSection onClick={(e: any) => openSearchList(e)}>
                    <Divider bottom={100} />
                    <h6>TO:</h6>
                    <h6 className='store--id'>@{store.linkedTo[0].username}</h6>
                    <SelectedItem {...props} opened={opened} />
                    {
                        !opened &&
                        <InputCont>
                            <Divider bottom={100} />
                            <p>Quatity:</p>
                            <InputGroupCont>
                                <Icon onClick={(e: Event) => adjustCount(e, count + 1)} className='icon' size={9}>
                                    <p>+</p>
                                </Icon>
                                <QInput
                                    name="instock"
                                    placeholder="0"
                                    cancelCallback={() => { }}
                                    value={count}
                                    changeCallback={handleOnChange}
                                />
                                <Icon onClick={(e: Event) => adjustCount(e, count - 1)} className='icon' size={9}>
                                    <p>-</p>
                                </Icon>
                            </InputGroupCont>
                        </InputCont>
                    }
                </InfoSection>
                <Search opened={opened} />
                {
                    !opened &&
                    <Button title="Share" />
                }
            </Form>
        </Container>
    )
}

export default ShareForm