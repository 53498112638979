import styled from "styled-components";
export const HeaderMainCont = styled.div`
  height: 60px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  @media (max-width: 768px) {
    /* background: yellow; */
  }
  
`
export const CategoriesListCont = styled.div.attrs({
  className: 'container'
})`
  height: 100%;
  max-width: 350px;
  padding:0px 0px;
  display: flex; 
  justify-content: center;
  align-items: center;
   @media (max-width: 768px) {
    display: none;
  }
`
export const CategoryItems = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
    .updown {
      svg {
        fill:grey;
      }
      /* display:none; */
    }
    p {
        margin-bottom: 0px;
        font-size: 10px;
        font-weight: 700;
        margin-right: 5px;
        color: #1c1c1c;
        position: relative;
        text-transform: uppercase;
    }
    /* .divide {
      display: none;
    } */
      .icon {
        cursor: pointer;
        width: 12px;
        height: 12px;
      }
    .icon-cont {
      position: relative;
      width: 27px;
      height: 27px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      background-color: rgba(0,0,0,.05);
      :hover {
        background-color: rgba(0,0,0,.08);
      }

      svg {
        stroke: grey;
      }
    }
    @media(max-width: 768px) {
        svg {
           position: relative;
           right: 0px;
        }
        .divide, .updown {
          display: block;
        }
    }
`

export const MenuOptionsList = styled.ul<any>`
  font-size: 12px;
  position: absolute;
  overflow: hidden;
  padding: 0px 0px;
  right: 0;
  z-index: 50;
  min-width: 150px;
  top: -140%;
  color: ${props => props.theme.light.colors.labels.pri};
  background-color: ${props => props.theme.light.colors.backgrounds.base.pri};
  border: 1px solid ${props => props.theme.light.colors.separators.pri};
  border-radius: 10px;
  box-shadow: 0 5px 25px #c4c3c337;
  transition: all .15s ease-in-out;
  transform: scale(${props => props.show ? 1 : 0});
`
export const Option = styled.li<any>`
  height: 35px;
  width: 100%;
  display: flex;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  position: relative;
  a {
    margin: 0px;
    text-decoration: none;
    p {
      margin-bottom: 0px;
      font-size: 10px;
      font-weight: 700;
      padding: 0px 10px;
      color: ${props => props.active ? 'black' : 'rgba(83, 100, 113, 0.521)'};
    }
  }
 
  :hover {
    background-color: rgb(247 247 247);
    p {
      color: black;
    }
  }
`

export const HeaderCont = styled.div.attrs({
  className: 'container'
})`
  height: 100%;
  max-width: 1000px;
  padding:0px 0px;

   @media (max-width: 768px) {
    max-width: 100%;
    height: auto;
    align-items: center;
  }
`
export const HeaderListItems = styled.ul`
  width: 100%;
  height: 40px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background: white;
  padding: 0px;
  overflow: scroll;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  a {
      text-decoration: none;
      color: inherit;
    p {
      margin-bottom: 0px;
      font-size: 10px;
      position: relative;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: 700;
      text-transform: uppercase;
      @media(max-width: 768px) {
        text-transform: none;
        font-weight: 500;
        font-size: 12px;
      }
  }
}

  
`;
export const Item = styled.li<any>`
  height: 100%;
  /* width: auto; */
  margin: 0px 5px;
  cursor: pointer;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  height: 75%;
  border-radius:16px;
  color: ${props => props.active ? "#111111" : "rgba(83, 100, 113, 0.521)" };
  &:hover {
    color: black;
    /* background: #111111 !important; */
  }
  &::after {
    content:"";
    position: absolute;
    width: ${props => props.active ? "100%" : "0px"};
    height: 2px;
    bottom: -5px;
    background: ${props => props.active ? "#111111" : "transparent" };
    }
    @media(max-width: 768px) {
      margin: 0px 8px;
    }
`;