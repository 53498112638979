import { useQuery } from '@apollo/client'
import React from 'react'
import { useParams } from 'react-router-dom'
import { GET_STORE_ITEMS } from '../../../graphql/queries/product.query'
import EmptyState from '../../inventory/components/pagestate/empty'
import ErrorState from '../../inventory/components/pagestate/error'
import LoadingState from '../../inventory/components/pagestate/loader'
import Card from '../components/cards/relateds'
import { Container, ItemsContainer } from './styles'


const StoreItems = () => {

    const { id } = useParams()

    const { data, loading, error, fetchMore, refetch } = useQuery(GET_STORE_ITEMS, {
        variables: {
            id
        },
        fetchPolicy: "network-only",
    })

    let items: any = [];

    if (data) {
        items =  data.storeItems.map((p: any) => {
            const i = Math.floor(Math.random() * 16)
           return (
                {
                    ...p,

                }
            )
        }) 
    }

    if (error) console.log({ ...error })

    return (
        <Container>
            {
                loading ?
                    <LoadingState />
                    :
                    error ?
                        <ErrorState retryCallback={refetch} />
                        :
                        !data.storeItems.length ?
                            <EmptyState btnLabel='Refresh' message='No products in this store yet' />
                            :

                            <ItemsContainer>
                                {
                                    items?.map((item: any) => (
                                        <Card key={Math.random()} item={item} />
                                    ))
                                }
                            </ItemsContainer>
            }
        </Container>
    )
}

export default StoreItems