import React from 'react'
import { User, HomeIcn, Invoice, Orders } from '../../../../components/icons'
import { Icon } from '../../../../components/icons/styles'
import { FooterCont, LinkItem, LinkItemsCont } from './styles'

const Footer = () => {
  return (
      <FooterCont>
        <LinkItemsCont>
          <LinkItem>
            <Icon>
              <HomeIcn />
            </Icon>
            HOME
          </LinkItem>
          <LinkItem>
            <Icon>
              <Invoice />
            </Icon>
            INVOICE
          </LinkItem>
          <LinkItem>
            <Icon>
              <Orders />
            </Icon>
            ORDERS
          </LinkItem>
          <LinkItem>
            <Icon>
              <User />
            </Icon>
            ACCOUNT
          </LinkItem>
        </LinkItemsCont>
      </FooterCont>
  )
}

export default Footer