import styled from "styled-components";

export const Main = styled.main`
  width: 100%;
  height: 100vh;
  position: relative;
  background: white;
`;
export const Container = styled.div.attrs({
    className: "container",
})`
  /* height: 100vh; */
  width: 100%;
  display: flex !important;
  flex-wrap: wrap;
  align-items: start;
  padding-top: 0px;
  max-width: 1000px;
  margin:auto;
  position: relative;
  margin-bottom: 0px;
  @media (max-width: 420px) {
    columns: 165px;
    column-gap: 5px;
  }
`;