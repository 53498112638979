import React, { useEffect, useState } from 'react'
import { ChartCont, Body, Cont, CanvasCont, Footer, Header, LoadingCont, BtnGroup, ToggleBtn } from './styles'
import { Card } from 'react-bootstrap'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { Bar } from 'react-chartjs-2';
import { useLazyQuery, useQuery, useReactiveVar } from '@apollo/client';

import { chartDuration } from '../../../../globals';
import { SpinLoader } from '../../../loaders';
import { roundAmount, format_date } from '../../../../utils';
import { GET_TRENDS } from '../../../../graphql/queries';
import { TrendsList } from './list';
import ErrorState from '../../../../pages/inventory/components/pagestate/error';


export const colors = [
    '#00f5d4',
    '#ffd000',
    '#ff7900',
    '#d9ed92',
    '#C69749',
    '#FEBE8C',
    '#07beb8',
    '#858ae3',
    '#FF8DC7',
    '#00bbf9',
]

export const Tops = () => {


    ChartJS.register(ArcElement, Tooltip, Legend);

    const dateRange = useReactiveVar(chartDuration)

    const [view, setView] = useState('Stocks')
    const [date, setDate] = useState('wk')
    const [cutOut, setCutOut] = useState(60)

    const [menu, setMenu] = useState('')
     useEffect(() => {
         setCutOut(window.innerWidth <= 768 ? 130 : 60,)
     }, [window.innerWidth])
     

    const { loading, data: d, error } = useQuery(GET_TRENDS, {
        variables: {
            item: view.toLowerCase(),
            duration: date
        },
        fetchPolicy: "network-only",
    })

    if (error) console.log({ error })

    let chartList: any = [];
    let chartdata: any = [];

    if (d) {
        d.trends.forEach((item: any, i: number) => {
            chartList[i] = item.items[0]
            chartdata[i] = item.totalSalesCount
        })
    }
    const data = {
        datasets: [
            {
                label: 'Sales count',
                borderWidth: 0,
                data: chartdata,
                backgroundColor: colors,
            },
        ],
    };

    var options = {
        responsive: true,
        cutout: cutOut
        // plugins: {
        //     responsiveCutout: true, // Enable the custom plugin
        // }
    };
    
  return (
      <Cont>
          <Card id="trends">
              <Header>
                  <h6>Top salling products</h6>
                  <BtnGroup>
                      <ToggleBtn onClick={() => setDate('wk')} active={date === 'wk'}>
                          <p>Dy</p>
                      </ToggleBtn>
                      <ToggleBtn onClick={() => setDate('mn')} active={date === 'mn'}>
                          <p>Mn</p>
                      </ToggleBtn>
                      <ToggleBtn onClick={() => setDate('yr')} active={date === 'yr'}>
                          <p>Yr</p>
                      </ToggleBtn>
                  </BtnGroup>
              </Header>
              <Body>
                  <h4>27.4M</h4>
                  {
                      d?.trends.length > 0 ?
                  <ChartCont>
                        <>
                            <CanvasCont>
                                <Doughnut data={data} options={options} />
                            </CanvasCont>
                            <TrendsList view={view} items={chartList} />
                        </>
                  </ChartCont>
                          :
                          <LoadingCont> {
                              loading ?
                                  <SpinLoader size={'40px'} />
                                  : error ?
                                      <ErrorState />
                                      :
                                      <p>No record here</p>
                          }

                          </LoadingCont>
                   }
              </Body>
              <Footer>
                {
                      d?.trends.length > 0 ?
                      <>
                              <p>{format_date(d?.trends[d?.trends.length - 1].head.createdAt)}</p>
                              -
                              <p>{format_date(d?.trends[0].tail.createdAt)}</p>
                      </>
                      :
                      null
                }
              </Footer>
          </Card>
      </Cont>
  )
}
