import { useQuery, useReactiveVar } from '@apollo/client'
import React from 'react'
import Skeleton from '../../../../../components/loaders/skeletons'
import { GET_MATCHED_PRODS } from '../../../../../graphql/queries/stocks.query'
import { initProduct } from '../../../../../types/defaults'
import { matchedProds, Product } from '../../../../../types/model'
import { formatMoney, getLocalStore } from '../../../../../utils'
import { ImageItem } from './imageView'


import {ItemInfo, OptItemInfo, ListFeedbackMsgCont, ListItemCont } from './styles'


const SelectedItem = (props: any) => {

    const { stock } = props
    const { connectedAccounts } = getLocalStore()

    let matches: any = useReactiveVar(matchedProds)

    const { data, loading, error } = useQuery(GET_MATCHED_PRODS, {
        variables: {
            query: stock.name,
            storeId: connectedAccounts[0]._id
        },
        fetchPolicy: "cache-first",
    })

    if (error) console.log(error)

    let i: Product = initProduct;

    if (data) {
        const {matchedProducts} =  data
        i = matchedProducts[0]
        matchedProds(matchedProducts)
    }
    return (
        <>
            {
                loading ?
                    <Skeleton h={60} pt={10} />
                    :
                    error || !i ?
                    <ListFeedbackMsgCont>
                        <p>
                            {
                                error ? 'Connection Error' : 'No matching product found'
                            }
                        </p>
                    </ListFeedbackMsgCont>
                    :
                    <ListItemCont>
                        <ImageItem
                                source={stock.stockImage}
                        />
                        <ItemInfo>
                            <p>{stock.name}</p>
                            <p>{stock.description}</p>
                        </ItemInfo>
                        <OptItemInfo>
                                <p>{stock.instock}</p>
                                <p>{formatMoney(stock.sellingPrice)}</p>
                        </OptItemInfo>
                    </ListItemCont>
            }
        </>

    )
}

export default SelectedItem