import styled from "styled-components";

export const Container = styled.div.attrs({
    className: "container",
})`
  height: 100%;
  width: 100%;
  padding-top: 0px;
  position: relative;
`;

export const ListGroupCont = styled.div`
  width: 100%;
`

