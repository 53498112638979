import { gql } from "@apollo/client";

export const SAVE_STAFF_INFO = gql`
    mutation saveStaffInfo($info: StaffInput!) {
        saveStaffInfo(info: $info) { 
                _id
                info {
                    dp
                    name
                    category
                    storeId
                    accType
                }
                username
                createdAt
                updatedAt
        }
    }
`
export const DROP_STAFF = gql`
    mutation DropStaff($id: String!) {
        dropStaff(id: $id) { 
                _id
                info {
                    dp
                    name
                    category
                    storeId
                    accType
                }
                createdAt
                updatedAt
        }
    }
`