import styled from "styled-components"

export const Cont = styled.header`   
   .card {
        padding: 0px 0px;
        overflow: hidden;
        height: 360px;
        width: 100%;
        border-radius: 12px;
        margin-bottom: 15px;
        border-color: #eaeaea;
        display: grid;
        grid-template-rows: 40px 1fr 50px;
         @media(max-width: 768px) {
           margin-bottom: 10px;
           height: auto;
           min-height: auto;
      }
     
    }

`
export const Header = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px;
    height: 40px;
    background-color: #ffffff;
    h6 {
        margin-bottom: 0px;
        font-size: 14px;
    }
`
export const BtnGroup = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:2px;
    height: 26px;
    width: 120px;
    background: whitesmoke;
    border-radius: 6px;
    
`
export const ToggleBtn = styled.button<any>`
    display: flex;
    justify-content: center;
    align-items: center;
    padding:2px;
    height: 100%;
    width: 33%;
    box-shadow: none;
    outline: none;
    border: none;
    background: ${props => props.active ? 'white' : 'whitesmoke'};
    border-radius: 6px;
    p {
      margin-bottom: 0px;
      font-size: 11px;
    }
`
export const Body = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding: 0px 14px 10px 14px;
  h4  {
    font-weight: bold;
    margin: 0px;
    font-size: 1.25rem;
  }
`
export const Footer = styled.footer`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 14px;
    height: 100%;
    position: relative;
    p {
        margin-bottom: 0px;
        font-size: 11px;
        padding: 0px 10px;
        color: rgb(142, 142, 145)
    }
`
export const ChartCont = styled.div<any>`
    height: 100%;
    width: 100%;
    bottom:0px;
    border-radius: 12px;
    position: relative;
    display: flex;
    align-items: space-between;
     @media(max-width: 768px) {
     flex-direction: column;
    }
  `
export const CanvasCont = styled.div<any>`
    height: 100%px;
    width: 150px;
    bottom:0px;
    padding-right: 10px;
    border-radius: 12px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    @media(max-width: 768px) {
      width: 200px;
      height: 200px;
      padding-right: 0px;
    }

  `
export const LoadingCont = styled.div<any>`
   height: 100%;
   width: 100%;
   display: flex;
   position: absolute;
   flex-direction: row;
   align-items: center;
   justify-content: center;
   align-self: center ;
`


export const TrendsCont = styled.div<any>`
    height: 100%;
    width: 100%;
    display: flex;
    position: relative;
    align-items: center;
    padding: 0px 10px 10px 10px;
    justify-content: space-between;
`
export const TrendingBox = styled.div<any>`
    height: 100%;
    width: 55%;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    padding: 10px 10px 0px 0px;
    top: 20px;
`
export const TrendingList = styled.ol<any>`
    height: 100%;
    width: 100%;
    font-size: 14px;
    margin-bottom:0px;
    padding: 25px 0px 0px 16px;
    position: relative;
    display: flex;
    align-self: center ;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    list-style: decimal;
    li {
        width: 100%;
        height:50px;
        display: flex;
        margin-bottom: 5px;
        flex-direction: column;
        align-items: start;
        justify-content: center;
        position: relative;
        p {
            font-size: 12px;
            margin-bottom: 0px;
            width: 170px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
        p:last-child {
            font-size: 11px;
            color: #aba9a9;
            padding-left: 16px;
            margin-bottom:5px;
            span {
              font-size: 11px;
            }
        }
        .cat {
          display: none;
        } 
    }
    @media (max-width: 768px) {
       padding: 25px 0px 0px 0px;
       .desc {
         padding-left: 18px;
         color: #aba9a9;
       }
       li {
        display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: start;
          margin-bottom: 0px;
        p {
          width: 100%;
          position: relative;
        }
         .cat {
          display: inline-block;
       }
      
       }
    }
`
export const ListItem = styled.li<any>`
    
    p:first-child {
      width: 100%;
        &::before {
            display: inline-block;
            content: " ";
            height: 12px;
            width: 12px;
            top: 1px;
            margin-right: 4px;
            position: relative;
            border-radius: 50%;
            background-color: ${props => props.color};
        }
        span {
          margin-bottom: 0px;
          font-size: 12px;
          position: absolute;
          left: 4px;
          color: grey;
        }
        @media (max-width: 768px) {
          span:last-child {
          position: absolute;
          right: 0%;
          text-align: right;
        }
      }
        
    }
  
`
export const ImageCont = styled.div<any>`
    background: rgb(230 230 230);
    height: 45px;
    width: 45px;
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    background-size: cover;
    background-position: center;
    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: inherit;
    }
`;
