import { useState } from 'react'
import styled from 'styled-components'
import { Cancel, Chevron } from '../../../../../../components/icons'
import { Divider, Icon } from '../../../../../../components/icons/styles'
import { DropDownItem, DropDownListCont, Header, SingleDropDownListCont, SubDropDownListCont } from './styles'


const DropDownList = (props: any) => {
    const { options, header, name, closeCallback, selectCallback } = props
    const [hovered, setHovered] = useState<any>(null)
    return (
        <DropDownListCont>
            <Header>
                <h6>{header}</h6>
                <Icon onClick={closeCallback}>
                    <Cancel />
                </Icon>
                <Divider />
            </Header>
            {
                options.map((opt:any, i: number) => (
                    <DropDownItem onMouseLeave={() => setHovered(null)} onMouseEnter={()=>setHovered(i)}>
                        <p>{opt.name}</p> 
                        <Icon rot={-90}>
                            <Chevron />
                        </Icon> {
                            hovered === i  ?
                                <SubDropDownListCont onMouseLeave={() => setHovered(null)} className='subOpt' mh={350}>
                                {
                                    opt.items?.map((subOpt: any) => (
                                        <DropDownItem onClick={() => selectCallback(opt.name, subOpt)}>
                                            <p>{subOpt}</p>
                                            <Divider />
                                        </DropDownItem>
                                    ))
                                }
                            </SubDropDownListCont>
                            :
                            null
                        }
                    </DropDownItem>
                ))
            }
        </DropDownListCont>
    )
}
export default DropDownList
export const SingleDropDownList = (props: any) => {
    const { options, header, name, closeCallback, selectCallback } = props
    return (
        <SingleDropDownListCont {...props}>
            <Header>
                <h6>{header}</h6>
                <Icon onClick={closeCallback}>
                    <Cancel />
                </Icon>
                <Divider />
            </Header>
            {
                options.map((opt:any, i: number) => (
                    <DropDownItem onClick={() => selectCallback(opt)}>
                        <p>{opt}</p> 
                    </DropDownItem>
                ))
            }
        </SingleDropDownListCont>
    )
}
