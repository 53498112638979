import styled from "styled-components";

export const ButtonItem = styled.button<any>`
  height: 45px;
  width: 100%;
  margin-top: 20px;
  border-radius: 10px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  font-size: 12px;
  font-weight: 700;
  cursor: pointer;
  background: ${props => props.disabled ? 'whitesmoke' :'#08d57f'} ;
  &:hover {
    border: none;
    background-color: ${props => props.disabled ? 'whitesmoke' : '#04bb6f'} 
    /* color: ${(props) => (props.buy ? "white" : "white")}; */
  }
`;
export const GoogleBtn = styled.button<any>`
  height: 45px;
  width: 100%;
  margin-top: 20px;
  border-radius: 10px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  font-size: 12px;
  font-weight: 700;
  cursor: pointer;
  color: white;
  background: ${props => props.disabled ? 'whitesmoke' :'#00150c'} ;
  &:hover {
    border: none;
    background-color: ${props => props.disabled ? 'whitesmoke' : '##00160d'} 
    /* color: ${(props) => (props.buy ? "white" : "white")}; */
  }
  svg {
    height: 18px;
    width: 18px;
    margin-right: 5px;
  }
`;