import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { Chevron, HomeIcn, Plus } from '../../../../../components/icons'
import { Divider, Icon, IconBtn } from '../../../../../components/icons/styles'
import { showInventoryModal } from '../../../../../globals'
import SearchForm from '../../../../inventory/components/forms'
// import SearchForm from '../../forms'
import Actions from '../main/menu'

import { FilterCont, HeaderCont, HeaderListItems, HeaderMainCont, Item } from './styles'


export const TableHeader = () => {
    const showEditForm = () => {
        //show modal to edit item details.
        showInventoryModal(true)
        // tableState({item: null, state: 'editing'})
    }
    return (
        <HeaderCont>
            <HeaderListItems>
                <Item>
                    <h6>STOCK</h6>
                </Item>
                <Item>
                    <h6>CATEGORY</h6>
                </Item>
                <Item>
                    <h6>INSTOCK</h6>
                </Item>
                <Item>
                    <h6>
                        SELLING PRICE
                        <span>
                            <IconBtn onClick={() => showEditForm()}>
                                <Icon>
                                    <Plus />
                                </Icon>
                            </IconBtn>
                        </span>
                    </h6>
                </Item>
                <Divider />
            </HeaderListItems>

        </HeaderCont>
    )
}


const CatHeader = (props: any) => {
    const [active, setActive] = useState('')

    return (
        <HeaderMainCont>
            <HeaderCont>
                <HeaderListItems>
                    <h6>Orders</h6>
                    <SearchForm />
                    <p></p>
                </HeaderListItems>
            </HeaderCont>
            {/* <Divider /> */}
        </HeaderMainCont>
    )
}

export default CatHeader