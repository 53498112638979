import styled from "styled-components";


export const Main = styled.main`
    width: 100%;
    height: 100%;
    position: relative;
`
export const Container = styled.div.attrs({
    className: "container"
})`
    padding-top: 160px;
    columns: 170px;
    column-gap: 10px;
    position: relative;
    /* @media (max-width: 420px) {
        columns: 165px;
        column-gap: 5px;
  } */
`
export const CardCont = styled.div.attrs({
    className: "card"
})`
border: none;
border-radius: 8px;
display: flex;
/* flex-direction: column; */
background: inherit; 
width: 200px;
position:relative;
cursor: pointer;
&:hover {
   
}
`
export const ImageCont = styled.div`
border: none;
position:relative;
border-radius: 8px;
width: 100%;
height:200px ;
overflow:hidden;
.cart-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  background:#f84646;
  position: relative;
  bottom:10px;
  right: 10px;
  border-radius: 50%;
  color: white;
  .icon {
    width: 18px;
    height: 18px;
     svg {
    
    stroke: white;
  }
  }
 
}

@media (max-width: 420px) {
   max-height:200px ;
}
img {
    height: 100%;
    width: 100%;
    border-radius: inherit;
    object-fit: cover;
    border: none;
    /* mix-blend-mode: multiply;
    filter: contrast(1); */
    vertical-align: top center;
    @media (max-width: 420px) {
       object-fit: contain;
    }
}
`
export const PriceCont = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    h6 {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 0px;
        sub {
            font-size: 12px;
            bottom: 0px;
        }
    }
    p {
        margin-bottom: 0px;
        font-size: 12px;
        text-align: right;
        span {
            color: grey;
        }
    }
`
export const Description = styled.div`
padding: 10px 0px;
position:relative;
display: flex;
flex-direction: column;
.desc {
    color: black;
    font-size: 13px;
}
p {
    color: #5e5d5d;
    font-size: 12px;
    width: 98%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 0px;
}
`
export const LikeCont = styled.div<any>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  z-index: 1;
  top: 6px;
  right: 6px;
  padding: 0px 4px;
  cursor: pointer;
  border-radius: 12px;
  height: 25px;
  min-width: 45px;
  background: rgba(0, 0, 0, 0.051);
  :hover {
    background-color:  #0000002c;
  }
  .icon {
    width: 20px;
    height: 20px;
    svg {
        stroke: grey;
    }
  }
  p {
    margin-bottom: 0px;
    font-size: 12px;
    color: grey;
    font-weight: 500;
  }
  @media(max-width: 420px) {
        p {
           font-weight: 400;
        }
    }
`
export const Rating = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`
export const StarsCont = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin: 0px 2px;
  }
  .icon {
    width: 14px;
    height: 14px;
  }
  @media (max-width: 420px) {
    .icon {
    width: 16px;
    height: 16px;
  }
  }
  @media (min-width: 744px) and (max-width: 1024px) {
    .icon {
      width: 14px;
      height: 14px;
    }
  }
`
