import React, { ReactElement } from 'react'
import styled from 'styled-components'

const Message = styled.p`

`
interface Props {

}

export default function ErrorMessage(props: any): ReactElement {

    let { graphQLErrors, networkError, message } = props.error

    // if (graphQLErrors)
    // graphQLErrors.forEach(({ message, locations, path }) =>
    //   console.log(
    //     `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
    //   ),
    // );

    if (networkError)
        message = "Something went wrong, but don’t fret — let’s give it another shot.";

    return <Message>{message}</Message>

}
