import styled from "styled-components";

export const InvoiceCont = styled.div<any>`
  width: 100%;
  height: 60px;
  display: grid;
  grid-template-columns: 50px auto;
  gap: 0px 10px;
  overflow: visible;
  border-radius: 8px;
  cursor: pointer;
  position: relative;
  margin: auto;
  align-items: center;
  padding: 0px;
  li:nth-child(4) {
    .icon {
      width: 22px;
      height: 22px;
      display: none;
    }
  }
  &::before {
    content:"";
    display: block;
    position: absolute;
    height: 100%;
    width: 101%;
    left: -6px;
    bottom: 0;
    border-radius: inherit;
    background: ${props => props.selected ? '#25ec78' : ''};
  }
  :hover {
    cursor: pointer;
    &::before {
      background: ${props => props.selected ? '#25ec78' : 'whitesmoke'};
    }
    ul {
      .divider {
        display: none;
      }
    }
    li {
      p:last-child {
        color: #363636;
      }
    }
    li:nth-child(4) {
      .icon { 
        display: flex;
      }
      p,.divider {
        display: none;
      }
    }
  }
`;
export const ImageCont = styled.div`
  width: 40px;
  height: 40px;
  border: none;
  position: relative;
  border-radius: 6px;
  position: relative;
  z-index: 1;
  background: #e3e3e3;
  img {
    height: 100%;
    width: 100%;
    border-radius: inherit;
    object-fit: cover;
    border: none;
    mix-blend-mode: multiply;
    filter: contrast(1);
    vertical-align: top center;
  }
`;
export const Row = styled.ul`
  width: 100%;
  height: 100%;
  display: grid;
  position: relative;
  margin: 0px;
  padding-left: 0px;
  grid-template-columns: 41.6% 28% 1fr 1fr;
`;
export const Col = styled.li`
  height: 100%;
  width: 100%;
  cursor: pointer;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  p:first-child {
    margin: 0px;
    font-size: 13px;
  }
  p {
    margin: 0px;
    font-size: 12px;
    position: relative;
    width: 98%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #363636;
  }
  p:last-child {
    color: grey;
  }

  /* &:nth-child(3) {
    p {
      text-align: left;
    }
  } */
  &:nth-child(4) {
    align-items: flex-end;
    text-align: right;
    padding-right: 5px;
  }
`;
export const Footer = styled.footer`
  width: 927px;
  left: 60px;
  display: flex;
  height: 40px;
  align-items: center;
  position: relative;
  justify-content: space-between;
  h6 {
    font-size: 14px;
    margin-bottom: 0px;
    font-weight: 500;
    color: rgba(63, 63, 64, 0.8);
  }
  p {
    margin-bottom: 0px;
    color: grey;
    font-size: 13px;
  }
`
