import styled from "styled-components";
export const Container = styled.div<any>`
  height: 100%;
  width: 100%;
  padding: 0px 0px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .or {
    border-top: 1px solid #0000000f;
     width: 250px;
    height: 1px;
    position: relative;
    margin: 25px auto;
    top: 20px;
    p {
       position: absolute;
       font-size: 12px;
       top: -10px;
       background: inherit;
       text-align: center;
       width: 100%;
    }
  }
`;

export const FormItem = styled.form`
  width: 240px;
  height: 200px;
  position: relative;
  background: white;
  h6 {
    font-size: 20px;
    width: 100%;
    font-weight: 700;
    text-align: center;
    margin-bottom: 20px;
  }
   @media(max-width: 768px) {
     width:300px;
  }
`;
export const GoogleBtnCont = styled.div`
  width: 240px;
  position: relative;
  margin: 15px auto 30px auto;
  @media(max-width: 768px) {
    width: 300px;
  }
`;


export const FormGroupCont = styled.div<any>`
  margin: 8px 0px;
  position: relative;
  border-radius: 8px;
  width:100%;
  border: 1px solid rgb(0 0 0 / 6%);
`;

export const FormGroup: any = styled.div<any>`
  display: grid;
  position: relative;
  padding: 7px;
  grid-template-columns: 92% 8%;
  align-items: center;
  height: ${(props) => props.h || 45}px;
  width: ${(props) => props.w || 100}%;
  background: ${(props) => (props.focused ? "whitesmoke" : "initial")};
  border-bottom-left-radius: ${(props) => (props.top ? "0px" : "6px")};
  border-bottom-right-radius: ${(props) => (props.top ? "0px" : "6px")};
  border-bottom: ${(props) => (props.top ? "1px" : "0px")} solid rgb(0 0 0 / 6%);
  border-right: ${(props) => (props.left ? "1px" : "0px")} solid #d3d3d35c;
  /* border-bottom-color: ${(props) => (props.top ? "#e6e1e1" : "white")}; */
  .icon {
    width: 8px;
    height: 8px;
    cursor: pointer;
    position: relative;
    :hover {
        background: whitesmoke;
    }
  }
  #pwd {
      width: 12px;
      height: 12px;
  }
`;

export const FormOpt = styled.div`
  width: 100%;
  /* margin-bottom: 20px; */
  padding: 0px 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  p {
    margin-bottom: 0px;
    font-size: 11px;
    color: grey;
    span {
      color: #08d57f;
      cursor: pointer
    }
  }
  p:last-child {
    color: #08d57f;
    cursor: pointer
}`

export const ErrorLine = styled.div`
width: 100%;
margin-top: 20px;
padding: 0px 5px;
position: relative;
display: flex;
align-items: center;
justify-content: center;
p {
  margin-bottom: 0px;
  font-size: 11px;
  color: #e3441880;
}

`
export const TagLine = styled.p`
width: 100%;
margin-top: 20px;
margin-bottom: 0px;
position: relative;
font-size: 12px;
text-align: center;
span {
  a {
    text-decoration: none;
    color: #08d57f;
    margin-left: 2px;
  }
}

`